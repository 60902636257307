import { gql } from "apollo-boost";
import { MutationFunction } from "@apollo/react-common";
import { Login, LoginVariables } from "./types/Login";

export const loginMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        userId
        email
        role
        assignedTowns {
          townId
          name
        }
      }
    }
  }
`;

export type LoginMuationFn = MutationFunction<Login, LoginVariables>;
