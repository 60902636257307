import * as React from "react";
import { FunctionComponent, useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import { SelectCitizen } from "../select-citizen";
import { TownFull } from "../../../api/graphql/queries/townsFull";
import { PageLoadingIndicator } from "../../../utils/page-loading-indicator";
import { TownContext } from "../../core/town-context";
import { Town } from "../../../api/graphql/queries/towns";
import { SearchCitizen } from "../../../api/graphql/queries/search-citizens";
import { StartWeighingProcessVariables } from "../../../api/graphql/mutations/types/StartWeighingProcess";
import {
  START_WEIGHING_PROCESS_MUTATION,
  StartWeighingProcessResult,
} from "../../../api/graphql/mutations/start-weighing-process";
import { snackbarCloseAction } from "../../../utils/snackbar-close-action";
import * as _ from "lodash";
import { AVAILABLE_QR_CODES, AvailableQrCodesResults, QrCode } from "../../../api/graphql/queries/available-qr-codes";
import { AvailableQrCodesVariables } from "../../../api/graphql/queries/types/AvailableQrCodes";
import { Autocomplete } from "@material-ui/lab";
import { QrCodeType } from "../../../api/graphql/graphql-global-types";

interface IStartWeighingDialogProps {
  onSuccess: (firstWeight?: number) => void;
  onCloseDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#979797",
    marginBottom: 37,
  },
  contentRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 5,
  },
  highlightedText: {
    backgroundColor: "#D5D5D5",
  },
  menuItemRoot: {
    backgroundColor: "transparent",
    "&:focus": {
      background: "transparent",
    },
  },
  loadingIndicatorContainer: {
    flex: 1,
  },
}));

export const StartWeighingProcessDialog: FunctionComponent<IStartWeighingDialogProps> = (props) => {
  const { onCloseDialog } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { assignedTowns: towns } = useContext(TownContext);

  const [shouldShowErrors, setShouldShowErrors] = useState<boolean>(false);
  const [selectedTown, setSelectedTown] = useState<Town | null | undefined>(null);
  const [selectedCitizen, setSelectedCitizen] = useState<SearchCitizen | null>(null);
  const [additionalWeighingInfo, setAdditionalWeighingInfo] = useState<string>("");
  const [qrCodeFilter, setQrCodeFilter] = useState("");
  const [selectedQrCode, setSelectedQrCode] = useState<QrCode | null>(null);
  const [qrCodeSelection, setQrCodeSelection] = useState<QR_CODE_SELECTION>("ASSIGN");

  const [startWeighingProcess, { loading: isStartingWeighingProcess }] = useMutation<
    StartWeighingProcessResult,
    StartWeighingProcessVariables
  >(START_WEIGHING_PROCESS_MUTATION);

  const { data: qrCodesResult, loading: isLoadingQrCodes } = useQuery<
    AvailableQrCodesResults,
    AvailableQrCodesVariables
  >(AVAILABLE_QR_CODES, {
    variables: {
      type: QrCodeType.TEMPORARY,
      availableOnly: true,
    },
  });

  const onTownSelected = (townId: string) => {
    const town = towns.find((t) => t.townId === townId);
    setSelectedTown(town);
    setQrCodeSelection("ASSIGN");
  };

  const onSend = async () => {
    setShouldShowErrors(true);

    const qrCode = qrCodeSelection === "APP" ? selectedCitizen?.userQrCode : selectedQrCode?.id;

    if (!selectedCitizen || !qrCode) {
      return;
    }

    try {
      const result = await startWeighingProcess({
        variables: {
          citizenId: selectedCitizen.citizenId,
          householdId: selectedCitizen.householdId,
          qrCodeId: qrCode,
          additionalWeighingInfo: additionalWeighingInfo.trim().length > 0 ? additionalWeighingInfo : null,
        },
      });

      if (!result || !result.data || result.data.startWeighingProcess.error) {
        onError(t("weighing_management.start_dialog.errors.in_use"));
        return;
      }

      onSuccess();
    } catch (e) {
      onError(t("weighing_management.start_dialog.errors.generic"));
    }
  };

  const onError = (message: string) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
    });
  };

  const onSuccess = () => {
    enqueueSnackbar(t("weighing_management.start_dialog.success"), {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
    });
    props.onSuccess();
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth={"xs"}>
      {_.isEmpty(towns) ? (
        <PageLoadingIndicator className={classes.loadingIndicatorContainer} />
      ) : (
        <form>
          <DialogTitle>{t("weighing_management.start_dialog.title")}</DialogTitle>
          <DialogContent className={classes.contentRoot}>
            <Grid container={true}>
              <Grid item={true} xs={12} className={classes.content}>
                <FormControl fullWidth={true} error={shouldShowErrors && !selectedTown}>
                  <InputLabel>{t("weighing_management.start_dialog.town")}</InputLabel>
                  <Select
                    value={selectedTown || null}
                    renderValue={(value?: TownFull | string | number | boolean | object | unknown) =>
                      value && (value as TownFull).name ? `${(value as TownFull).name}` : undefined
                    }
                    displayEmpty={false}
                    onChange={(e) => onTownSelected(e.target.value as string)}
                    classes={{ root: classes.menuItemRoot }}
                  >
                    {towns
                      .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
                      .map((town) => (
                        <MenuItem key={town.name} value={town.townId}>
                          {town.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {shouldShowErrors && !selectedTown ? (
                    <FormHelperText>{t("weighing_management.start_dialog.errors.town")}</FormHelperText>
                  ) : undefined}
                </FormControl>
              </Grid>

              <Grid item={true} xs={12} className={classes.content}>
                <SelectCitizen
                  townId={selectedTown && selectedTown.townId}
                  onCitizenChanged={setSelectedCitizen}
                  showError={shouldShowErrors && !selectedCitizen}
                />
                {shouldShowErrors && !selectedCitizen ? (
                  <FormHelperText>{t("weighing_management.start_dialog.errors.user")}</FormHelperText>
                ) : undefined}
              </Grid>

              {selectedCitizen?.userQrCode && (
                <Grid item xs={12} className={classes.content}>
                  <Typography>{t("weighing_management.start_dialog.qr_code_exists")}</Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={qrCodeSelection}
                      onChange={(event, value) => setQrCodeSelection(event.target.value as QR_CODE_SELECTION)}
                    >
                      <FormControlLabel
                        value={"ASSIGN"}
                        control={<Radio />}
                        label={t("weighing_management.start_dialog.assign_qr_code")}
                      />
                      <FormControlLabel
                        value={"APP"}
                        control={<Radio />}
                        label={t("weighing_management.start_dialog.use_app_qr_oce")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              {qrCodeSelection === "ASSIGN" && (
                <Grid item xs={12} className={classes.content}>
                  <Autocomplete
                    disabled={!selectedTown || !selectedCitizen}
                    value={selectedQrCode}
                    options={qrCodesResult?.qrCodes || []}
                    noOptionsText={t("no_options")}
                    getOptionLabel={(option) => `Nr. ${option.label ?? "-"}`}
                    onChange={(event: React.ChangeEvent<{}>, value: QrCode | null) => setSelectedQrCode(value)}
                    getOptionSelected={(option, value) => option.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("weighing_management.start_dialog.qr_code")}
                        fullWidth={true}
                        value={qrCodeFilter}
                        error={shouldShowErrors && !selectedQrCode}
                        onChange={(e) => setQrCodeFilter(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoadingQrCodes ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {shouldShowErrors && !selectedQrCode ? (
                    <FormHelperText>{t("weighing_management.start_dialog.errors.user")}</FormHelperText>
                  ) : undefined}
                </Grid>
              )}

              <Grid item={true} xs={12} className={classes.content}>
                <TextField
                  id="additional-weighing-info"
                  disabled={!selectedCitizen}
                  label={t("weighing_management.start_dialog.additional_weighing_info_label")}
                  fullWidth
                  value={additionalWeighingInfo}
                  onChange={(e) => setAdditionalWeighingInfo(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingBottom: 10, paddingRight: 10 }}>
            <Button color="primary" onClick={() => onCloseDialog()}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              variant={"contained"}
              onClick={(event) => {
                event.preventDefault();
                onSend();
              }}
              type={"submit"}
            >
              {isStartingWeighingProcess ? <ClipLoader color={"white"} size={17} /> : t("save")}
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

type QR_CODE_SELECTION = "ASSIGN" | "APP";
