export const de = {
  verification: {
    text: {
      CODE_NOT_FOUND: "Ein Fehler ist aufgetreten.",
      SUCCESS:
        "Deine E-Mail Adresse wurde erfolgreich verifiziert. Du kannst dich nun in der Daheim App damit einloggen.",
      CODE_ALREADY_USED:
        "Deine E-Mail Adresse wurde erfolgreich verifiziert. Du kannst dich nun in der Daheim App damit einloggen.",
    },
    image: {
      CODE_NOT_FOUND_DESKTOP: "verification-error.svg",
      CODE_NOT_FOUND_MOBILE: "verification-error.svg",
      SUCCESS_DESKTOP: "verification-success.png",
      SUCCESS_MOBILE: "verification-success-mobile.svg",
      CODE_ALREADY_USED_DESKTOP: "verification-success.png",
      CODE_ALREADY_USED_MOBILE: "verification-success-mobile.svg",
    },
  },
  no_options: "Keine Einträge gefunden",
  cancel: "Abbrechen",
  save: "Speichern",
  delete: "Löschen",
  no_data: "Keine Daten gefunden",
  general_error_message: "Ein Fehler ist aufgetreten",
  general_success_message: "Aktion erfolgreich abgeschlossen",
  login: {
    title: "ASZ-PORTAL",
    login: "Anmelden",
    emailPlaceholder: "E-Mail",
    passwordPlaceholder: "Passwort",
    error: "Ein Fehler ist aufgetreten. " + "Bitte überprüfen Sie Ihre Login-Daten und versuchen Sie es erneut.",
    errorLoginMissing: "Bitte Login eingeben!",
    errorLoginInvalid: "Bitte gültige E-Mailadresse eingeben",
    errorPasswordMissing: "Bitte Passwort eingeben!",
    check: "Bitte überprüfen Sie Ihre Login-Daten!",
  },
  logout: {
    title: "Abmelden",
    message: "Wollen Sie sich wirklich abmelden?",
    cancel: "Nein",
    confirm: "Abmelden",
  },
  navigation: {
    logout: "Abmelden",
    dashboard: "Dashboard",
    weighing: "Verwiegung",
    weighing_export: "Export",
    household: "Haushalte",
    user_management: "Benutzerverwaltung",
    fraction_management: "Fraktionsverwaltung",
  },
  user_management: {
    import_citizens: {
      title: "Personenregister importieren",
      import_message: "Datei auswählen oder hier ablegen",
      import_error: "Beim Verarbeiten des Files ist ein Fehler aufgetreten",
      import_success: "Datei erfolgreich verarbeitet. Datensätze: ",
      upload_error: "Beim Importieren der Daten ist ein Fehler aufgetreten",
      upload_success: "Daten wurden erfolgreich hochgeladen!",
    },
    disposal_allowance: {
      resign_title: "Wollen Sie diesem Bürger die Abfuhrberechtigung entziehen?",
      allow_title: "Wollen Sie diesem Bürger die Abfuhrberechtigung erlauben?",
      resign_button: "Entziehen",
      allow_button: "Erlauben",
      resign_success: "Dem Benutzer wurde die Berechtigung entzogen",
      allow_success: "Dem Benutzer wurde die Berechtigung erlaubt",
    },
    manual_verification: {
      title: "Benutzerfreischaltung",
      user_data_title: "Vom Benutzer eingetragene Daten",
      citizen_data_title: "Vorgeschlagene Daten",
      verify_button: "Freischalten",
      verify_success: "Benutzer wurde freigeschalten",
      info_text: "Wählen Sie die Kriterien nach denen Sie suchen wollen.",
      stepper_next: "Weiter",
      stepper_back: "Zurück",
      no_match: "Keine Übereinstimmung?\nKontaktiere den Benutzer per E-Mail: ",
    },
    no_data: "Es wurden keine Daten gefunden",
    unverified_users_header: "Freizuschaltende Benutzer",
    verified_users_header: "Freigeschaltete Benutzer",
    unlock: "Freischalten",
    verified_users_search_label: "Nach Name, Adresse oder SV-Nummer suchen",
    loading: "Laden...",
  },
  user_data: {
    firstname: "Vorname",
    lastname: "Nachname",
    street: "Straße",
    street_and_number: "Straße und Hausnummer",
    street_number: "Hausnummer",
    postalcode: "PLZ",
    postalode_and_city: "PLZ und Ort",
    city: "Stadt",
    date_of_birth: "Geburtstag",
    email: "E-Mail",
    status: "Status",
    name: "Name",
    registered: "Registriert",
    not_registered: "Nicht registriert",
    staircase: "Stiege",
    apartment_number: "Türnummer",
  },
  allowance: {
    fraction: "Fraktion",
    sum: "Insgesamt",
    consumed: "Verbraucht",
    available: "Noch verfügbar",
    townDefault: "Standard",
  },
  waste_management: {
    add_waste: "Neue Fraktion",
    reset_allowances: {
      button: "Zurücksetzen",
      title: "Zurücksetzen",
      description: "Wollen Sie für alle Bürger der Gemeinde die Freimengen zurücksetzen?",
      success: "Es wurden alle Freimengen zurückgesetzt",
      error: "Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut",
    },
    waste: "Bezeichnung",
    base_waste: "Fraktion",
    fraction_group: "Fraktionsgruppe",
    allowance: "Freimenge",
    error_weight: "Bitte geben Sie ein gültiges Gewicht ein.",
    dialog: {
      new_name: "Bezeichnung",
      new_name_helper: "Sie können eine alternative Bezeichnung vergeben",
      add: {
        title: "Neue Fraktion hinzufügen",
        success: "Fraktion wurde erfolgreich hinzugefügt!",
      },
      edit: {
        title: "Fraktion bearbeiten",
        success: "Fraktion wurde erfolgreich bearbeitet!",
      },
      errors: {
        waste: "Bitte wählen Sie eine Fraktion aus!",
        name: "Bitte vergeben Sie eine Bezeichnung",
        api: "Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut!",
      },
      no_waste_types: "Es stehen derzeit keine Fraktionen zur Verfügung",
    },
    fractionGroup: {
      title: "Fraktionsgruppe",
      maxAllowance: "Freimenge",
      consumption: "Intervall",
      consumptionCycle: {
        ON_DELIVERY: "pro Anlieferung",
        YEAR: "pro Jahr und Haushalt",
      },
    },
  },
  weighing_export: {
    download: "Herunterladen",
    download_in_progress: "Wird heruntergeladen ...",
    status: {
      success: "Wiegungen werden heruntergeladen.",
      no_weighings: "Keine Wiegungen für den angegebenen Zeitraum gefunden.",
      error: "Wiegungen konnten nicht geladen werden",
    },
  },
  weighing_management: {
    export: {
      title: "Export",
      action: "Exportieren",
      button: "Herunterladen",
    },
    no_additional_weighing_info_set: "[Keine Zusatzinformationen hinterlegt]",
    start: "Neue Einfahrt",
    open_weighings: "Aktuelle Anzahl Besucher: ",
    no_open_weighings: "Noch keine Verwiegungen vorhanden",
    enter: "Erfassen",
    add_weighing: "Neue Verwiegung erfassen",
    finish_weighing: "ASZ-Besuch abschließen",
    send_to_sap: "An SAP senden",
    send_to_sap_success: "Übertragung an SAP erfolgreich",
    sap_weighing_nr: "SAP Waagevorgangsnummer",
    start_dialog: {
      title: "Neue Einfahrt registrieren",
      town_subtitle: "Aus welcher Gemeinde kommt der Bürger?",
      town: "Gemeinde",
      user_subtitle: "Welcher Bürger fährt ein?",
      user_select: "Bürger (zum Suchen tippen)",
      waste_subtitle: "Welche Fraktion wird abgeladen?",
      waste_type: "Fraktion",
      weight_subtitle: "Wie schwer ist das Fahrzeug bei der Erstverwiegung?",
      weight: "Gewicht",
      additional_weighing_info_subtitle: "Zusatzinformationen (freier Text)",
      additional_weighing_info_label: "Zusatzinformationen",
      errors: {
        town: "Bitte wählen Sie eine Gemeinde aus!",
        user: "Bitte wählen Sie einen Bürger aus!",
        waste: "Bitte wählen Sie eine Fraktion aus!",
        weight: "Bitte geben Sie ein gültiges Gewicht der Erstverwiegung ein!",
        second_weight: "Bitte geben Sie ein gültiges Gewicht der Rückverwiegung ein!",
        user_does_not_exist:
          "Für diesen Bewohner kann keine Wiegung gestartet werden. Möglicherweise wurde der Bewohner gelöscht. Bitte laden Sie die Seite neu",
        generic: "Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut",
        in_use: "Qr-Code in Verwendung oder Bürger hat bereits einen aktiven Vorgang.",
      },
      success: "Verwiegung wurde erfolgreich erstellt!",
      qr_code: "QR-Code",
      qr_code_exists:
        "Dieser Bürger ist bereits in der App registriert. Möchten Sie dennoch einen Qr-Code zuweisen? Der Bürger muss dann den von Ihnen zugewiesenen QR-Code benutzen und kann nicht mit dem QR-Code der App einfahren!",
      use_app_qr_oce: "QR-Code aus App benutzen",
      assign_qr_code: "QR-Code zuweisen",
    },
    edit_dialog: {
      title: "Verwiegung hinzufügen",
      weight_first: "Gewicht ",
      waste_type: "Fraktion",
      success: "Verwiegung wurde erfolgreich gespeichert!",
      delete: "Löschen",
      success_delete: "Verwiegung wurde erfolgreich gelöscht!",
      errors: {
        general: "Ein Fehler ist aufgetreten",
      },
      confirmation: {
        title: "Verwiegung löschen",
        message: "Wollen Sie die Verwiegung von {{name}} der Fraktion {{waste}} löschen?",
        success: "Verwiegung erfolgreich gelöscht!",
      },
    },
    enter_weighing: {
      heading: "Verwiegung erfassen",
      weight: "Gewicht",
      errors: {
        weight: "Bitte geben Sie ein gültiges Gewicht der Verwiegung ein!",
      },
    },
    confirm_dialog: {
      heading: "ASZ-Besuch abschließen",
      text: "Möchten Sie den ASZ-Besuch von {{name}} abschließen?",
      fractions_to_pay:
        "Bitte beachten Sie, dass Sie damit auch die Bezahlung folgender Fraktionen bestätigen: {{fractions}}",
      paid: "Abschließen",
      success: "ASZ-Besuch erfolgreich abgeschlossen!",
      error: "Ein Fehler ist aufgetreten",
    },
    overview: {
      waste: "Fraktion",
      disposal_allowance: "Freimenge",
      first_weight: "Erstverwiegung",
      second_weight: "Zweitverwiegung",
      loaded: "Abgeladen",
      to_pay: "Zu bezahlen",
      status: "Status",
      complete: "Abschließen",
      pay: "Bezahlen",
      ready: "Fertig",
      edit: "Bearbeiten",
      unloading: "Wird abgeladen",
      weighted: "Verwogen",
      waiting_for_weighing: "Warte auf Wiegung",
      waiting_for_payment: "Warte auf Bezahlung",
      weight: "Gewicht",
    },
    many_weighings: {
      tooltip: "Es befinden sich {{count}} Wiegungen in der Warteschlange",
      snackbar: "Es befinden sich {{count}} Wiegungen in der Warteschlange",
      new_weighing:
        "Es laden bereits {{count}} Personen in diesem ASZ ihren Müll ab. Trotzdem eine weitere Verwiegung hinzufügen?",
    },
    generate_qr_code: {
      heading: "Neue QR Codes erstellen",
      text: "Geben Sie die Anzahl der QR Codes ein, die sie erstellen möchten:",
      number: "Anzahl der Codes",
      generate: "QR-Codes erstellen",
      success: "QR-Code erfolgreich erstellt!",
      error: "Ein Fehler ist aufgetreten",
    },
  },
  disposal_allowance_management: {
    title: "Freimengen verwalten",
    invalid_date: "Bitte in gültiges Datum auswählen",
    error_weight: "Bitte geben Sie eine gültige Freimenge ein",
    error_negative_weighing: "Die Wiegung würde negativ sein, bitte geben Sie einen gültigen Wert ein",
    api_error: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!",
    success: "Freimengen wurden erfolgreich gespeichert!",
  },
  password_reset: {
    title: "Du hast dein Passwort vergessen?",
    instructions:
      "Wir helfen dir ein neues Passwort zu erstellen. Um die Wiederherstellung zu starten, gib deine E-Mail-Adresse an. Wir senden dir ein E-Mail mit weiteren Instruktionen.",
    button: "Passwort zurücksetzen",
    input_error: "Bitte überprüfe, ob es sich bei der Eingabe um eine gültige E-Mail-Adresse handelt.",
    server_error: "Passwort kann nicht wiederhergestellt werden. Es tut uns leid, das hätte nicht passieren dürfen!",
    try_again: "Es gibt ein Problem bei der Wiederherstellung. Bitte versuche es später erneut.",
    sent: {
      title: "Überprüfe deine E-Mails",
      instructions:
        "Zur Überprüfung deiner Identität haben wir dir ein E-Mail mit weiteren Instruktionen zum Abschluss der Wiederherstellung zugesendet. Bitte überprüfe deinen Posteingang.",
      ok: "✓",
    },
    complete: {
      title: "Fast geschafft ...",
      instructions:
        "Du kannst nun ein neues Passwort vergeben. Achte auf die Sicherheit deines Passwortes. Dazu muss das Passwort mindestens 8 Zeichen lang sein, mindestens eine Zahl, einen Kleinbuchstaben und einen Großbuchstaben enthalten.",
      button: "Neues Passwort speichern",
      password_criteria:
        "Das Passwort muss mindestens 8 Zeichen lang sein, mindestens eine Zahl, einen Kleinbuchstaben und einen Großbuchstaben enthalten.",
      ok: "✓",
      ok_title: "Geschafft!",
      ok_instructions: "Du kannst dich nun mit deinem neuen Passwort anmelden.",
      ok_link: "Hier geht's weiter ...",
    },
    invalid: {
      title: "Ungültiger Link",
      instructions: "Es tut uns Leid, aber dieser Link ist bereits abgelaufen oder war nie gültig.",
      nok: "❌",
    },
  },
  edit_additional_weighing_info_dialog: {
    dialog_title: "Zusatzinformationen bearbeiten",
    additional_weighing_info_label: "Zusatzinformationen",
  },
  FREE: "Kostenlos",
  FIVEHUNDRETKG: "Kostenlos bis 500 kg",
  HUNDRETKG: "Kostenlos bis 100 kg",
  FIFTYKG: "Kostenlos bis 50 kg",
  CHARGEABLE: "Kostenpflichtig",
  UNDEFINED: "Gruppe nicht definiert",
};
