import * as React from "react";
import {IconButton} from "@material-ui/core";
// import {useSnackbar} from "notistack";
import CloseIcon from '@material-ui/icons/Close';

interface ISnackbarCloseActionProp {
  key: string | number | undefined
  closeSnackbar: ((key: string | number | undefined) => void)
}

export const snackbarCloseAction: React.FunctionComponent<ISnackbarCloseActionProp> = ({key, closeSnackbar}) => {
  // const {closeSnackbar} = useSnackbar()

  return (
    <IconButton style={{color: 'white'}} onClick={() => {
      closeSnackbar(key)
    }}>
      <CloseIcon fontSize="small"/>
    </IconButton>
  )
}