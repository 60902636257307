import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { isAndroid, isBrowser, isIOS, isMobile, MobileView } from "react-device-detect";
import { VerifyEmailStatus } from "../api/graphql/graphql-global-types";
import { verifyEmail, verifyEmailVariables } from "../api/graphql/mutations/types/verifyEmail";
import { useMutation } from "@apollo/react-hooks";
import { verifyEmailMutation } from "../api/graphql/mutations/verify-email-mutation";
import { useQuery } from "../utils/use-query";
import { config } from "../utils/config";

interface IVerifyEmailProps {}

interface IVerifyEmailState {}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: "#e7f3f6",
    width: "100%",
    height: "100%",
    minHeight: "100vh"
  },
  image: {
    width: 438,
    maxWidth: "60%"
  },
  text: {
    maxWidth: 600,
    marginTop: 30,
    fontSize: 20,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.1px",
    color: "#4a4a4a"
  },
  textMobile: {
    marginTop: 30,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.1px",
    color: "#4a4a4a",
    maxWidth: "80%",
    textAlign: "center"
  },
  store: {
    maxWid: 130,
    height: 38
  },
  storeMobile: {
    maxWid: 108,
    height: 32
  },
  storeContainer: {
    marginTop: 25
  },
  apple: {
    marginRight: 30
  },
  daheimButton: {
    height: 50,
    marginTop: 25
  }
}));

export const VerifyEmail: FunctionComponent<IVerifyEmailProps> = props => {
  const query = useQuery();
  const classes = useStyles();
  const { t } = useTranslation();

  const code = query.get("code");

  const [verifyMutation, { data: verificationResult, loading: isVerifyingEmail }] = useMutation<
    verifyEmail,
    verifyEmailVariables
  >(verifyEmailMutation);

  useEffect(() => {
    if (code) {
      verifyMutation({ variables: { code } });
    }
  }, [code]);

  if (verificationResult && verificationResult.verifyEmail) {
    const system = isMobile ? "MOBILE" : "DESKTOP";
    const status = VerifyEmailStatus.CODE_ALREADY_USED;

    return (
      <Grid
        className={classes.container}
        container={true}
        alignItems={"center"}
        justify={"center"}
        direction={"column"}
      >
        <img src={`/images/${t(`verification.image.${status}_${system}`)}`} className={classes.image} alt="" />

        <Typography className={isMobile ? classes.textMobile : classes.text}>
          {t(`verification.text.${status}`)}
        </Typography>

        <MobileView>
          <a href={isIOS ? config.REACT_APP_DAHEIM_IOS_URL : config.REACT_APP_DAHEIM_ANDROID_URL}>
            <img src={`/images/daheim-btn.svg`} className={classes.daheimButton} alt="" />
          </a>
        </MobileView>

        <Grid item={true} className={classes.storeContainer}>
          {isIOS || isBrowser ? (
            <a href={config.REACT_APP_APP_STORE_LINK}>
              <img
                src={"/images/app-store.png"}
                className={`${isMobile ? classes.storeMobile : classes.store} ${!isMobile ? classes.apple : undefined}`}
                alt=""
              />
            </a>
          ) : (
            undefined
          )}
          {isAndroid || isBrowser ? (
            <a href={config.REACT_APP_PLAY_STORE_LINK}>
              <img src={"/images/google-play.png"} className={isMobile ? classes.storeMobile : classes.store} alt="" />
            </a>
          ) : (
            undefined
          )}
        </Grid>
      </Grid>
    );
  }
  return <div />;
};
