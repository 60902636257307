import { default as React, FunctionComponent } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReactTable, { CellInfo, Column } from "react-table";
import { OpenWeighingProcess, OpenWeighingProcessWeight } from "../../../api/graphql/queries/open-weighings";
import { formatWeighing } from "../../../utils/format-weight";
import { renderHeader } from "../../../pages/waste-management";
import { WeighingActions } from "./weighing-actions";
import * as _ from "lodash";

interface IOpenWeighingsTableProps {
  weighingProcess: OpenWeighingProcess;
}

const useStyles = makeStyles((theme: Theme) => ({
  hasToPay: {
    color: "#e02020",
  },
}));

export const OpenWeighingsTable: FunctionComponent<IOpenWeighingsTableProps> = (props) => {
  const { weighingProcess } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const weighingsSorted = _.sortBy(weighingProcess.weighings, (w) => w.waste.name);

  const columns: Column[] = [
    {
      Header: t("weighing_management.overview.waste"),
      accessor: "waste.name",
      style: { whiteSpace: "unset" }, // allows text wrap
      Cell: ({ original, row }: { original: OpenWeighingProcessWeight; row: { _index: number } }) => {
        const previous: OpenWeighingProcessWeight | null = row._index > 0 ? weighingsSorted[row._index - 1] : null;
        return <span>{previous?.waste.name !== original.waste.name ? original.waste.name : ""}</span>;
      },
    },
    {
      Header: t("weighing_management.overview.disposal_allowance"),
      accessor: "disposal_allowance",
      Cell: ({ original, row }: { original: OpenWeighingProcessWeight; row: { _index: number } }) => {
        const previous: OpenWeighingProcessWeight | null = row._index > 0 ? weighingsSorted[row._index - 1] : null;
        return (
          <span className={original.weightToPay > 0 ? classes.hasToPay : ""}>
            {previous?.waste.name !== original.waste.name
              ? formatWeighing(original.disposalAllowance.remainingAmount, original.waste.baseWaste.fractionGroup.unit)
              : ""}
          </span>
        );
      },
    },
    {
      Header: t("weighing_management.overview.weight"),
      Cell: ({ original }: { original: OpenWeighingProcessWeight }) => (
        <span>{formatWeighing(original.weight, original.waste.baseWaste.fractionGroup.unit)}</span>
      ),
    },
    {
      Header: t("weighing_management.overview.to_pay"),
      Cell: ({ original }: { original: OpenWeighingProcessWeight }) => (
        <span>
          {original.weightToPay > 0
            ? formatWeighing(original.weightToPay, original.waste.baseWaste.fractionGroup.unit)
            : "-"}
        </span>
      ),
    },
    {
      width: 100,
      sortable: false,
      accessor: "edit",
      // @ts-ignore
      Cell: ({ original, row }: { original: OpenWeighingProcessWeight; row: { _index: number } }) => {
        return <WeighingActions weighing={original} weighingProcess={weighingProcess} />;
      },
    },
  ].map((column) => ({
    ...column,
    Header: (cellInfo: CellInfo, col: any) =>
      renderHeader(cellInfo, col, typeof column.Header === "string" ? column.Header : ""),
  }));

  return (
    <ReactTable
      columns={columns}
      data={weighingsSorted}
      minRows={weighingProcess.weighings.length}
      showPagination={false}
      sortable={false}
      filterable={false}
    />
  );
};
