import * as React from "react";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Button, Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import Header from "../components/core/header/header";
import { useTranslation } from "react-i18next";
import { FontWeightAbsolute } from "csstype";
import { PageLoadingIndicator } from "../utils/page-loading-indicator";
import * as _ from "lodash";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { OPEN_WEIGHINGS_QUERY, OpenWeighingsResult } from "../api/graphql/queries/open-weighings";
import {
  OPEN_WEIGHINGS_SUBSCRIPTION,
  OpenWeighingsSubscriptionsResult,
} from "../api/graphql/subscriptions/citizens-with-open-weighings-subscriptions";
import { NumberOfWeighingContext } from "../utils/NumberOfWeighingContext";
import { AuthContext } from "../utils/AuthContext";
import { ExportWeighingsDialog } from "../components/weighing-management/export-weighings";
import { StartWeighingProcessDialog } from "../components/weighing-management/start-weighing/start-weighing-process-dialog";
import { OpenWeighingOverview } from "../components/weighing-management/open-weighings-overview/open-weighings-overview";
import { GenerateQrCodeDialog } from "../components/weighing-management/generate-qr-code-dialog";

interface IWeighingManagementProps {}

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: 20,
    fontWeight: "bold" as FontWeightAbsolute,
    lineHeight: "0.9",
    color: theme.palette.primary.main,
  },
  subtitleContainer: {
    marginTop: 25,
    marginBottom: 25,
  },
}));

export const WeighingManagement: FunctionComponent<IWeighingManagementProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { isAuthenticated } = useContext(AuthContext);

  const [showDialog, setShowDialog] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showWaitingCustomersInfo, setShowWaitingCustomersInfo] = useState(false);
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);

  const { data: subData, error: subError } = useSubscription<OpenWeighingsSubscriptionsResult>(
    OPEN_WEIGHINGS_SUBSCRIPTION
  );
  const { data, error, loading, refetch } = useQuery<OpenWeighingsResult>(OPEN_WEIGHINGS_QUERY);
  const { numberOfWeighings, newWeighingText } = useContext(NumberOfWeighingContext);

  useEffect(() => {
    refetch().then();
  }, [isAuthenticated]);

  const onStartWeighing = () => {
    if (!showWaitingCustomersInfo && numberOfWeighings?.shouldShowWarning) {
      setShowWaitingCustomersInfo(true);
    } else {
      setShowDialog(true);
      setShowWaitingCustomersInfo(false);
    }
  };

  const onExportWeighings = () => {
    setShowExport(true);
  };

  if (loading) {
    return <PageLoadingIndicator />;
  } else if (error || subError) {
    // todo
    console.log(subError, subData);
    return <div>error</div>;
  }

  const weighingProcesses = _.sortBy(subData?.openWeighingsSubscription ?? data?.openWeighings ?? [], [
    (d) => d.created_at,
  ]);

  return (
    <div>
      <React.Fragment>
        <Grid container direction={"column"}>
          <Grid item>
            <Grid container justify={"space-between"} alignItems={"center"} spacing={1}>
              {showWaitingCustomersInfo && (
                <Grid item xs={12}>
                  <Typography color={"secondary"} align={"right"}>
                    {newWeighingText ?? ""}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Header title={t("navigation.weighing")} />
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button variant={"contained"} color={"primary"} onClick={() => setShowQrCodeDialog(true)}>
                      {t("weighing_management.generate_qr_code.generate")}
                    </Button>
                  </Grid>
                  {!showWaitingCustomersInfo && (
                    <Grid item>
                      <Button variant={"contained"} color={"primary"} onClick={() => onExportWeighings()}>
                        {t("weighing_management.export.action")}
                      </Button>
                    </Grid>
                  )}
                  {showWaitingCustomersInfo && (
                    <Grid item>
                      <Button variant={"outlined"} color={"primary"} onClick={() => setShowWaitingCustomersInfo(false)}>
                        {t("cancel")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button variant={"contained"} color={"primary"} onClick={() => onStartWeighing()}>
                      {t("weighing_management.start")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} className={classes.subtitleContainer}>
            <Typography className={classes.subtitle}>
              {t("weighing_management.open_weighings")}
              <span
                style={{
                  color:
                    numberOfWeighings?.shouldShowWarning === true
                      ? theme.palette.error.main
                      : theme.palette.primary.main,
                }}
              >
                {numberOfWeighings?.currentOpenWeighings ?? -1}
              </span>
            </Typography>
          </Grid>
        </Grid>

        {showDialog && (
          <StartWeighingProcessDialog
            onSuccess={() => {
              setShowDialog(false);
            }}
            onCloseDialog={() => setShowDialog(false)}
          />
        )}
        <ExportWeighingsDialog isOpen={showExport} onCloseDialog={() => setShowExport(false)} />

        <GenerateQrCodeDialog
          open={showQrCodeDialog}
          onCloseDialog={() => {
            setShowQrCodeDialog(false);
          }}
        />

        <OpenWeighingOverview weighingProcesses={weighingProcesses} refetch={() => {}} />
      </React.Fragment>
    </div>
  );
};
