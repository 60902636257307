import { gql } from "apollo-boost";
import { MutationFunction } from "@apollo/react-common";
import { PasswordReset, PasswordResetVariables } from "./types/PasswordReset";

export const passwordResetMutation = gql`
  mutation PasswordReset($email: String!) {
    passwordReset(email: $email) {
      error
      message
    }
  }
`;

export type PasswordResetMuationFn = MutationFunction<PasswordReset, PasswordResetVariables>;
