import React, { FunctionComponent } from "react";
import "./App.css";
import { createStyles, Theme, makeStyles } from "@material-ui/core";

import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import { Route, Switch, Redirect } from "react-router";
import Portal from "./pages/Portal";
import { PrivateRoute } from "./components/PrivateRoute";
import { VerifyEmail } from "./pages/verify-email";
import { ResetPassword } from "./pages/reset-password";
import { UpdatePasswordToken } from "./pages/update-password-token";
import { LoginPage } from "./pages/Login";

export const drawerWith = 230;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    logoContainer: {
      flexGrow: 1,
    },
    logo: {
      height: "30px",
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    container: {
      display: "flex",
      flexFlow: "column",
      minHeight: "100vh",
      backgroundColor: theme.palette.background.default,
    },
  })
);

export const App: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classnames("App", classes.container)}>
      <Switch>
        <Route path={"/reset-password/complete"} render={(props) => <UpdatePasswordToken {...props} />} />
        <Route path={"/reset-password"} render={(props) => <ResetPassword {...props} />} />
        <Route path={"/verify-email"} render={(props) => <VerifyEmail {...props} />} />
        <PrivateRoute path={"/portal"} render={(props) => <Portal {...props} />} />
        <Route path={"/login"} render={(props) => <LoginPage {...props} />} />
        <Redirect to={"/login"} />
      </Switch>
    </div>
  );
};
