import store from "store";

enum STORE_KEYS {
  TOWN_ID = "citizen_card_town_id"
}

export class CitizenCardService {
  public static getTown(): string | null {
    return store.get(STORE_KEYS.TOWN_ID, null);
  }

  public static setTown(townId: string | null) {
    store.set(STORE_KEYS.TOWN_ID, townId);
  }
}
