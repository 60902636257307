import lodash from "lodash";

function roundUpto(number: number, upto: number): number {
  return Number(number.toFixed(upto));
}

export const formatWeighing = (weight: number | null | undefined, unit: string): string => {
  if (!lodash.isNil(weight) && lodash.isFinite(weight)) {
    return `${roundUpto(weight, 2)} ${unit}`;
  } else {
    return "-";
  }
};
