import React, { FunctionComponent } from "react";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, DialogActions, DialogContentText, Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useLazyQuery } from "@apollo/react-hooks";
import { DOWNLOAD_WEIGHINGS_QUERY } from "../../api/graphql/queries/download-weighings";
import { DownloadWeighings, DownloadWeighingsVariables } from "../../api/graphql/queries/types/DownloadWeighings";
import fileDownload from "js-file-download";
import * as blobUtil from "blob-util";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

interface IExportWeighingsProps {
  isOpen: boolean;
  onCloseDialog: () => void;
}

export const ExportWeighingsDialog: FunctionComponent<IExportWeighingsProps> = props => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [fromDate, setFromDate] = React.useState<Date | null>(
    moment()
      .subtract(1, "month")
      .startOf("month")
      .toDate()
  );
  const [toDate, setToDate] = React.useState<Date | null>(
    moment()
      .subtract(1, "month")
      .endOf("month")
      .toDate()
  );

  function handleFromDateChange(date: MaterialUiPickersDate | null) {
    date && setFromDate(moment(date).toDate());
  }

  function handleToDateChange(date: MaterialUiPickersDate | null) {
    date && setToDate(moment(date).toDate());
  }

  const [downloadData, { loading: isLoading }] = useLazyQuery<DownloadWeighings, DownloadWeighingsVariables>(
    DOWNLOAD_WEIGHINGS_QUERY,
    {
      onCompleted: data => {
        if (data.downloadWeighings) {
          fileDownload(
            blobUtil.base64StringToBlob(data.downloadWeighings),
            `Wiegungen ${moment().format("YYYY-MM-DD HH-mm-ss")}.xlsx`
          );
          enqueueSnackbar(t("weighing_export.status.success"), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          });
          props.onCloseDialog();
        } else {
          enqueueSnackbar(t("weighing_export.status.no_weighings"), {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          });
        }
      },
      onError: () => {
        enqueueSnackbar(t("weighing_export.status.error"), {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        });
      }
    }
  );

  const onStartDownload = (from: Date | null, to: Date | null) => {
    if (from && to) {
      downloadData({ variables: { from, to } });
    }
  };

  return (
    <>
      <Dialog
        open={props.isOpen || false}
        onClose={props.onCloseDialog}
        aria-labelledby="export-dialog-title"
        aria-describedby="export-dialog-description"
      >
        <DialogTitle id="export-dialog-title">{t("weighing_management.export.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="export-dialog-description">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="center">
                <KeyboardDatePicker
                  disableFuture
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd.MM.yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Von"
                  // maxDate={toDate}
                  value={fromDate}
                  onChange={handleFromDateChange}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  invalidDateMessage="Ungültiges Datum"
                  invalidLabel="unbekannt"
                  minDateMessage="Datum darf nicht vor dem 'von' Datum liegen"
                  maxDateMessage="Datum darf nicht nach dem 'bis' Datum liegen"
                />
              </Grid>
              <Grid container justify="center">
                <KeyboardDatePicker
                  disableFuture
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd.MM.yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Bis"
                  minDate={fromDate}
                  value={toDate}
                  onChange={handleToDateChange}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  invalidDateMessage="Ungültiges Datum"
                  invalidLabel="unbekannt"
                  minDateMessage="Datum darf nicht vor dem 'von' Datum liegen"
                  maxDateMessage="Datum darf nicht in der Zukunft liegen"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCloseDialog} color="primary">
            {t("cancel")}
          </Button>
          <Button
            color="secondary"
            autoFocus
            variant={"contained"}
            disabled={isLoading || !toDate || !fromDate || moment(toDate).isBefore(fromDate)}
            onClick={() => onStartDownload(fromDate, toDate)}
          >
            {isLoading && <CircularProgress color="primary" size={20} style={{ marginRight: "1em" }} />}{" "}
            {isLoading ? t("weighing_export.download_in_progress") : t("weighing_export.download")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
