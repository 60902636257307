import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/styles";
import { Dialog, DialogActions, DialogContent, DialogTitle, PropTypes, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import Button from "@material-ui/core/Button";
import { ClipLoader } from "react-spinners";
import * as React from "react";

interface IConfirmationDialogProps extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  content: string | JSX.Element;
  dialogTitle: string | JSX.Element;
  isLoading?: boolean;
  primaryActionText?: string;
  primaryActionColor?: PropTypes.Color;
}

const useStyles = makeStyles((theme: Theme) => ({}));

export const ConfirmationDialog: FunctionComponent<IConfirmationDialogProps> = (props) => {
  const { onClose, onSuccess, content, dialogTitle, isLoading, primaryActionText, primaryActionColor, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Dialog maxWidth={"xs"} fullWidth {...rest}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose()} disabled={isLoading}>
            {t("cancel")}
          </Button>
          <Button
            color={primaryActionColor || "primary"}
            variant={"contained"}
            disabled={isLoading}
            onClick={() => {
              onSuccess();
            }}
          >
            {isLoading ? <ClipLoader color={"white"} size={17} /> : primaryActionText || t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
