import * as React from "react"
import { Paper, Typography } from '@material-ui/core';

interface ILoadingPaperProps {
    label:String
  }
  
  //TODO: make fancy
export const LoadingPaper: React.SFC<ILoadingPaperProps> = (props) => {
    return (
      <Paper>
        <Typography>{props.label}</Typography>
      </Paper>
    );
  }