import { MatchingCitizen_citizenSearch } from "../../api/graphql/queries/types/MatchingCitizen";
import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    citizenItem: {},
    multiLineText: {
      whiteSpace: "pre-line",
    },
    container: {
      height: "100%",
      padding: 8,
    },
  })
);

interface IMatchingCitizenDetailProps {
  citizenHousehold?: MatchingCitizen_citizenSearch;
  contactEmail: String;
}

export const MatchingCitizenDetail: React.FunctionComponent<IMatchingCitizenDetailProps> = ({
  citizenHousehold,
  contactEmail,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (lodash.isNil(citizenHousehold)) {
    return (
      <div>
        <Typography variant={"subtitle2"} style={{ padding: "15px" }}>
          <div className={classes.multiLineText}>{t("user_management.manual_verification.no_match")}</div>
          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </Typography>
      </div>
    );
  }

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.lastname")} <br />
          <b>{citizenHousehold?.citizen.lastName || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.firstname")} <br />
          <b>{citizenHousehold?.citizen.firstName || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.street")} <br />
          <b>{citizenHousehold?.household.street || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.street_number")} <br />
          <b>{citizenHousehold?.household.streetNumber || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.staircase")} <br />
          <b>{citizenHousehold?.household.staircase || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.apartment_number")} <br />
          <b>{citizenHousehold?.household.apartmentNumber || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.city")} <br />
          <b>{citizenHousehold?.household.city || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.postalcode")} <br />
          <b>{citizenHousehold?.household.postalCode || "--"}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.citizenItem}>
        <Typography variant={"body1"}>
          {t("user_data.date_of_birth")} <br />
          <b>{citizenHousehold?.citizen.dateOfBirth || "--"}</b>
        </Typography>
      </Grid>
    </Grid>
  );
};
