import { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Info } from "@material-ui/icons";

interface INoWeighingsProps {}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    color: "gray",
    marginTop: theme.spacing(4),
    opacity: 0.8,
    fontStyle: "italic",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

export const NoWeighings: FunctionComponent<INoWeighingsProps> = (props) => {
  const {} = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container={true} alignContent={"center"} justify={"center"} className={classes.container}>
      <Grid item className={classes.icon}>
        <Info />
      </Grid>
      <Typography>{t("weighing_management.no_open_weighings")}</Typography>
    </Grid>
  );
};
