import * as React from "react";
import { FunctionComponent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DisposalAllowance } from "../../api/graphql/queries/household";
import { ClipLoader } from "react-spinners";
import {
  newDisposalAllowancesForHousehold,
  NewDisposalAllowancesForHouseholdFn,
} from "../../api/graphql/mutations/new-disposal-allowances-for-household";
import { useArray } from "denode-react";
import {
  NewDisposalAllowancesForHousehold,
  NewDisposalAllowancesForHouseholdVariables,
} from "../../api/graphql/mutations/types/NewDisposalAllowancesForHousehold";
import { Mutation } from "@apollo/react-components";

interface IManageDisposalAllowanceDialogProps {
  disposalAllowance: DisposalAllowance[];
  onCancel: () => void;
  householdId: string;
  onSuccess: () => void;
}

interface IEntryData {
  disposalAllowance: DisposalAllowance;
  newValue?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  disposalRow: {
    marginTop: 8,
  },
  titleCell: {
    display: "flex",
    alignItems: "center",
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 16,
    "font-weight": "bold",
  },
}));

export const ManageDisposalAllowanceDialog: FunctionComponent<IManageDisposalAllowanceDialogProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  useArray();
  const [disposalData, disposalDataOperations] = useArray<IEntryData>(
    props.disposalAllowance.map((da) => ({
      disposalAllowance: da,
      newValue: `${da.totalAmount}`,
    }))
  );

  const [apiError, setApiError] = useState(false);

  const onWeightChanged = (weight: string, atIndex: number) => {
    disposalDataOperations.updateAt(atIndex, (item) => ({ ...item, newValue: weight }));
  };

  const onSend = (mutation: NewDisposalAllowancesForHouseholdFn) => {
    const isValid =
      disposalData.map((v) => v.newValue).filter((v) => !v || isNaN(Number(v)) || Number(v) < 0).length === 0;
    setApiError(false);
    if (isValid) {
      mutation({
        variables: {
          householdId: props.householdId,
          newDisposalAllowances: disposalData.map((d) => ({
            baseWasteId: d.disposalAllowance.waste.baseWasteId,
            weight: Number(d.newValue),
          })),
        },
      }).then((result) => {
        if (result && result.data && !result.data.newDisposalAllowanceForHousehold.error) {
          props.onSuccess();
        } else {
          setApiError(true);
        }
      });
    }
  };

  return (
    <Mutation<NewDisposalAllowancesForHousehold, NewDisposalAllowancesForHouseholdVariables>
      mutation={newDisposalAllowancesForHousehold}
    >
      {(mutation, result) => (
        <Dialog open={true} fullWidth={true} maxWidth={"md"}>
          <form>
            <DialogTitle>{t("disposal_allowance_management.title")}</DialogTitle>
            <DialogContent>
              <Grid container={true}>
                {disposalData.map((newDisposalAllowance, index) => (
                  <Grid
                    item={true}
                    xs={12}
                    key={newDisposalAllowance.disposalAllowance.waste.baseWasteId}
                    className={classes.disposalRow}
                  >
                    <Grid container={true}>
                      <Grid item={true} xs={6} className={classes.titleCell}>
                        <b>{newDisposalAllowance.disposalAllowance.waste.name}&nbsp;</b> (bisher{" "}
                        {newDisposalAllowance.disposalAllowance.totalAmount}{" "}
                        {newDisposalAllowance.disposalAllowance.waste.baseWaste.fractionGroup.unit})
                      </Grid>
                      <Grid item={true} xs={6}>
                        <FormControl
                          fullWidth={true}
                          error={
                            !newDisposalAllowance.newValue ||
                            isNaN(Number(newDisposalAllowance.newValue)) ||
                            Number(newDisposalAllowance.newValue) < 0
                          }
                        >
                          <InputLabel>{newDisposalAllowance.disposalAllowance.waste.name}</InputLabel>
                          <Input
                            endAdornment={
                              <InputAdornment position="end">
                                {newDisposalAllowance.disposalAllowance.waste.baseWaste.fractionGroup.unit}
                              </InputAdornment>
                            }
                            inputProps={{ type: "number" }}
                            value={newDisposalAllowance.newValue}
                            onChange={(e) => onWeightChanged(e.target.value, index)}
                          />
                          {!newDisposalAllowance.newValue ||
                          isNaN(Number(newDisposalAllowance.newValue)) ||
                          Number(newDisposalAllowance.newValue) < 0 ? (
                            <FormHelperText>{t("disposal_allowance_management.error_weight")}</FormHelperText>
                          ) : undefined}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {apiError ? (
                <Typography className={classes.error}>{t("disposal_allowance_management.api_error")}</Typography>
              ) : undefined}
            </DialogContent>
            <DialogActions style={{ paddingBottom: 10, paddingRight: 10 }}>
              <Button color="primary" onClick={() => props.onCancel()}>
                {t("cancel")}
              </Button>
              <Button
                color="primary"
                variant={"contained"}
                type={"submit"}
                onClick={(event) => {
                  event.preventDefault();
                  onSend(mutation);
                }}
              >
                {result.loading ? <ClipLoader color={"white"} size={17} /> : t("save")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Mutation>
  );
};
