import { gql } from "apollo-boost";

export const MATCHING_CITIZENS_QUERY = gql`
  query MatchingCitizen(
    $firstName: String
    $lastName: String
    $street: String
    $streetNumber: String
    $city: String
    $dateOfBirth: String
    $postalCode: String
    $apartmentNumber: String
    $staircase: String
  ) {
    citizenSearch(
      citizen: {
        firstName: $firstName
        lastName: $lastName
        street: $street
        streetNumber: $streetNumber
        city: $city
        dateOfBirth: $dateOfBirth
        postalCode: $postalCode
        apartmentNumber: $apartmentNumber
        staircase: $staircase
      }
      first: null
    ) {
      citizen {
        citizenId
        firstName
        lastName
        dateOfBirth
      }
      household {
        staircase
        apartmentNumber
        street
        streetNumber
        postalCode
        city
      }
    }
  }
`;
