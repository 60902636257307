import { default as React, FunctionComponent } from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { SwitchStateKeys } from "./switch-state";

interface ISingleUserSwitchControlProps {
  checked: boolean;
  switchStateKey: SwitchStateKeys;
  title: string;
  text?: string | null | undefined;
  onSwitchChange: (switchState: SwitchStateKeys | string, checked: boolean) => void;
}

export const SingleUserSwitchControl: FunctionComponent<ISingleUserSwitchControlProps> = props => {
  const { checked, switchStateKey, title, text, onSwitchChange } = props;

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={e => onSwitchChange(switchStateKey, e.target.checked)}
            value={checked}
            color="primary"
          />
        }
        label={
          <div>
            <div>{title}</div>
            <b>{text || "--"}</b>
          </div>
        }
      />
    </Grid>
  );
};
