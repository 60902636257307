import React, { useEffect } from "react";
import { Theme, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { MatchingCitizen_citizenSearch } from "../../api/graphql/queries/types/MatchingCitizen";
import { MatchingCitizenDetail } from "./citizen-detail";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { BounceLoader } from "react-spinners";
import { PRIMARY_COLOR } from "../../index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden"
    },
    container: {
      height: "100%"
    }
  })
);

interface ICitizenStepperProps {
  citizens?: MatchingCitizen_citizenSearch[] | null | undefined;
  contactEmail: String;
  onCitizenChanged: (citizen: MatchingCitizen_citizenSearch | null) => void;
  isLoading: boolean;
}

export const CitizenStepper: React.FunctionComponent<ICitizenStepperProps> = ({
  citizens,
  contactEmail,
  onCitizenChanged,
  isLoading
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    setActiveStep(0);
    publishCitizen();
  }, [citizens]);

  useEffect(() => {
    publishCitizen();
  }, [activeStep]);

  if (isLoading) {
    return (
      <Grid container={true} justify={"center"} alignContent={"center"} style={{ height: "100%" }}>
        <BounceLoader color={PRIMARY_COLOR} />
      </Grid>
    );
  }

  if (lodash.isNil(citizens) || lodash.isEmpty(citizens)) {
    return (
      <Grid>
        <MatchingCitizenDetail citizenHousehold={undefined} contactEmail={contactEmail} />
      </Grid>
    );
  }

  const maxSteps = citizens.length + 1;

  function publishCitizen() {
    if (lodash.isNil(citizens)) {
      onCitizenChanged(null);
    } else {
      onCitizenChanged(citizens[activeStep]);
    }
  }

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    // publishCitizen()
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    // publishCitizen()
  }

  return (
    <Grid container direction="column" justify="space-between" className={classes.container}>
      <Grid item>
        <MatchingCitizenDetail citizenHousehold={citizens[activeStep]} contactEmail={contactEmail} />
      </Grid>
      <Grid item>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              {t("user_management.manual_verification.stepper_next")}
              {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              {t("user_management.manual_verification.stepper_back")}
            </Button>
          }
        />
      </Grid>
    </Grid>
  );
};
