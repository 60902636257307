import { gql } from "apollo-boost";
import { MutationFunction } from "@apollo/react-common";
import {
  NewDisposalAllowancesForHousehold,
  NewDisposalAllowancesForHouseholdVariables
} from "./types/NewDisposalAllowancesForHousehold";

export const newDisposalAllowancesForHousehold = gql`
  mutation NewDisposalAllowancesForHousehold($newDisposalAllowances: [DisposalAllowanceInput!]!, $householdId: ID!) {
    newDisposalAllowanceForHousehold(newDisposalAllowances: $newDisposalAllowances, householdId: $householdId) {
      error
      message
    }
  }
`;

export type NewDisposalAllowancesForHouseholdFn = MutationFunction<
  NewDisposalAllowancesForHousehold,
  NewDisposalAllowancesForHouseholdVariables
>;
