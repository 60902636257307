import gql from "graphql-tag";
import {
  openWeighings,
  openWeighings_openWeighings,
  openWeighings_openWeighings_citizenHousehold,
  openWeighings_openWeighings_citizenHousehold_citizen,
  openWeighings_openWeighings_weighings,
} from "./types/openWeighings";

export const OPEN_WEIGHINGS_QUERY = gql`
  query openWeighings {
    openWeighings {
      id
      state
      additional_weighing_info
      created_at
      sap_weighing_nr
      weighings {
        id
        weight
        weightToPay
        paidAt
        createdAt
        disposalAllowance {
          totalAmount
          remainingAmount
        }
        waste {
          name
          baseWasteId
          defaultAllowance

          baseWaste {
            originalName
            baseWasteId
            fractionGroup {
              unit
            }
          }
        }
      }
      citizenHousehold {
        typeOfResidence
        citizen {
          citizenId
          firstName
          lastName
        }
        household {
          householdId
          street
          streetNumber
          town {
            id
            name
          }
          disposalAllowance {
            totalAmount
            individual
            remainingAmount
            waste {
              name
              baseWasteId
              defaultAllowance
              baseWaste {
                originalName
                baseWasteId
                fractionGroup {
                  unit
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type OpenWeighingsResult = openWeighings;
// export type OpenWeighing = openWeighings_openWeighings;
export type OpenWeighingProcess = openWeighings_openWeighings;
export type OpenWeighingProcessWeight = openWeighings_openWeighings_weighings;
export type OpenWeighingCitzen = openWeighings_openWeighings_citizenHousehold_citizen;
export type OpenWeighingCitizenHousehold = openWeighings_openWeighings_citizenHousehold;
