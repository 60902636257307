import gql from "graphql-tag";
import { AddOrEditWeighing } from "./types/AddOrEditWeighing";

export const ADD_OR_EDIT_WEIGHING_MUTATION = gql`
  mutation AddOrEditWeighing(
    $weighingProcessId: ID!
    $weight: Float!
    $townId: ID!
    $householdId: ID!
    $baseWasteId: ID!
    $weighingId: ID
  ) {
    addOrEditWeighing(
      weightProcessId: $weighingProcessId
      weight: $weight
      townId: $townId
      householdId: $householdId
      baseWasteId: $baseWasteId
      weighingId: $weighingId
    ) {
      error
      message
    }
  }
`;

export type AddOrEditWeighingResult = AddOrEditWeighing;
