import { gql } from "apollo-boost";

export const NUMBER_OF_OPEN_WEIGHINGS_SUBSCRIPTION = gql`
  subscription NumberOfOpenWeighingsSubscription {
    numberOfOpenWeighingsSubscription {
      currentOpenWeighings
      shouldShowWarning
    }
  }
`;
