import * as React from "react";
import { IBaseProps } from "../utils/BaseProps";
import { withTranslation } from "react-i18next";
import {
  Button,
  createStyles,
  DialogActions,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import i18n from "i18next";
import classNames from "classnames";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/MenuRounded";
import { drawerWith } from "../App";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { AuthConsumer } from "../utils/AuthContext";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import lodash from "lodash";
import { UserRole } from "../api/graphql/graphql-global-types";
import { InfoOutlined } from "@material-ui/icons";
import { NumberOfWeighingContextConsumer } from "../utils/NumberOfWeighingContext";

interface ISidebarProps extends IBaseProps, RouteComponentProps {}

interface ISidebarState {
  selectedMenuItem?: IMenuItem;
  open: boolean;
  logoutDialogOpen: boolean;
  logoutProcedure: (() => void) | null;
}

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWith,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWith,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(9) + 1,
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
    },
    logoContainer: {
      height: 80,
    },
    menuItem: {
      paddingLeft: "12px",
      color: "white",
      marginBottom: "10px",
    },
    menuItemText: {
      color: "white !important",
      fontSize: "14px",
    },

    menuItemTextSelected: {
      color: "white !important",
      fontSize: "14px",
      fontWeight: "bold",
    },
    menuItemTextRoot: {
      padding: 0,
    },
    listItemIconRoot: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    listItemIconRootCollapsed: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginRight: 10,
    },
    listItemRoot: {
      height: 48,
      marginTop: 5,
      marginBottom: 5,
      paddingTop: 5,
      paddingBottom: 5,
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
      },
    },
    listItemRootCollapsed: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      width: "100%",
      marginRight: 10,
    },
    selected: {
      backgroundColor: "rgba(255, 255, 255, 0.25) !important",
    },
    logoCollapsed: {
      width: 60,
    },
  });

interface IMenuItem {
  title: string;
  icon: string;
  id: string;
  route?: string;
  onClick?: (logout: (() => void) | null) => void;
  roles: Array<UserRole>;
  info?: string;
}

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  private onLogoutClick = (logout: (() => void) | null) => {
    this.setState({
      logoutDialogOpen: true,
      logoutProcedure: logout,
    });
  };

  private menuItems = (numberOfWeighingsTooltipText?: string): IMenuItem[] => {
    return [
      /* {id: 'dashboard', title: i18n.t('navigation.dashboard'), icon: 'icon-dashboard.svg', roles: [ UserRole.ADMIN,UserRole.TOWN_ADMIN ]}, */
      {
        id: "weighing_overview",
        title: i18n.t("navigation.weighing"),
        icon: "icon-weighing.svg",
        route: "/portal/weighing-management",
        roles: [UserRole.ADMIN, UserRole.WEIGHING_MASTER],
        info: numberOfWeighingsTooltipText,
      },
      {
        id: "household_overview",
        title: i18n.t("navigation.household"),
        icon: "icon-household.svg",
        route: "/portal/household-overview",
        roles: [UserRole.ADMIN, UserRole.TOWN_ADMIN, UserRole.WEIGHING_MASTER],
      },
      {
        id: "user_management",
        title: i18n.t("navigation.user_management"),
        icon: "icon-user.svg",
        route: "/portal/user-management",
        roles: [UserRole.ADMIN, UserRole.TOWN_ADMIN],
      },
      {
        id: "fraction_change",
        title: i18n.t("navigation.fraction_management"),
        icon: "icon-waste-management.svg",
        route: "/portal/waste-management",
        roles: [UserRole.ADMIN, UserRole.TOWN_ADMIN, UserRole.WEIGHING_MASTER],
      },
      {
        id: "logout",
        title: i18n.t("navigation.logout"),
        icon: "icon_exit.svg",
        onClick: (logout) => this.onLogoutClick(logout),
        roles: [UserRole.ADMIN, UserRole.TOWN_ADMIN, UserRole.WEIGHING_MASTER],
      },
    ];
  };

  constructor(props: ISidebarProps) {
    super(props);

    this.state = {
      selectedMenuItem: this.menuItems().find((m) => m.route === props.location.pathname),
      open: true,
      logoutDialogOpen: false,
      logoutProcedure: null,
    };
  }

  private onMenuItemClick = (item: IMenuItem, logout: (() => void) | null | undefined) => {
    if (item.route) {
      this.props.history.push(item.route);
      this.setState({ selectedMenuItem: item });
    } else if (item.onClick) {
      let logoutProcedure: (() => void) | null = null;
      if (!lodash.isNil(logout)) {
        logoutProcedure = logout;
      }
      item.onClick(logoutProcedure);
    }
  };

  private isItemSelected = (item: IMenuItem): boolean => {
    const { selectedMenuItem } = this.state;

    return selectedMenuItem ? selectedMenuItem.id === item.id : false;
  };

  private onHandleMenuClick = () => {
    this.setState({ open: !this.state.open });
  };

  private handleLogoutClose = () => {
    this.setState({ logoutDialogOpen: false, logoutProcedure: null });
  };

  private handleLogout = () => {
    if (!lodash.isNil(this.state.logoutProcedure)) {
      this.state.logoutProcedure();
    }

    this.setState({ logoutDialogOpen: false, logoutProcedure: null });
  };

  private renderMenu = () => {
    const { classes } = this.props;
    const { open, logoutDialogOpen } = this.state;
    return (
      <AuthConsumer>
        {({ isAuthenticated, logout, hasRoles }) =>
          !isAuthenticated ? (
            <Redirect to="/login" />
          ) : (
            <div>
              <NumberOfWeighingContextConsumer>
                {({ tooltipText }) => (
                  <List>
                    {this.menuItems(tooltipText)
                      .filter((item) => hasRoles !== undefined && hasRoles(item.roles))
                      .map((item, index) => (
                        <MenuItem
                          key={index}
                          button={true}
                          disableGutters={true}
                          classes={{
                            root: classes.listItemRoot,
                            selected: classes.selected,
                          }}
                          onClick={() => this.onMenuItemClick(item, logout)}
                          selected={this.isItemSelected(item)}
                        >
                          <ListItemIcon
                            classes={{
                              root: classNames(classes.listItemIconRoot, {
                                [classes.listItemIconRootCollapsed]: !open,
                              }),
                            }}
                          >
                            <img src={`images/${item.icon}`} className={classes.iconContainer} alt="" />
                          </ListItemIcon>
                          {open && (
                            <Grid container justify={"space-between"} alignItems={"center"}>
                              <Grid item>
                                <ListItemText
                                  primary={item.title}
                                  classes={{
                                    primary: this.isItemSelected(item)
                                      ? classes.menuItemTextSelected
                                      : classes.menuItemText,
                                    root: classes.menuItemTextRoot,
                                  }}
                                />
                              </Grid>
                              {!!item.info && (
                                <Grid item style={{ height: 24 }}>
                                  <Tooltip title={item.info}>
                                    <InfoOutlined style={{ color: "white" }} />
                                  </Tooltip>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </MenuItem>
                      ))}
                  </List>
                )}
              </NumberOfWeighingContextConsumer>
              <Dialog
                open={logoutDialogOpen}
                onClose={this.handleLogoutClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{i18n.t("logout.title")}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">{i18n.t("logout.message")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleLogoutClose} color="primary">
                    {i18n.t("logout.cancel")}
                  </Button>
                  <Button onClick={this.handleLogout} color="secondary" autoFocus variant={"contained"}>
                    {i18n.t("logout.confirm")}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )
        }
      </AuthConsumer>
    );
  };
  resize = () => {
    const { open } = this.state;

    let newState = window.innerWidth > 992;

    if (open !== newState) {
      this.setState({
        open: newState,
      });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  public render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <Drawer
        variant={"permanent"}
        anchor={"left"}
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classNames([
            classes.drawerPaper,
            {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            },
          ]),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <Grid container={true} justify={open ? "flex-end" : "center"}>
            <IconButton onClick={this.onHandleMenuClick} style={{ color: "white" }}>
              {open ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </Grid>
        </div>
        <Grid container={true} alignContent={"stretch"} direction={"column"} style={{ flexWrap: "unset" }}>
          <Grid item={true} className={classes.logoContainer} xs={12}>
            <Grid container={true} alignContent={"center"} justify={"center"} style={{ height: "100%" }}>
              <img src={"./images/logo-daheim.svg"} className={!open ? classes.logoCollapsed : undefined} alt="" />
            </Grid>
          </Grid>
          <Grid item={true} xs={12}>
            {this.renderMenu()}
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(Sidebar)));
