import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/react-hooks";
import { SEARCH_CITIZEN_QUERY, SearchCitizen, SearchCitizensResult } from "../../api/graphql/queries/search-citizens";
import { SearchCitizensVariables } from "../../api/graphql/queries/types/SearchCitizens";
import { CITIZEN_LIMIT } from "../../index";

interface ISelectCitizenProps {
  townId?: string | null;
  onCitizenChanged: (citizen: SearchCitizen | null) => void;
  showError: boolean;
}

export const SelectCitizen: FunctionComponent<ISelectCitizenProps> = (props) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [options, setOptions] = useState<SearchCitizen[]>([]);
  const { townId } = props;

  const [loadCitizens, { data: citizensData, loading }] = useLazyQuery<SearchCitizensResult, SearchCitizensVariables>(
    SEARCH_CITIZEN_QUERY,
    {
      onCompleted: (data) => data && setOptions(data.searchCitizens),
    }
  );

  const loadCitizensDebounced = useCallback(_.debounce(loadCitizens, 500), [loadCitizens]);

  useEffect(() => {
    console.log("load citizens", townId);
    if (townId && townId.length > 0) {
      loadCitizensDebounced({
        variables: {
          searchTerm,
          townId: townId ?? "",
          limit: CITIZEN_LIMIT,
        },
      });
    }
  }, [loadCitizensDebounced, searchTerm, townId]);

  return (
    <Autocomplete<SearchCitizen>
      getOptionSelected={(option, value) => option.citizenId === value.citizenId}
      disabled={!props.townId}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}, ${option.street} ${option.streetNumber}`}
      options={options}
      loading={loading}
      onChange={(e: React.ChangeEvent<{}>, value: SearchCitizen | null) => props.onCitizenChanged(value)}
      filterOptions={(options, state) => {
        return options;
      }}
      onClose={() => setSearchTerm("")}
      noOptionsText={t("no_options")}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("weighing_management.start_dialog.user_select")}
          fullWidth={true}
          error={props.showError}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
