import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import {
  UpdateAdditionalWeighingInfo,
  UpdateAdditionalWeighingInfoVariables,
} from "../../api/graphql/mutations/types/UpdateAdditionalWeighingInfo";
import { updateAdditionalWeighingInfoMutation } from "../../api/graphql/mutations/update-additional-weighing-info";
import { snackbarCloseAction } from "../../utils/snackbar-close-action";

interface IEditAdditionalWeighingInfoDialogProps {
  onCloseDialog: () => void;
  additionalWeighingInfo: string;
  weighingProcessId: string | number;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 5,
    paddingBottom: 17,
  },
  loadingIndicatorContainer: {
    flex: 1,
  },
}));

export const EditAdditionalWeighingInfoDialog: FunctionComponent<IEditAdditionalWeighingInfoDialogProps> = (props) => {
  const { onCloseDialog } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [additionalWeighingInfo, setAdditionalWeighingInfo] = useState<string>(props.additionalWeighingInfo || "");

  const [updateAdditionalWeighingInfo, { loading: isUpdatingAdditionalWeighingInfo }] = useMutation<
    UpdateAdditionalWeighingInfo,
    UpdateAdditionalWeighingInfoVariables
  >(updateAdditionalWeighingInfoMutation);

  const onSend = async () => {
    try {
      await updateAdditionalWeighingInfo({
        variables: {
          additionalWeighingInfo,
          weighingProcessId: `${props.weighingProcessId}`,
        },
      });

      showSuccessSnackbar();
      onCloseDialog();
    } catch (error) {
      showGeneralErrorSnackbar();
      console.log(error);
    }
  };

  const showGeneralErrorSnackbar = () => {
    enqueueSnackbar(t("general_error_message"), {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
    });
  };

  const showSuccessSnackbar = () => {
    enqueueSnackbar(t("general_success_message"), {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
    });
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle>{t("edit_additional_weighing_info_dialog.dialog_title")}</DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <Grid container item={true} xs={12} className={classes.content}>
          <TextField
            id="additional-weighing-info"
            label={t("edit_additional_weighing_info_dialog.additional_weighing_info_label")}
            fullWidth
            value={additionalWeighingInfo}
            onChange={(e) => setAdditionalWeighingInfo(e.target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 10, paddingRight: 10 }}>
        <Button color="primary" onClick={() => onCloseDialog()}>
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          variant={"contained"}
          onClick={(event) => {
            event.preventDefault();
            onSend();
          }}
          type={"submit"}
        >
          {isUpdatingAdditionalWeighingInfo ? <ClipLoader color={"white"} size={17} /> : t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
