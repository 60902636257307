import { gql } from "apollo-boost";
import { MutationFunction } from "@apollo/react-common";
import { manualVerifyUser, manualVerifyUserVariables } from "./types/manualVerifyUser";
export const MANUEL_VERIFY_USER_MUTATION = gql`
  mutation manualVerifyUser($userId: ID!, $citizenId: ID!) {
    verifyUser(userId: $userId, citizenId: $citizenId) {
      error
      message
    }
  }
`;

export type ManualVerifyUserMutationFn = MutationFunction<manualVerifyUser, manualVerifyUserVariables>;
