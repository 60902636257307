import { default as React, FunctionComponent, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OpenWeighingProcess, OpenWeighingProcessWeight } from "../../../api/graphql/queries/open-weighings";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { AddEditWeighingDialog } from "../edit-weighing-dialog";
import { ConfirmationDialog } from "../../core/confirmation-dialog";
import { Delete } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_WEIGHING_MUTATION, DeleteWeighingResult } from "../../../api/graphql/mutations/delete-weighing";
import { DeleteWeighingVariables } from "../../../api/graphql/mutations/types/DeleteWeighing";
import { snackbarCloseAction } from "../../../utils/snackbar-close-action";
import { useSnackbar } from "notistack";

interface IWeighingActionsProps {
  weighing: OpenWeighingProcessWeight;
  weighingProcess: OpenWeighingProcess;
}

const useStyles = makeStyles((theme: Theme) => ({}));

export const WeighingActions: FunctionComponent<IWeighingActionsProps> = (props) => {
  const { weighing, weighingProcess } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [deleteWeighing, { loading: isDeleting }] = useMutation<DeleteWeighingResult, DeleteWeighingVariables>(
    DELETE_WEIGHING_MUTATION
  );

  const [shouldShowEditDialog, setShouldShowEditDialog] = useState(false);
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);

  const onError = () => {
    enqueueSnackbar(t("general_error_message"), {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
    });
  };

  const onDelete = () => {
    deleteWeighing({
      variables: {
        weighingId: weighing.id,
      },
    })
      .then((r) => {
        if (r.data?.deleteWeighing.error) {
          onError();
        } else {
          enqueueSnackbar(t("weighing_management.edit_dialog.confirmation.success"), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
          });
        }
      })
      .catch(onError);
  };

  return (
    <>
      <AddEditWeighingDialog
        onSuccess={() => setShouldShowEditDialog(false)}
        isOpen={shouldShowEditDialog}
        onCloseDialog={() => setShouldShowEditDialog(false)}
        weighingProcess={weighingProcess}
        weighing={weighing}
      />
      <ConfirmationDialog
        open={shouldShowDeleteDialog}
        onClose={() => setShouldShowDeleteDialog(false)}
        onSuccess={onDelete}
        primaryActionText={t("delete")}
        content={
          <Typography>
            {t("weighing_management.edit_dialog.confirmation.message", {
              name: `${weighingProcess.citizenHousehold.citizen.lastName} ${weighingProcess.citizenHousehold.citizen.firstName}`,
              waste: weighing.waste.name,
            })}
          </Typography>
        }
        dialogTitle={t("weighing_management.edit_dialog.confirmation.title")}
        isLoading={isDeleting}
      />
      <Grid container direction={"row"}>
        <IconButton
          onClick={() => {
            setShouldShowEditDialog(true);
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => {
            setShouldShowDeleteDialog(true);
          }}
        >
          <Delete fontSize="small" />
        </IconButton>
      </Grid>
    </>
  );
};
