import React, { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import {
  NumberOfOpenWeighingsSubscription,
  NumberOfOpenWeighingsSubscription_numberOfOpenWeighingsSubscription
} from "../api/graphql/subscriptions/types/NumberOfOpenWeighingsSubscription";
import { NUMBER_OF_OPEN_WEIGHINGS_SUBSCRIPTION } from "../api/graphql/subscriptions/number-of-open-weighings-subscription";
import {
  NumberOfOpenWeighings,
  NumberOfOpenWeighings_numberOfOpenWeighings
} from "../api/graphql/queries/types/NumberOfOpenWeighings";
import { NUMBER_OF_OPEN_WEIGHINGS } from "../api/graphql/queries/number-of-open-weighings";
import { useSnackbar } from "notistack";
import { snackbarCloseAction } from "./snackbar-close-action";
import { useTranslation } from "react-i18next";
import { AuthContext } from "./AuthContext";
import { UserRole } from "../api/graphql/graphql-global-types";
import lodash from "lodash";

export interface INumberOfWeighingContext {
  numberOfWeighings:
    | NumberOfOpenWeighingsSubscription_numberOfOpenWeighingsSubscription
    | NumberOfOpenWeighings_numberOfOpenWeighings
    | null;
  loadingNumberOfWeighing: boolean;
  refetchNumberOfWeighing: () => void;
  tooltipText?: string;
  newWeighingText?: string;
}

export const NumberOfWeighingContext = createContext<INumberOfWeighingContext>({
  numberOfWeighings: null,
  loadingNumberOfWeighing: true,
  refetchNumberOfWeighing: () => null
});

interface INumberOfWeighingContextProviderProps {}

export const NumberOfWeighingContextProvider: FunctionComponent<INumberOfWeighingContextProviderProps> = props => {
  const { children } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { hasRoles } = useContext(AuthContext);

  const [numberOfOpen, setNumberOfOpen] = useState(-1);

  const { data: subNumberOpen, error: subNumberOpenError } = useSubscription<NumberOfOpenWeighingsSubscription>(
    NUMBER_OF_OPEN_WEIGHINGS_SUBSCRIPTION
  );
  const { data: numberOpen, error: numberOpenError, loading: numberOpenLoading, refetch } = useQuery<
    NumberOfOpenWeighings
  >(NUMBER_OF_OPEN_WEIGHINGS);

  if (subNumberOpenError || numberOpenError) {
    console.error("NumberOfWeighingContext couldn't load data", numberOpenError?.message);
  }

  // skip snackbar alerts for non WEIGHING_MASTER
  const showSnackBar = lodash.isFunction(hasRoles) && hasRoles([UserRole.WEIGHING_MASTER, UserRole.ADMIN]);

  const data = subNumberOpen?.numberOfOpenWeighingsSubscription ?? numberOpen?.numberOfOpenWeighings ?? null;
  const snackbarKey = "SNACKBAR.NumberOfWeighingContext";
  useEffect(() => {
    const newNumberOfOpen = data?.currentOpenWeighings ?? -1;
    if (data?.shouldShowWarning === true) {
      if (newNumberOfOpen !== numberOfOpen) {
        setNumberOfOpen(newNumberOfOpen);
        if (showSnackBar) {
          closeSnackbar(snackbarKey);
          enqueueSnackbar(t("weighing_management.many_weighings.snackbar", { count: newNumberOfOpen }), {
            variant: "warning",
            persist: true,
            key: snackbarKey,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            action: key => snackbarCloseAction({ key, closeSnackbar })
          });
        }
      }
    } else {
      setNumberOfOpen(newNumberOfOpen);
      closeSnackbar(snackbarKey);
    }
  }, [data, numberOfOpen]);

  const tooltipText =
    data?.shouldShowWarning === true
      ? t("weighing_management.many_weighings.tooltip", { count: numberOfOpen })
      : undefined;
  const newWeighingText =
    data?.shouldShowWarning === true
      ? t("weighing_management.many_weighings.new_weighing", { count: numberOfOpen })
      : undefined;

  return (
    <NumberOfWeighingContext.Provider
      value={{
        numberOfWeighings: data,
        loadingNumberOfWeighing: numberOpenLoading,
        refetchNumberOfWeighing: refetch,
        tooltipText,
        newWeighingText
      }}
    >
      {children}
    </NumberOfWeighingContext.Provider>
  );
};

export const NumberOfWeighingContextConsumer = NumberOfWeighingContext.Consumer;
