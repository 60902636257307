import numeral from "numeral";

export const initNumeral = () => {
  numeral.register("locale", "de", {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    abbreviations: {
      thousand: "k",
      million: "m",
      billion: "b",
      trillion: "t",
    },
    ordinal: function (number) {
      return ".";
    },
    currency: {
      symbol: "€",
    },
  });

  numeral.locale("de");
};

export const formatAllowance = (val: number, unit: string): string => {
  return `${numeral(val).format("0,0.[00]")} ${unit}`;
};
