import { gql } from "apollo-boost";
import { MutationFunction } from "@apollo/react-common";
import { verifyEmail, verifyEmailVariables } from "./types/verifyEmail";

export const verifyEmailMutation = gql`
  mutation verifyEmail($code: String!) {
    verifyEmail(code: $code)
  }
`;

export type VerifyEmailMuationFn = MutationFunction<verifyEmail, verifyEmailVariables>;
