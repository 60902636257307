import * as React from "react";
import { FunctionComponent } from "react";
import "../../../pages/waste-management-table.css";
import { OpenWeighingsCard } from "./open-weighing-card";
import { OpenWeighingProcess } from "../../../api/graphql/queries/open-weighings";

interface IOpenWeighingsOverviewProps {
  weighingProcesses: OpenWeighingProcess[];
  refetch: Function;
}

export const OpenWeighingOverview: FunctionComponent<IOpenWeighingsOverviewProps> = (props) => {
  return (
    <div>
      {props.weighingProcesses.map((process) => (
        <OpenWeighingsCard weighingProcess={process} key={process.id} refetch={props.refetch} />
      ))}
    </div>
  );
};
