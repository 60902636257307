import gql from "graphql-tag";
import { FinishWeighingProcess } from "./types/FinishWeighingProcess";

export const FINISH_WEIGHING_PROCESS_MUTATION = gql`
  mutation FinishWeighingProcess($weighingProcessId: ID!) {
    finishWeighingProcess(weighinProcessId: $weighingProcessId) {
      error
      message
    }
  }
`;

export type FinishWeighingProcessResult = FinishWeighingProcess;
