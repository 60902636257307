import * as React from "react";
import { IBaseProps } from "../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import { Card, CardActionArea, createStyles, Grid, Theme, Typography, withStyles } from "@material-ui/core";
import loadHouseholdsQuery, { Household, Households } from "../../api/graphql/queries/households";
import { LoadingPaper } from "../core/loading-paper";
import lodash from "lodash";
import { householdsVariables } from "../../api/graphql/queries/types/households";
import { Query } from "@apollo/react-components";

const styles = (theme: Theme) =>
  createStyles({
    listCard: {
      padding: 0,
      margin: 5
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    },
    listCardActionArea: {
      padding: 20
    },
    listCardText: {
      fontWeight: "bold"
    }
  });

interface IListCardProps extends IHouseholdListProps {
  household: Household;
}

function ListCard(props: IListCardProps) {
  const { classes, household } = props;

  if (household) {
    const selected = household.householdId === props.selectedHouseholdId ? " " + classes.selected : "";
    return (
      <Card className={classes.listCard + selected} onClick={() => props.onCardClick(household.householdId)}>
        <CardActionArea className={classes.listCardActionArea}>
          <Typography className={classes.listCardText + selected}>
            {household.street}{" "}
            {[household.streetNumber, household.staircase, household.apartmentNumber].filter(s => s).join("/")}
          </Typography>
        </CardActionArea>
      </Card>
    );
  }
  return null;
}

interface IHouseholdListProps extends IBaseProps {
  onCardClick: Function;
  townId: string | null;
  selectedHouseholdId: string | undefined | null;
}

class HouseholdList extends React.Component<IHouseholdListProps> {
  constructor(props: IHouseholdListProps) {
    super(props);

    this.state = {};
  }

  renderList(data: Households, props: IHouseholdListProps) {
    if (data && data.households && data.households.length !== 0) {
      const cards = data.households.map(household => {
        return <ListCard key={household.householdId} {...props} household={household} />;
      });
      return (
        <Grid container justify="flex-start" direction="column">
          {cards}
        </Grid>
      );
    }
    return <>no data</>;
  }

  render() {
    const { t, townId } = this.props;
    return (
      <div>
        {!lodash.isNil(townId) && (
          <Query<Households, householdsVariables> query={loadHouseholdsQuery} variables={{ townId }}>
            {({ loading, data, error }) =>
              loading ? (
                <LoadingPaper label={t("user_management.loading")} />
              ) : error ? (
                <>error</>
              ) : (
                this.renderList(data, this.props)
              )
            }
          </Query>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(HouseholdList));
