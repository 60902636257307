import * as React from "react";
import { IBaseProps } from "../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import {
  Button,
  createStyles,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
// import { LoadingPaper } from '../core/loading-paper';
import loadHouseholdDataQuery, { Citizens, DisposalAllowance, Household } from "../../api/graphql/queries/household";
import Subheader from "../core/header/subheader";
import PaperTable from "../core/table/paper-table";
import { ManageDisposalAllowanceDialog } from "./manage-disposal-allowance";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { UserRole } from "../../api/graphql/graphql-global-types";
import { AuthConsumer } from "../../utils/AuthContext";
import { snackbarCloseAction } from "../../utils/snackbar-close-action";
// import SearchBar from '../components/core/search-bar';
import { formatAllowance } from "../../utils/numeral";
import { Query } from "@apollo/react-components";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 5,
      marginLeft: 10,
    },
    listCard: {
      padding: 0,
      margin: 5,
      width: "100%",
    },
    listCardActionArea: {
      padding: 20,
    },
    listCardText: {},
    tableHead: {
      backgroundColor: theme.palette.primary.main,
    },
    tableHeadText: {
      color: "white",
      fontWeight: "bold",
    },
  });

interface IHouseholdDetailProps extends IBaseProps, WithSnackbarProps {
  selectedHouseholdId: String | null | undefined;
}

interface ICitizenTableProps extends IHouseholdDetailProps {
  citizens: Citizens;
}

function CitizenTable(props: ICitizenTableProps) {
  const { t, classes, citizens } = props;

  if (citizens) {
    const citizenTableRows = citizens.map((citizen) => {
      let email = "-";
      let status = t("user_data.not_registered");
      if (citizen.user) {
        status = citizen.user.isVerifiedCitizen ? t("user_data.registered") : status;
        email = citizen.user.email ? citizen.user.email : email;
      }

      return (
        <TableRow key={citizen.citizenId}>
          <TableCell>
            <Typography>
              {citizen.lastName} {citizen.firstName}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{citizen.dateOfBirth || "-"}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{email}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{status}</Typography>
          </TableCell>
        </TableRow>
      );
    });

    return (
      <PaperTable>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>
              <Typography className={classes.tableHeadText}>{t("user_data.name")}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHeadText}>{t("user_data.date_of_birth")}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHeadText}>{t("user_data.email")}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHeadText}>{t("user_data.status")}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{citizenTableRows}</TableBody>
      </PaperTable>
    );
  }
  return null;
}

interface IAllowanceTableProps extends IHouseholdDetailProps {
  disposalAllowance: DisposalAllowance[];
}

//TODO: change the name to something less stupid sounding
function AllowanceTable(props: IAllowanceTableProps) {
  const { t, classes, disposalAllowance } = props;

  const citizenTableRows = disposalAllowance
    .sort((a, b) => a.waste.name.localeCompare(b.waste.name))
    .map((allowance) => {
      return (
        <TableRow key={allowance.waste.baseWasteId}>
          <TableCell>
            <Typography>{allowance.waste.name}</Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {formatAllowance(allowance.totalAmount, allowance.waste.baseWaste.fractionGroup.unit)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {formatAllowance(
                allowance.totalAmount - allowance.remainingAmount,
                allowance.waste.baseWaste.fractionGroup.unit
              )}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {formatAllowance(allowance.remainingAmount, allowance.waste.baseWaste.fractionGroup.unit)}
            </Typography>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <PaperTable>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell>
            <Typography className={classes.tableHeadText}>{t("allowance.fraction")}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.tableHeadText}>{t("allowance.sum")}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.tableHeadText}>{t("allowance.consumed")}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.tableHeadText}>{t("allowance.available")}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {disposalAllowance.length > 0 ? (
          citizenTableRows
        ) : (
          <TableRow>
            <TableCell>
              <Typography>{t("no_data")}</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </PaperTable>
  );
}

class HouseholdDetail extends React.Component<IHouseholdDetailProps, { showDialog: boolean }> {
  constructor(props: IHouseholdDetailProps) {
    super(props);

    this.state = {
      showDialog: false,
    };
  }

  private onCancelDialog = () => {
    this.setState({
      showDialog: false,
    });
  };

  private onSuccessDialog = (refetchHousehold: Function) => {
    refetchHousehold();
    this.props.enqueueSnackbar(this.props.t("disposal_allowance_management.success"), {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: this.props.closeSnackbar }),
    });
    this.setState({
      showDialog: false,
    });
  };

  private onShowDialog = () => {
    this.setState({
      showDialog: true,
    });
  };

  renderDetails(data: Household, props: IHouseholdDetailProps, refetchHousehold: Function) {
    if (data && data.household) {
      const { household } = data;
      return (
        <div>
          <Subheader
            title={
              household.street +
              " " +
              [household.streetNumber, household.staircase, household.apartmentNumber].filter((s) => s).join("/")
            }
          />
          <Subheader title={"Bewohner"} />
          <CitizenTable {...props} citizens={household.citizens} />
          <Subheader title={"Freimengen"} />
          <AllowanceTable {...props} disposalAllowance={household.disposalAllowance} />
          <AuthConsumer>
            {({ hasRoles }) =>
              hasRoles !== undefined && hasRoles([UserRole.TOWN_ADMIN, UserRole.ADMIN]) ? (
                <div>
                  <Grid container={true} justify={"flex-end"}>
                    <Button onClick={() => this.onShowDialog()} variant={"contained"} color={"primary"}>
                      {props.t("disposal_allowance_management.title")}
                    </Button>
                  </Grid>
                  {this.state.showDialog && (
                    <ManageDisposalAllowanceDialog
                      disposalAllowance={household.disposalAllowance}
                      householdId={household.householdId}
                      onCancel={this.onCancelDialog}
                      onSuccess={() => this.onSuccessDialog(refetchHousehold)}
                    />
                  )}
                </div>
              ) : (
                <div></div>
              )
            }
          </AuthConsumer>
        </div>
      );
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Query<Household> query={loadHouseholdDataQuery} variables={{ householdId: this.props.selectedHouseholdId }}>
          {({ loading, data, error, refetch }) =>
            loading ? null : error ? <>error</> : this.renderDetails(data, this.props, refetch)
          }
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(withTranslation()(HouseholdDetail)));
