import { FunctionComponent } from "react";
import { useQuery } from "@apollo/react-hooks";
import { CheckImport, CheckImportVariables } from "../../api/graphql/queries/types/CheckImport";
import { CHECK_IMPORT_QUERY } from "../../api/graphql/queries/check-import";
import { ImportStatus } from "../../api/graphql/graphql-global-types";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme } from "@material-ui/core";
import { BounceLoader } from "react-spinners";
import { PRIMARY_COLOR } from "../../index";

interface IImportWaitingProps {
  importId: string;
  onSuccess: () => void;
  onError: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 150
  }
}));

export const ImportWaiting: FunctionComponent<IImportWaitingProps> = props => {
  const classes = useStyles();
  const { data: checkResult } = useQuery<CheckImport, CheckImportVariables>(CHECK_IMPORT_QUERY, {
    pollInterval: 5000,
    variables: { importId: props.importId }
  });

  if (checkResult?.checkImport === ImportStatus.SUCCESS) {
    props.onSuccess();
  } else if (checkResult?.checkImport === ImportStatus.FAILED) {
    props.onError();
  }

  return (
    <Grid container className={classes.container} justify={"center"} alignItems={"center"} direction={"column"}>
      <Grid item>
        <BounceLoader size={70} color={PRIMARY_COLOR} />
      </Grid>
      <Grid>Daten werden importiert. Diese kann einige Zeit dauern. Bitte schließen Sie nicht das Browserfenster</Grid>
    </Grid>
  );
};
