import * as React from "react";
import { FunctionComponent, useContext, useState } from "react";
import { ReactComponent as Logo } from "../assets/logo-daheim.svg";
import { Button, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { Redirect } from "react-router";
import jwt from "jsonwebtoken";
import { updatePasswordTokenMutation } from "../api/graphql/mutations/update-password-token";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { AuthContext } from "../utils/AuthContext";
import {
  UpdatePasswordToken as UpdatePasswordTokenType,
  UpdatePasswordTokenVariables,
} from "../api/graphql/mutations/types/UpdatePasswordToken";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { useQuery } from "../utils/use-query";
import { isIOS, MobileView, BrowserView } from "react-device-detect";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { config } from "../utils/config";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "white",
    display: "flex",
    flexFlow: "row",
    flex: 1,
    paddingTop: 50,
  },
  daheimButton: {
    display: "block",
    height: 50,
    textDecoration: "none",
    color: "#3b474f",
    backgroundColor: "white",
    borderRadius: 50,
    padding: "0 25px 0 25px",
    lineHeight: "50px",
  },

  content: {
    display: "flex",
    flex: 1,
    flexFlow: "column",
    padding: 16,
    alignItems: "center",
  },

  component: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },

  textInputComponent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    maxWidth: "80%",
    width: 480,
  },

  logo: {
    width: 250,
    height: "100%",
    paddingBottom: 0,
    altText: "Daheim ASZ-Portal",
  },

  resetTitle: {
    color: theme.palette.primary.main,
    fontSize: 26,
    fontWeight: "bold",
    margin: 10,
    paddingBottom: 1,
    paddingTop: 15,
    textAlign: "center",
  },

  resetSubTitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "normal",
    margin: 10,
    paddingBottom: 25,
    maxWidth: 480,
  },

  resetOk: {
    color: "rgb(119, 232, 86)",
    fontSize: 100,
  },

  resetNok: {
    color: "red",
    fontSize: 100,
  },

  textField: {
    backgroundColor: "white",
    borderRadius: 2,
  },

  paper: {
    display: "flex",
    maxWidth: "80%",
    width: 480,
    color: theme.palette.text.secondary,
    height: 50,
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 20,
    paddingRitht: 20,
    justifyContent: "center",
    alignItems: "center",
  },

  resetbutton: {
    marginTop: 20,
    marginBottom: 15,
    width: 260,
  },

  passwordReset: {
    marginTop: 5,
    fontSize: 12,
    color: "white",
    cursor: "pointer",
  },
  loginErrorText: {
    color: theme.palette.warning.main,
    fontSize: 14,
    marginTop: 20,
    maxWidth: 480,
  },
}));

interface IUpdatePasswordTokenProps {}

type TokenPayload = {
  exp: number;
};

export const UpdatePasswordToken: FunctionComponent<IUpdatePasswordTokenProps> = (props) => {
  const query = useQuery();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useContext(AuthContext);
  const token = query.get("token") || "";
  const payload = token && token.length > 0 ? (jwt.decode(token) as TokenPayload) : null;
  const isInvalidToken = !(payload && parseInt(`${new Date().getTime() / 1000}`, 10) < payload.exp);

  const [password, setPassword] = useState<string>("");
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const isValidPassword = password && password.length >= 8 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(password);

  const [passwordUpdateFn, passwordUpdateResult] = useMutation<UpdatePasswordTokenType, UpdatePasswordTokenVariables>(
    updatePasswordTokenMutation
  );

  const onResetButtonClick = () => {
    setShouldShowError(true);
    passwordUpdateFn({
      variables: {
        token,
        password,
      },
    }).catch(console.error);
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (isInvalidToken) {
    return (
      <div className={classes.root}>
        <Grid container={true} className={classes.content}>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <Typography className={classes.resetTitle}>{t("password_reset.invalid.title")}</Typography>
            </div>
          </Grid>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <Typography className={classes.resetSubTitle}>{t("password_reset.invalid.instructions")}</Typography>
            </div>
          </Grid>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <HighlightOffIcon fontSize={"large"} color={"error"} />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (
    shouldShowError &&
    !passwordUpdateResult.loading &&
    passwordUpdateResult.data?.updatePasswordToken.error === true
  ) {
    setShouldShowError(false);
    enqueueSnackbar(t("password_reset.server_error"), {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  }

  if (
    isValidPassword &&
    !passwordUpdateResult.loading &&
    passwordUpdateResult.data?.updatePasswordToken.error === false
  ) {
    return (
      <div className={classes.root}>
        <Grid container={true} className={classes.content}>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <Logo className={classes.logo} />
              <Typography className={classes.resetTitle}>{t("password_reset.complete.ok_title")}</Typography>
            </div>
          </Grid>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <Typography className={classes.resetSubTitle}>{t("password_reset.complete.ok_instructions")}</Typography>
            </div>
          </Grid>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <CheckCircleOutlineIcon fontSize={"large"} color={"primary"} />
            </div>
          </Grid>
          <MobileView>
            <Button
              disabled={!isValidPassword}
              variant="contained"
              onClick={(event) => {
                event.preventDefault();
                window.location.href =
                  (isIOS ? config.REACT_APP_DAHEIM_IOS_URL : config.REACT_APP_DAHEIM_ANDROID_URL) || "";
              }}
              className={classes.resetbutton}
              color={"primary"}
            >
              DaheimApp öffnen
            </Button>
          </MobileView>
          <BrowserView>
            <a href="/login" className={classes.daheimButton}>
              Hier geht's weiter ...
            </a>
          </BrowserView>
        </Grid>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        onResetButtonClick();
      }}
      className={classes.root}
    >
      <Grid container={true} className={classes.content}>
        <Grid container={true} className={classes.component}>
          <div className={classes.component}>
            <Logo className={classes.logo} />
            <Typography className={classes.resetTitle}>{t("password_reset.complete.title")}</Typography>
          </div>
        </Grid>
        <Grid container={true} className={classes.component}>
          <div className={classes.component}>
            <Typography className={classes.resetSubTitle}>{t("password_reset.complete.instructions")}</Typography>
          </div>
        </Grid>
        <Grid container={true} className={classes.textInputComponent}>
          <TextField
            className={classes.textField}
            placeholder={t("login.passwordPlaceholder")}
            id="password"
            type="password"
            fullWidth
            error={shouldShowError && !password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid container={true} className={classes.component}>
          {password && password.length > 0 && !isValidPassword && (
            <Typography className={classes.loginErrorText}>{t("password_reset.complete.password_criteria")}</Typography>
          )}
          <Button
            disabled={!isValidPassword}
            variant="contained"
            onClick={(event) => {
              event.preventDefault();
              onResetButtonClick();
            }}
            type={"submit"}
            className={classes.resetbutton}
            color={"primary"}
          >
            {passwordUpdateResult?.loading ? (
              <ClipLoader color={"white"} size={17} />
            ) : (
              t("password_reset.complete.button")
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
