import { gql } from "apollo-boost";
import * as types from "./types/WasteOfTown";
import { WasteOfTown_basicWaste, WasteOfTown_me_town_wasteTypes } from "./types/WasteOfTown";

export default gql`
  query WasteOfTown {
    me {
      town {
        townId
        name
        wasteTypes {
          name
          defaultAllowance
          baseWaste {
            baseWasteId
            originalName
            fractionGroup {
              id
              group
              maxWeight
              unit
            }
          }
        }
      }
    }
    basicWaste {
      baseWasteId
      originalName
      fractionGroup {
        id
        group
        maxWeight
        unit
      }
    }
  }
`;
export type WasteOfTownData = types.WasteOfTown;
export type WasteType = WasteOfTown_me_town_wasteTypes;
export type BasicWaste = WasteOfTown_basicWaste;
