import * as React from "react"
import {IBaseProps} from "../../../utils/BaseProps"
import {withTranslation} from "react-i18next";
import {createStyles, withStyles, Theme, Typography} from "@material-ui/core";

interface IHeaderProps extends IBaseProps {
  title: string
}

interface IHeaderState {

}

const styles = (theme: Theme) => createStyles({
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '0.75',
    letterSpacing: 'normal',
    color: theme.palette.primary.main,
  }
})

class Header extends React.Component<IHeaderProps, IHeaderState> {

  constructor(props: IHeaderProps) {
    super(props);

    this.state = {}
  }

  public render() {
    const {classes} = this.props

    return <Typography className={classes.header}>{this.props.title.toUpperCase()}</Typography>
  }
}

export default withStyles(styles)(withTranslation()(Header))
