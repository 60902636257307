import * as React from "react"
import {IBaseProps} from "../utils/BaseProps"
import {withTranslation} from "react-i18next";
import {AppBar, createStyles, Grid, Theme, Toolbar, Typography, withStyles,} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    background: theme.palette.background.default,
    maxHeight: 70
  },
  toolBar: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 16,
    paddingBottom: 0,
    background: theme.palette.background.default

  },
  titleText: {
    display: 'block',
    alignItems: 'center',
    justifyItems: 'center',
    fontSize: 14,
    marginLeft: 16,
  },
  fabWithPrimary: {
    color: 'primary',
  },

  whiteIcon: {
    color: 'white'
  },

  seperatorText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginLeft: 16,
  },

  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 36,
    height: 36,
    marginLeft: 16,
  },
  iconButton: {
    padding: 0,
    marginLeft: 16,
    minWidth: 0
  }
})

interface IToolbarState {

}

interface IToolbarProps extends IBaseProps {

}

class UpperBar extends React.Component<IToolbarProps, IToolbarState> {

  constructor(props: IToolbarProps) {
    super(props);

    this.state = {

    }

  }


  public render() {

    const {classes} = this.props

    return <div className={classes.root}>

      <AppBar position="static" color="inherit">
        <Toolbar variant="regular" className={classes.toolBar}>
          <Grid container={true} justify={"flex-end"} alignItems={"center"}>
            <Typography variant="h6" noWrap className={classes.titleText}> </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  }
}

export default withStyles(styles)(withTranslation()(UpperBar))
