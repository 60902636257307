import { gql } from "apollo-boost";
import { MutationFunction } from "@apollo/react-common";
import { resetAllowances, resetAllowancesVariables } from "./types/resetAllowances";

export const resetAllowancesMutation = gql`
  mutation resetAllowances($townId: ID!) {
    resetHouseholdDisposalAllowances(townId: $townId) {
      error
      message
    }
  }
`;

export type ResetAllowancesMutationFn = MutationFunction<resetAllowances, resetAllowancesVariables>;
