import { gql } from "apollo-boost";
import { households, households_households } from "./types/households";
//import { Query } from "@apollo/react-components";

export default gql`
  query households($townId: String!) {
    households(isDeleted: false, inTown: $townId) {
      householdId
      street
      streetNumber
      postalCode
      city
      apartmentNumber
      staircase
    }
  }
`;

export type Household = households_households | undefined;
export type Households = households | undefined;
