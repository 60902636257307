import * as React from "react";
import { FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import { BasicWaste } from "../../api/graphql/queries/basic-waste";
import { addWasteToTownMuation } from "../../api/graphql/mutations/add-waste";
import { useSnackbar } from "notistack";
import { WasteType } from "../../api/graphql/queries/waste-of-town";
import { editWasteOfTownMutation } from "../../api/graphql/mutations/edit-waste";
import { snackbarCloseAction } from "../../utils/snackbar-close-action";
import lodash from "lodash";
import { AddWasteToTown, AddWasteToTownVariables } from "../../api/graphql/mutations/types/AddWasteToTown";
import { EditWasteOfTown, EditWasteOfTownVariables } from "../../api/graphql/mutations/types/EditWasteOfTown";
import { useMutation } from "@apollo/react-hooks";

const useStyles = makeStyles((theme: Theme) => ({
  newNameContainer: {
    marginTop: 8,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontWeight: "bold",
    paddingTop: 16,
  },
}));

interface IAddEditWasteDialogProps {
  onCloseDialog: () => void;
  existingWastes: WasteType[];
  wasteToEdit?: WasteType | null;
  basicWastes: BasicWaste[];
}

export const AddEditWasteDialog: FunctionComponent<IAddEditWasteDialogProps> = (props) => {
  const { wasteToEdit, basicWastes, existingWastes } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [newName, setNewName] = useState<string>(wasteToEdit?.name ?? "");
  const [selectedWaste, setSelectedWaste] = useState<BasicWaste | null | undefined>(null);
  const [shouldShowErrors, setShouldShowErrors] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const [defaultAllowance, setDefaultAllowance] = useState<number | null>(wasteToEdit?.defaultAllowance ?? 0.0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [addMutation] = useMutation<AddWasteToTown, AddWasteToTownVariables>(addWasteToTownMuation);
  const [editMutation] = useMutation<EditWasteOfTown, EditWasteOfTownVariables>(editWasteOfTownMutation);

  const isEditing = !!wasteToEdit;

  const onWasteSelected = (basicWasteUuid: string) => {
    const selectedWaste = basicWastes.find((w) => w.baseWasteId === basicWasteUuid);
    setSelectedWaste(selectedWaste);
    setNewName(selectedWaste?.originalName ?? "");
  };

  const onSuccess = () => {
    setIsLoading(false);
    enqueueSnackbar(t(isEditing ? "waste_management.dialog.edit.success" : "waste_management.dialog.add.success"), {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar }),
    });
    props.onCloseDialog();
  };

  const onError = () => {
    setIsLoading(false);
    setApiError(true);
  };

  const onSend = () => {
    setShouldShowErrors(true);
    setApiError(false);

    if (isEditing) {
      if (wasteToEdit && newName.trim().length > 0 && defaultAllowance && defaultAllowance > 0.0) {
        setIsLoading(true);
        editMutation({
          variables: {
            baseWasteId: wasteToEdit.baseWaste.baseWasteId,
            name: newName,
            defaultAllowance: defaultAllowance || 0,
          },
        })
          .then((result) => {
            if (result && result.data && !result.data.editWasteOfTown.error) {
              onSuccess();
            } else {
              onError();
            }
          })
          .catch((e) => {
            console.error(e);
            onError();
          });
      }
    } else {
      if (selectedWaste && newName.trim().length > 0 && defaultAllowance && defaultAllowance > 0.0) {
        setIsLoading(true);
        addMutation({
          variables: {
            baseWasteId: selectedWaste.baseWasteId,
            name: newName,
            defaultAllowance: defaultAllowance || 0,
            fractionGroupId: selectedWaste.fractionGroup.id,
          },
        })
          .then((result) => {
            if (result && result.data && !result.data.addWasteToTown.error) {
              onSuccess();
            } else {
              onError();
            }
          })
          .catch((e) => {
            console.error(e);
            onError();
          });
      }
    }
  };

  const shouldShowAllowanceError = !lodash.isFinite(Number(defaultAllowance)) || Number(defaultAllowance) <= 0;

  const filteredBasicWastes = basicWastes.filter(
    (waste) => existingWastes.find((w) => w.baseWaste.baseWasteId === waste.baseWasteId) === undefined
  );

  if (filteredBasicWastes.length === 0 && !isEditing) {
    enqueueSnackbar(t("waste_management.dialog.no_waste_types"), {
      variant: "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar }),
    });
    props.onCloseDialog();
    return null;
  }

  return (
    <Dialog open={true} fullWidth={true} maxWidth={"sm"}>
      <form>
        <DialogTitle>
          {isEditing ? t("waste_management.dialog.edit.title") : t("waste_management.dialog.add.title")}
        </DialogTitle>
        <DialogContent>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <FormControl
                fullWidth={true}
                error={shouldShowErrors && !selectedWaste && !isEditing}
                disabled={isEditing}
              >
                <InputLabel>{t("waste_management.base_waste")}</InputLabel>
                <Select
                  value={
                    (isEditing &&
                      wasteToEdit &&
                      basicWastes.find((bw) => bw.baseWasteId === wasteToEdit.baseWaste.baseWasteId)) ||
                    selectedWaste ||
                    ""
                  }
                  fullWidth={true}
                  renderValue={(value?: BasicWaste | string | number | boolean | object | unknown) =>
                    value && (value as BasicWaste).baseWasteId ? `${(value as BasicWaste).originalName}` : undefined
                  }
                  displayEmpty={false}
                  onChange={(e) => onWasteSelected(e.target.value as string)}
                  name="name"
                >
                  {filteredBasicWastes.map((waste) => (
                    <MenuItem key={waste.baseWasteId} value={waste.baseWasteId}>
                      {waste.originalName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {shouldShowErrors && !selectedWaste ? t("waste_management.dialog.errors.waste") : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item={true} xs={12}>
              <FormControl disabled={false} fullWidth={true} error={shouldShowErrors && shouldShowAllowanceError}>
                <InputLabel>{t("waste_management.allowance")}</InputLabel>
                <Input
                  autoFocus={true}
                  endAdornment={<InputAdornment position="end">{selectedWaste?.fractionGroup.unit}</InputAdornment>}
                  inputProps={{ type: "number" }}
                  value={defaultAllowance}
                  onChange={(e) => {
                    const newDefaultAllowance: number | null = !lodash.isEmpty(e.target.value)
                      ? Number(e.target.value)
                      : null;
                    setDefaultAllowance(newDefaultAllowance);
                  }}
                />
                {shouldShowErrors && shouldShowAllowanceError ? (
                  <FormHelperText>{t("waste_management.error_weight")}</FormHelperText>
                ) : undefined}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item={true} xs={12} className={classes.newNameContainer}>
            <TextField
              label={t("waste_management.dialog.new_name")}
              helperText={
                shouldShowErrors && newName.trim().length === 0
                  ? t("waste_management.dialog.errors.name")
                  : t("waste_management.dialog.new_name_helper")
              }
              fullWidth={true}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              error={shouldShowErrors && newName.trim().length === 0}
            />
          </Grid>
          {apiError ? (
            <Typography className={classes.errorMessage}>{t("waste_management.dialog.errors.api")}</Typography>
          ) : undefined}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={props.onCloseDialog}>
            {t("cancel")}
          </Button>
          <Button
            color="primary"
            variant={"contained"}
            type={"submit"}
            disabled={isLoading}
            onClick={(event) => {
              event.preventDefault();
              onSend();
            }}
          >
            {isLoading ? <ClipLoader color={"white"} size={17} /> : t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
