import React from "react";
import store from "store";
import jwt from "jsonwebtoken";
// import { graphQLClient } from "api/graphqlAPI.js"
import lodash from "lodash";
import { oc } from "ts-optchain";
import { UserRole } from "../api/graphql/graphql-global-types";
import { Login_login_user as User } from "../api/graphql/mutations/types/Login";

interface IAuthState {
  token: string | null;
  user?: User | null;
}

interface IAuthProviderProps {
  children?: React.ReactNode;
}

interface IAuthContext {
  isAuthenticated: boolean;
  isAdmin: boolean;
  userInfo: User | null;
  hasRoles: (roles: UserRole[]) => boolean;
  authin: (token: string | null, user: User | null) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<Partial<IAuthContext>>({});

class AuthProvider extends React.Component {
  state: IAuthState = { token: null, user: null };

  constructor(props: IAuthProviderProps) {
    super(props);

    let payload: {} | null = null;
    const token = store.get("access_token");
    if (!lodash.isNil(token)) {
      payload = jwt.decode(token);
    }

    this.state = {
      token: token,
      user: payload ? lodash.get(payload, "user", null) : null
    };
  }

  handleTokenChange = (token: string | null, user: User | null) => {
    // console.log('token: ', token)
    store.set("access_token", token);

    this.setState({
      token: token,
      user: user
    });
  };

  isAuthenticated = () => {
    return !!this.state.token && this.state.token !== "" && this.state.token !== "logout";
  };

  hasRoles = (roles: UserRole[]) => {
    const role = oc(this.state).user.role();

    if (!lodash.isNil(role)) {
      return roles.includes(role);
    }
    return false;
  };

  isAdmin = () => {
    return this.hasRoles([UserRole.ADMIN]);
  };

  getUserInfo = () => {
    return this.state.user;
  };

  logout = () => {
    store.clearAll();
    // graphQLClient.cache.reset() // TODO: (RF) ??

    this.setState({
      token: null,
      user: null
    });
  };

  authin = (token: string | null, user: User | null) => {
    this.handleTokenChange(token, user);
  };

  render() {
    let userInfo: User | null = null;
    const userInfoTmp = this.getUserInfo();
    if (!lodash.isNil(userInfoTmp)) {
      userInfo = userInfoTmp;
    }

    const contextValue: IAuthContext = {
      isAuthenticated: this.isAuthenticated(),
      hasRoles: this.hasRoles,
      userInfo: userInfo,
      isAdmin: this.isAdmin(),
      authin: this.authin,
      logout: this.logout
    };

    return <AuthContext.Provider value={contextValue}>{this.props.children}</AuthContext.Provider>;
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
