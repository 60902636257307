import { default as React, FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SingleUser } from "./single-user";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import {
  MatchingCitizen,
  MatchingCitizen_citizenSearch,
  MatchingCitizenVariables
} from "../../../api/graphql/queries/types/MatchingCitizen";
import { MATCHING_CITIZENS_QUERY } from "../../../api/graphql/queries/matching-citizen";
import { SingleUser as SingleUserResult, SingleUserVariables } from "../../../api/graphql/queries/types/SingleUser";
import { SINGLE_USER_QUERY } from "../../../api/graphql/queries/single-user";
import { CitizenStepper } from "../citizen-stepper";
import { MANUEL_VERIFY_USER_MUTATION } from "../../../api/graphql/mutations/manual-verify-user";
import { snackbarCloseAction } from "../../../utils/snackbar-close-action";
import { manualVerifyUser, manualVerifyUserVariables } from "../../../api/graphql/mutations/types/manualVerifyUser";
import { useSnackbar } from "notistack";
import { SwitchState, SwitchStateKeys } from "./switch-state";
import * as _ from "lodash";

interface IVerifyUserManualProps {
  onModification: () => void;
  open: boolean;
  userId: string;
  onCloseDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  singleUserContainer: {
    height: "100%",
    padding: 8
  },
  error: {
    color: theme.palette.error.main
  },
  success: {
    color: "#4caf50"
  },
  actions: {
    paddingRight: 20,
    paddingBottom: 24
  },
  importErrorMessage: {
    color: theme.palette.error.main,
    fontWeight: "bold",
    paddingTop: 16
  },
  dialogGrid: {
    flexGrow: 1
  },
  citizenItem: {
    marginTop: 3,
    marginBottom: 3
  },
  leftItem: {
    paddingRight: 10
  },
  rightItem: {
    paddingLeft: 10
  },
  card: {
    height: "95%"
  }
}));

export const VerifyUserManual: FunctionComponent<IVerifyUserManualProps> = props => {
  const { open, userId, onModification, onCloseDialog } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [switchState, setSwitchState] = useState<SwitchState>(new SwitchState());
  const [selectedCitizenHousehold, setSelectedCitizenHousehold] = useState<MatchingCitizen_citizenSearch | null>(null);

  const [loadMatchingCitizens, { data: matchingCitizensResult, loading: isLoadingMatchingCitizens }] = useLazyQuery<
    MatchingCitizen,
    MatchingCitizenVariables
  >(MATCHING_CITIZENS_QUERY);
  const { data: singleUserResult, loading } = useQuery<SingleUserResult, SingleUserVariables>(SINGLE_USER_QUERY, {
    variables: {
      userId
    }
  });
  const [verifyUserMutation, { loading: isVerifyingUser }] = useMutation<manualVerifyUser, manualVerifyUserVariables>(
    MANUEL_VERIFY_USER_MUTATION
  );

  useEffect(() => {
    const reloadCitizens = () => {
      if (isLoadingMatchingCitizens || !switchState || !singleUserResult) {
        return;
      }
      const variables = Object.values(SwitchStateKeys).reduce((obj: any, key) => {
        if (_.get(switchState, key, false)) {
          obj[key] = _.get(singleUserResult?.user.identificationData, key, null);
        }
        return obj;
      }, {});

      loadMatchingCitizens({
        variables: {
          ...variables
        }
      });
    };
    reloadCitizens();
  }, [singleUserResult, switchState]);

  const onSwitchChanged = (switchStateKey: SwitchStateKeys | string, checked: boolean) => {
    setSwitchState({
      ...switchState,
      [switchStateKey]: checked
    });
  };

  if (loading) {
    return <div />; //todo
  }

  const onCitizenChanged = (citizen: MatchingCitizen_citizenSearch | null) => {
    setSelectedCitizenHousehold(citizen);
  };

  const onSend = () => {
    if (userId && selectedCitizenHousehold?.citizen.citizenId) {
      verifyUserMutation({
        variables: { userId: userId, citizenId: selectedCitizenHousehold?.citizen.citizenId }
      }).then(r => {
        // console.log('r: ', r)
        onModification();
        enqueueSnackbar(t("user_management.manual_verification.verify_success"), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          action: key => snackbarCloseAction({ key, closeSnackbar })
        });
        onCloseDialog();
      });
    }
  };

  const onCancel = () => {
    onCloseDialog();
  };

  return (
    <Dialog open={open} maxWidth={"md"} fullWidth={true}>
      <div>
        <DialogTitle>{t("user_management.manual_verification.title")}</DialogTitle>
        <DialogContent>
          <Grid container className={classes.dialogGrid} direction="row" justify="center">
            <Grid item xs={6} className={classes.leftItem}>
              <Typography variant="h6" gutterBottom>
                {t("user_management.manual_verification.user_data_title")}
              </Typography>
              <Paper className={classes.card}>
                <SingleUser
                  identification={singleUserResult?.user?.identificationData}
                  switchState={switchState}
                  onSwitchChange={onSwitchChanged}
                />
              </Paper>
            </Grid>
            <Grid item xs={6} className={classes.rightItem}>
              <Typography variant="h6" gutterBottom>
                {t("user_management.manual_verification.citizen_data_title")}
              </Typography>
              <Paper className={classes.card}>
                <CitizenStepper
                  citizens={matchingCitizensResult?.citizenSearch ?? []}
                  contactEmail={singleUserResult?.user.email ?? "No E-Mail"}
                  onCitizenChanged={onCitizenChanged}
                  isLoading={isLoadingMatchingCitizens}
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onCancel} color="primary">
            {t("cancel")}
          </Button>

          <Button
            onClick={onSend}
            variant={"contained"}
            color="primary"
            disabled={isVerifyingUser || !selectedCitizenHousehold}
          >
            {t("user_management.manual_verification.verify_button")}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
