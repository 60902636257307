import { gql } from "apollo-boost";
import { openWeighingSubscription } from "./types/openWeighingSubscription";

export const OPEN_WEIGHINGS_SUBSCRIPTION = gql`
  subscription openWeighingSubscription {
    openWeighingsSubscription {
      id
      state
      additional_weighing_info
      created_at
      sap_weighing_nr
      weighings {
        id
        weight
        weightToPay
        paidAt
        createdAt
        disposalAllowance {
          totalAmount
          remainingAmount
        }
        waste {
          name
          baseWasteId
          defaultAllowance
          baseWaste {
            originalName
            baseWasteId
            fractionGroup {
              unit
            }
          }
        }
      }
      citizenHousehold {
        typeOfResidence
        citizen {
          citizenId
          firstName
          lastName
        }
        household {
          householdId
          street
          streetNumber
          town {
            id
            name
          }
          disposalAllowance {
            totalAmount
            individual
            remainingAmount
            waste {
              name
              baseWasteId
              defaultAllowance
              baseWaste {
                originalName
                baseWasteId
                fractionGroup {
                  unit
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type OpenWeighingsSubscriptionsResult = openWeighingSubscription;
