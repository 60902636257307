import React, { FunctionComponent, useContext, Fragment } from "react";
import { TownContext } from "../core/town-context";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Town } from "../../api/graphql/queries/towns";
import { useTranslation } from "react-i18next";

export const TownSelection: FunctionComponent = props => {
  const { assignedTowns, town, setTownId } = useContext(TownContext);
  const { t } = useTranslation();

  if (assignedTowns.length <= 1) {
    return <Fragment />;
  }

  const onTownSelected = (townId: string) => {
    const selectedTown = assignedTowns.find(t => t.townId === townId);
    setTownId(selectedTown ? selectedTown.townId : null);
  };

  return (
    <FormControl fullWidth={true}>
      <InputLabel>{t("weighing_management.start_dialog.town")}</InputLabel>
      <Select
        value={town}
        renderValue={(value?: Town | string | number | boolean | object | unknown) =>
          value && (value as Town).name ? `${(value as Town).name}` : undefined
        }
        displayEmpty={false}
        onChange={e => onTownSelected(e.target.value as string)}
      >
        {assignedTowns
          .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
          .map(town => (
            <MenuItem key={town.name} value={town.townId}>
              {town.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
