/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ImportStatus {
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
}

export enum QrCodeType {
  PERMAMENT = "PERMAMENT",
  TEMPORARY = "TEMPORARY",
}

export enum UserRole {
  ADMIN = "ADMIN",
  APP_USER = "APP_USER",
  TOWN_ADMIN = "TOWN_ADMIN",
  WEIGHING_MASTER = "WEIGHING_MASTER",
}

export enum VerifyEmailStatus {
  CODE_ALREADY_USED = "CODE_ALREADY_USED",
  CODE_NOT_FOUND = "CODE_NOT_FOUND",
  SUCCESS = "SUCCESS",
}

export enum WeighingProcessState {
  ACTIVE = "ACTIVE",
  DONE = "DONE",
  DRAFT = "DRAFT",
}

export interface CitizensInput {
  firstName: string;
  lastName: string;
  dateOfBirth?: string | null;
  street: string;
  streetNumber: string;
  staircase?: string | null;
  apartmentNumber?: string | null;
  postalCode: string;
  city: string;
  gkz?: string | null;
  typeOfResidence?: string | null;
}

export interface DisposalAllowanceInput {
  baseWasteId: string;
  weight: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
