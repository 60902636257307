import { gql } from "apollo-boost";
import { SearchCitizens, SearchCitizens_searchCitizens } from "./types/SearchCitizens";

export const SEARCH_CITIZEN_QUERY = gql`
  query SearchCitizens($townId: ID!, $searchTerm: String!, $limit: Int) {
    searchCitizens(townId: $townId, searchTerm: $searchTerm, limit: $limit) {
      citizenId
      householdId
      firstName
      lastName
      street
      streetNumber
      apartmentNumber
      staircase
      userQrCode
    }
  }
`;

export type SearchCitizen = SearchCitizens_searchCitizens;
export type SearchCitizensResult = SearchCitizens;
