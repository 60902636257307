import { gql } from "apollo-boost";
import {
  UnverifiedUsers as UnverifiedUsersType,
  UnverifiedUsers_users,
  UnverifiedUsers_users_identificationData
} from "./types/UnverifiedUsers";
import { VerifiedUsers_users_matchingCitizen } from "./types/VerifiedUsers";

export default gql`
  query UnverifiedUsers {
    users(isIdentified: true, isVerified: false) {
      userId
      email
      hasDisposalAllowance
      identificationData {
        firstName
        lastName
        street
        streetNumber
        postalCode
        city
        dateOfBirth
        staircase
        apartmentNumber
      }
    }
  }
`;
export type UnverifiedUsers = UnverifiedUsersType | undefined;
export type IdentifiedUsersIdentificationData = UnverifiedUsers_users_identificationData | undefined;
export type UnverifiedUser = UnverifiedUsers_users;

export interface VerifiedOrUnverifiedUser {
  userId: string;
  email: string;
  hasDisposalAllowance: boolean;
  identificationData?: UnverifiedUsers_users_identificationData | null;
  matchingCitizen?: VerifiedUsers_users_matchingCitizen | null;
}
