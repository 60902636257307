import * as React from "react";
import { IBaseProps } from "../utils/BaseProps";
import { withTranslation } from "react-i18next";
import { Button, createStyles, Grid, Theme, withStyles } from "@material-ui/core";
import Header from "../components/core/header/header";
import ImportCitizens from "../components/user-management/import-citizens";
import UpdateUserDisposalAllowance from "../components/update-user-disposal-allowance";
import loadUnverifiedUsersQuery, {
  UnverifiedUser,
  UnverifiedUsers,
  VerifiedOrUnverifiedUser,
} from "../api/graphql/queries/unverified-users";
import loadVerifiedUsersQuery, { VerifiedUser, VerifiedUsers } from "../api/graphql/queries/verified-users";
import Subheader from "../components/core/header/subheader";
import i18next from "i18next";
import { LoadingPaper } from "../components/core/loading-paper";
import { VerifyUserManual } from "../components/user-management/verify-user/verify-user-manual";
import { Query } from "@apollo/react-components";
import { UserData, UserTable } from "../components/user-management/users-table";
import { UnverifiedUsers_users_identificationData } from "../api/graphql/queries/types/UnverifiedUsers";
import { VerifiedUsers_users_matchingCitizen } from "../api/graphql/queries/types/VerifiedUsers";
import { FunctionComponent } from "react";
import * as _ from "lodash";
import moment from "moment";
//For later use
//import SearchBar from '../components/core/search-bar';

interface IUserManagementProps extends IBaseProps {}

interface IUserManagementState {
  manualUserId?: string | null;
}

interface IUserTableProps extends IUserManagementProps {
  t: i18next.TFunction;
  classes: any;
  usersData: VerifiedOrUnverifiedUser[];
  verified: boolean;
  onSearch?: Function;
  onButtonClick: (userId: string) => void;
  sorted: boolean;
}

const styles = (theme: Theme) => createStyles({});

const UsersTableSection: FunctionComponent<IUserTableProps> = (props) => {
  const { t, usersData, verified, onSearch, onButtonClick, sorted } = props;
  //<SearchBar label={t('user_management.verified_users_search_label')} onSearch={onSearch} />

  let data: UserData[] = usersData.map((user) => {
    const personalData: UnverifiedUsers_users_identificationData | VerifiedUsers_users_matchingCitizen | null =
      (user as VerifiedUser)?.matchingCitizen || (user as UnverifiedUser)?.identificationData;

    const addressData =
      (personalData as VerifiedUsers_users_matchingCitizen)?.household ||
      (personalData as UnverifiedUsers_users_identificationData);

    return {
      id: user.userId,
      lastname: personalData?.lastName || "",
      firstname: personalData?.firstName || "",
      street: `${addressData.street ?? ""} ${[
        addressData?.streetNumber,
        addressData?.staircase,
        addressData?.apartmentNumber,
      ]
        .filter((s) => s)
        .join("/")}`,
      city: `${addressData.postalCode} ${addressData.city}`,
      dateOfBirth: personalData?.dateOfBirth || "",
      email: user.email,
      hasDisposalAllowance: user.hasDisposalAllowance,
      streetNumber: addressData.streetNumber,
      staircase: addressData.staircase,
      apartmentNumber: addressData.apartmentNumber,
      postalCode: addressData.postalCode,
      streetRaw: addressData.street,
      cityRaw: addressData.city,
      birthdayRaw: personalData?.dateOfBirth ? moment(personalData?.dateOfBirth, "DD.MM.YYYY") : null,
    };
  });

  if (sorted) {
    data = _.sortBy(data, [
      "lastname",
      "firstname",
      "streetRaw",
      "streetNumber",
      "staircase",
      "apartmentNumber",
      "postalCode",
      "birthdayRaw",
    ]);
  }

  return (
    <div>
      {verified && onSearch ? (
        <div>
          <Subheader title={t("user_management.verified_users_header")} />
        </div>
      ) : (
        <Subheader title={t("user_management.unverified_users_header")} />
      )}
      <UserTable data={data} isVerified={verified} onButtonClicked={onButtonClick} />
    </div>
  );
};

class UserManagement extends React.Component<IUserManagementProps, IUserManagementState> {
  //@ts-ignore
  private descriptionDialogRef: ImportCitizens;
  //@ts-ignore
  private refVerifyUserManual: VerifyUserManual;
  //@ts-ignore
  private refUpdateUserDisposalAllowance: UpdateUserDisposalAllowance;

  constructor(props: IUserManagementProps) {
    super(props);

    this.state = {};
  }

  private onImportCitizens = () => {
    this.descriptionDialogRef.onShowDialog();
  };

  private onVerifyUserManual = (userId: string) => {
    this.refVerifyUserManual.onShowDialog(userId);
  };

  private onUpdateUserDisposalAllowance = (userId: string) => {
    this.refUpdateUserDisposalAllowance.onShowDialog(userId);
  };

  private onVerifiedUserSearch = () => {};

  private onButtonClick = (userId: string, isUnverified: boolean) => {
    if (isUnverified) {
      this.setState({ manualUserId: userId });
    } else {
      this.onUpdateUserDisposalAllowance(userId);
    }
  };

  private onCloseVerifyingDialog() {
    this.setState({ manualUserId: null });
  }

  public render() {
    const { t } = this.props;

    return (
      <div>
        <Grid container justify="space-between" direction="row">
          <Grid item>
            <Header title={t("navigation.user_management")} />
          </Grid>
          <Grid item>
            <Button variant={"contained"} color={"primary"} onClick={() => this.onImportCitizens()}>
              {t("user_management.import_citizens.title")}
            </Button>
          </Grid>
        </Grid>

        <Query<VerifiedUsers> query={loadVerifiedUsersQuery}>
          {(verifiedResult) => {
            return (
              <div>
                <Query<UnverifiedUsers> query={loadUnverifiedUsersQuery}>
                  {({ loading, error, data, refetch }) =>
                    loading ? (
                      <LoadingPaper label={t("user_management.loading")} />
                    ) : (
                      <div>
                        <ImportCitizens
                          getRef={(ref) => (this.descriptionDialogRef = ref)}
                          onModification={() => {
                            refetch();
                            verifiedResult.refetch();
                          }}
                        />

                        {/*<VerifyUserManual getRef={(ref) => this.refVerifyUserManual = ref} onModification={() => {refetch(); verifiedResult.refetch()}}/>*/}
                        {this.state.manualUserId && (
                          <VerifyUserManual
                            onModification={() => {
                              refetch();
                              verifiedResult.refetch();
                            }}
                            onCloseDialog={() => this.onCloseVerifyingDialog()}
                            open={true}
                            userId={this.state.manualUserId}
                          />
                        )}
                        <UsersTableSection
                          usersData={data?.users || []}
                          {...this.props}
                          verified={false}
                          onButtonClick={(userId: string) => this.onButtonClick(userId, true)}
                          sorted={false}
                        />
                      </div>
                    )
                  }
                </Query>

                {verifiedResult.loading ? (
                  <LoadingPaper label={t("user_management.loading")} />
                ) : (
                  <div>
                    <UpdateUserDisposalAllowance
                      getRef={(ref) => (this.refUpdateUserDisposalAllowance = ref)}
                      onModification={() => verifiedResult.refetch()}
                    />
                    <UsersTableSection
                      usersData={verifiedResult.data?.users || []}
                      onSearch={this.onVerifiedUserSearch}
                      {...this.props}
                      verified={true}
                      onButtonClick={(userId: string) => this.onButtonClick(userId, false)}
                      sorted={true}
                    />
                  </div>
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(UserManagement));
