import { gql } from "apollo-boost";

export const NUMBER_OF_OPEN_WEIGHINGS = gql`
  query NumberOfOpenWeighings {
    numberOfOpenWeighings {
      currentOpenWeighings
      shouldShowWarning
    }
  }
`;
