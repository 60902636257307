import * as React from "react";
import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import Header from "../components/core/header/header";
import HouseholdList from "../components/household/household-list";
import HouseholdDetail from "../components/household/household-detail";
import { TownContext } from "../components/core/town-context";
import { TownSelection } from "../components/partial/town-selection";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    marginTop: 16,
    marginBottom: 30
  },
  list: {
    overflowY: "scroll"
  }
}));

interface IHouseholdOverviewProps {}

export const HouseholdOverview: FunctionComponent<IHouseholdOverviewProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedHouseholdId, setSelectedHouseholdId] = useState<string | null>(null);

  const { town } = useContext(TownContext);

  return (
    <React.Fragment>
      <Grid container={true} justify={"space-between"} alignItems={"center"}>
        <Grid item>
          <Header title={t("navigation.household")} />
        </Grid>
        <Grid item={true} xs={3}>
          <TownSelection />
        </Grid>
        <Grid item={true}></Grid>
      </Grid>
      <Grid container justify="space-between" direction="row" style={{ marginTop: 26 }}>
        <Grid item xs={4} className={classes.list}>
          <HouseholdList
            onCardClick={setSelectedHouseholdId}
            selectedHouseholdId={selectedHouseholdId}
            townId={town?.townId ?? ""}
          />
        </Grid>
        <Grid item xs={8}>
          {selectedHouseholdId && <HouseholdDetail selectedHouseholdId={selectedHouseholdId} />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
