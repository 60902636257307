import { FunctionComponent, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import Button from "@material-ui/core/Button";
import { ClipLoader } from "react-spinners";
import * as React from "react";
import * as _ from "lodash";
import { useMutation } from "@apollo/react-hooks";
import { GENERATE_QR_CODES_MUTATION, GenerateQrCodesResult } from "../../api/graphql/mutations/generate-qr-codes";
import { GenerateQrCodesVariables } from "../../api/graphql/mutations/types/GenerateQrCodes";
import { snackbarCloseAction } from "../../utils/snackbar-close-action";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";
import * as blobUtil from "blob-util";

interface IGenerateQrCodeDialogProps extends DialogProps {
  onCloseDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({}));

export const GenerateQrCodeDialog: FunctionComponent<IGenerateQrCodeDialogProps> = (props) => {
  const { onCloseDialog, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [numberOfCodes, setNumberOfCodes] = useState<number>(8);

  const onError = () => {
    enqueueSnackbar(t("weighing_management.generate_qr_code.error"), {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
    });
  };

  const [generateQrCodes, { loading }] = useMutation<GenerateQrCodesResult, GenerateQrCodesVariables>(
    GENERATE_QR_CODES_MUTATION,
    {
      variables: {
        numberOfCodes,
      },
      onCompleted: (data) => {
        if (data.generateQrCodes) {
          fileDownload(blobUtil.base64StringToBlob(data.generateQrCodes), "QR-Codes.pdf");
          onCloseDialog();
        } else {
          onError();
        }
      },
      onError,
    }
  );

  return (
    <Dialog {...rest}>
      <DialogTitle>{t("weighing_management.generate_qr_code.heading")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t("weighing_management.generate_qr_code.text")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("weighing_management.generate_qr_code.number")}
              inputProps={{ min: 0 }}
              type={"number"}
              value={numberOfCodes}
              onChange={(e) => setNumberOfCodes(parseInt(e.target.value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onCloseDialog()}>
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          variant={"contained"}
          onClick={() => generateQrCodes()}
          disabled={numberOfCodes < 1 || _.isNaN(numberOfCodes)}
        >
          {loading ? <ClipLoader color={"white"} size={17} /> : t("weighing_management.generate_qr_code.generate")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
