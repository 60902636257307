import { FunctionComponent, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReactTable, { CellInfo, Column } from "react-table";
import * as React from "react";
import { formatAllowance } from "../../utils/numeral";
import { renderHeader } from "../../pages/waste-management";
import { WasteType } from "../../api/graphql/queries/waste-of-town";
import EditIcon from "@material-ui/icons/Edit";
import { AuthContext } from "../../utils/AuthContext";
import { UserRole } from "../../api/graphql/graphql-global-types";

interface IWasteTableProps {
  onEditWaste: (waste: WasteType) => void;
  wasteTypes: WasteType[];
}

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    marginTop: 26,
  },
  editIcon: {
    cursor: "pointer",
  },
}));

export const WasteTable: FunctionComponent<IWasteTableProps> = (props) => {
  const { onEditWaste, wasteTypes } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { hasRoles } = useContext(AuthContext);

  const columns: Column[] = [
    {
      Header: t("waste_management.waste"),
      accessor: "name",
    },
    {
      Header: t("waste_management.base_waste"),
      accessor: "baseWaste.originalName",
    },
    {
      Header: t("waste_management.fraction_group"),
      accessor: "baseWaste.fractionGroup.group",
      Cell: ({ value }: { value: any }) => <div style={{ textAlign: "right" }}>{t(value)}</div>,
    },
    {
      Header: t("waste_management.allowance"),
      accessor: "",
      Cell: ({ value }: { value: any }) => {
        console.log(value);
        return (
          <div style={{ textAlign: "right" }}>
            {formatAllowance(value.defaultAllowance, value.baseWaste.fractionGroup.unit)}
          </div>
        );
      },
    },
    {
      Cell:
        hasRoles && hasRoles([UserRole.TOWN_ADMIN, UserRole.ADMIN])
          ? ({ original }: { original: WasteType }) => (
              <EditIcon className={classes.editIcon} onClick={() => onEditWaste(original)} />
            )
          : ({ original }: { original: WasteType }) => <div></div>,
      width: 45,
      sortable: false,
    },
  ].map((column) => {
    // apply things to all columns
    return {
      ...column,
      resizable: false,
      Header: (cellInfo: CellInfo, col: any) =>
        renderHeader(cellInfo, col, typeof column.Header === "string" ? column.Header : ""),
    };
  });

  return (
    <div className={`${classes.tableContainer} WasteManagement`}>
      <ReactTable
        columns={columns}
        data={wasteTypes}
        showPagination={false}
        minRows={wasteTypes.length > 0 ? wasteTypes.length : 0}
        defaultSorted={[
          {
            id: "name",
            desc: false,
          },
        ]}
      />
    </div>
  );
};
