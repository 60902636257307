import { gql } from "apollo-boost";
import { MutationFunction } from "@apollo/react-common";
import { UpdatePasswordToken, UpdatePasswordTokenVariables } from "./types/UpdatePasswordToken";

export const updatePasswordTokenMutation = gql`
  mutation UpdatePasswordToken($token: String!, $password: String!) {
    updatePasswordToken(token: $token, password: $password) {
      error
      message
    }
  }
`;

export type UpdatePasswordTokenMuationFn = MutationFunction<UpdatePasswordToken, UpdatePasswordTokenVariables>;
