import { gql } from "apollo-boost";
import {
  household,
  household_household_citizens,
  household_household_citizens_user,
  household_household_disposalAllowance,
} from "./types/household";

export default gql`
  query household($householdId: String!) {
    household(householdId: $householdId) {
      householdId
      street
      streetNumber
      apartmentNumber
      staircase
      postalCode
      city
      citizens {
        citizenId
        firstName
        lastName
        dateOfBirth
        user {
          email
          isVerifiedCitizen
        }
      }
      disposalAllowance {
        waste {
          baseWasteId
          name
          defaultAllowance
          baseWaste {
            fractionGroup {
              unit
            }
          }
        }
        totalAmount
        remainingAmount
      }
    }
  }
`;

export type Household = household | undefined;
export type Citizens = household_household_citizens[] | null;
export type User = household_household_citizens_user | null;
export type DisposalAllowance = household_household_disposalAllowance;
