import { gql } from "apollo-boost";
import {
  VerifiedUsers as VerifiedUsersType,
  VerifiedUsers_users,
  VerifiedUsers_users_matchingCitizen
} from "./types/VerifiedUsers";

export default gql`
  query VerifiedUsers {
    users(isIdentified: true, isVerified: true) {
      userId
      email
      hasDisposalAllowance
      matchingCitizen {
        firstName
        lastName
        dateOfBirth
        household {
          street
          streetNumber
          postalCode
          city
          staircase
          apartmentNumber
        }
      }
    }
  }
`;

export type VerifiedUsers = VerifiedUsersType | undefined;
export type IdentifiedUsersPersonalData = VerifiedUsers_users_matchingCitizen | undefined;
export type VerifiedUser = VerifiedUsers_users;
