import { default as React, FunctionComponent } from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IdentificationData } from "../../../api/graphql/queries/single-user";
import { SingleUserSwitchControl } from "./single-user-switch-control";
import { SwitchState, SwitchStateKeys } from "./switch-state";

interface ISingleUserProps {
  identification?: IdentificationData | null;
  switchState: SwitchState;
  onSwitchChange: (switchState: SwitchStateKeys | string, checked: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  singleUserContainer: {
    height: "100%",
    padding: 8
  },
  infoText: {
    textAlign: "center",
    height: 48
  }
}));

export const SingleUser: FunctionComponent<ISingleUserProps> = props => {
  // const classes = this.props.classes
  const { t } = useTranslation();
  const { identification, switchState, onSwitchChange } = props;
  const classes = useStyles();

  if (identification) {
    return (
      <Grid container>
        <Grid item>
          <Grid container justify={"space-between"} className={classes.singleUserContainer} spacing={2}>
            <SingleUserSwitchControl
              checked={switchState.lastName}
              switchStateKey={SwitchStateKeys.LAST_NAME}
              onSwitchChange={onSwitchChange}
              title={t("user_data.lastname")}
              text={identification?.lastName}
            />

            <SingleUserSwitchControl
              checked={switchState.firstName}
              switchStateKey={SwitchStateKeys.FIRST_NAME}
              onSwitchChange={onSwitchChange}
              title={t("user_data.firstname")}
              text={identification?.firstName}
            />

            <SingleUserSwitchControl
              checked={switchState.street}
              switchStateKey={SwitchStateKeys.STREET}
              onSwitchChange={onSwitchChange}
              title={t("user_data.street")}
              text={identification?.street}
            />

            <SingleUserSwitchControl
              checked={switchState.streetNumber}
              switchStateKey={SwitchStateKeys.STREET_NUMBER}
              onSwitchChange={onSwitchChange}
              title={t("user_data.street_number")}
              text={identification?.streetNumber}
            />

            <SingleUserSwitchControl
              checked={switchState.staircase}
              switchStateKey={SwitchStateKeys.STAIRCASE}
              onSwitchChange={onSwitchChange}
              title={t("user_data.staircase")}
              text={identification?.staircase}
            />

            <SingleUserSwitchControl
              checked={switchState.apartmentNumber}
              switchStateKey={SwitchStateKeys.APARTMENT_NUMBER}
              onSwitchChange={onSwitchChange}
              title={t("user_data.apartment_number")}
              text={identification?.apartmentNumber}
            />

            <SingleUserSwitchControl
              checked={switchState.city}
              switchStateKey={SwitchStateKeys.CITY}
              onSwitchChange={onSwitchChange}
              title={t("user_data.city")}
              text={identification?.city}
            />

            <SingleUserSwitchControl
              checked={switchState.postalCode}
              switchStateKey={SwitchStateKeys.POSTAL_CODE}
              onSwitchChange={onSwitchChange}
              title={t("user_data.postalcode")}
              text={identification?.postalCode}
            />

            <SingleUserSwitchControl
              checked={switchState.dateOfBirth}
              switchStateKey={SwitchStateKeys.DATE_OF_BIRTH}
              onSwitchChange={onSwitchChange}
              title={t("user_data.date_of_birth")}
              text={identification?.dateOfBirth}
            />

            <Grid item xs={12} className={classes.infoText}>
              <Typography>{t("user_management.manual_verification.info_text")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return <div />;
};
