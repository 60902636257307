import gql from "graphql-tag";
import { StartWeighingProcess } from "./types/StartWeighingProcess";

export const START_WEIGHING_PROCESS_MUTATION = gql`
  mutation StartWeighingProcess($citizenId: ID!, $householdId: ID!, $qrCodeId: ID!, $additionalWeighingInfo: String) {
    startWeighingProcess(
      citizenId: $citizenId
      householdId: $householdId
      qrCodeId: $qrCodeId
      additionalWeighingInfo: $additionalWeighingInfo
    ) {
      message
      error
    }
  }
`;

export type StartWeighingProcessResult = StartWeighingProcess;
