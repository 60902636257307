import gql from "graphql-tag";
import { GenerateQrCodes } from "./types/GenerateQrCodes";

export const GENERATE_QR_CODES_MUTATION = gql`
  mutation GenerateQrCodes($numberOfCodes: Int!) {
    generateQrCodes(numberOfCodes: $numberOfCodes)
  }
`;

export type GenerateQrCodesResult = GenerateQrCodes;
