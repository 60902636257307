import { gql } from "apollo-boost";

export const updateAdditionalWeighingInfoMutation = gql`
  mutation UpdateAdditionalWeighingInfo($weighingProcessId: ID!, $additionalWeighingInfo: String) {
    updateAdditionalWeighingInfo(
      weighingProcessId: $weighingProcessId
      additionalWeighingInfo: $additionalWeighingInfo
    ) {
      error
      message
    }
  }
`;
