import * as React from "react";
import { IBaseProps } from "../utils/BaseProps";
import { withTranslation } from "react-i18next";
import {
  createStyles,
  withStyles,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem
} from "@material-ui/core";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { SINGLE_USER_QUERY } from "../api/graphql/queries/single-user";
import QueryState from "./QueryState";
import {
  SingleUser,
  SingleUserVariables
} from "../api/graphql/queries/types/SingleUser";
import lodash from "lodash";
import {
  SetDisposalAllowanceMutationFn,
  setDisposalAllowanceMutation
} from "../api/graphql/mutations/set-disposal-allowance-user";
import { isNotNullOrUndefined } from "../utils/isNotNullOrUndefined";
import { snackbarCloseAction } from "../utils/snackbar-close-action";
import {
  setDisposalAllowance,
  setDisposalAllowanceVariables
} from "../api/graphql/mutations/types/setDisposalAllowance";
import { Mutation, Query } from "@apollo/react-components";

interface IUpdateUserDisposalAllowanceProps extends IBaseProps, WithSnackbarProps {
  getRef: (ref: UpdateUserDisposalAllowance) => void;
  onModification: () => void;
}

interface IUpdateUserDisposalAllowanceState {
  open: boolean;
  userId?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main
    },
    success: {
      color: "#4caf50"
    },
    actions: {
      paddingRight: 20,
      paddingBottom: 24
    },
    importErrorMessage: {
      color: theme.palette.error.main,
      fontWeight: "bold",
      paddingTop: 16
    }
  });

class UpdateUserDisposalAllowance extends React.Component<
  IUpdateUserDisposalAllowanceProps,
  IUpdateUserDisposalAllowanceState
> {
  constructor(props: IUpdateUserDisposalAllowanceProps) {
    super(props);

    this.state = {
      open: false
    };
  }

  componentDidMount(): void {
    this.props.getRef(this);
  }

  public onShowDialog = (userId: string) => {
    this.setState({
      open: true,
      userId: userId
    });
  };

  private reset = () => {
    this.setState({
      open: false
    });
  };

  private onCancel = () => {
    this.reset();
  };

  private onSend = (
    mutation: SetDisposalAllowanceMutationFn,
    userId: string | undefined,
    currentDisposalAllowance: boolean | undefined
  ) => {
    const { t, onModification } = this.props;

    this.reset();
    if (userId && isNotNullOrUndefined(currentDisposalAllowance)) {
      mutation({ variables: { userId: userId, disposalAllowance: !currentDisposalAllowance } }).then(r => {
        // console.log('r: ', r)
        onModification();
        const successMessage = currentDisposalAllowance
          ? t("user_management.disposal_allowance.resign_success")
          : t("user_management.disposal_allowance.allow_success");
        this.props.enqueueSnackbar(successMessage, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          action: key => snackbarCloseAction({ key, closeSnackbar: this.props.closeSnackbar })
        });
      });
    } else {
      alert("Invalid Data for Mutation");
    }
  };

  private renderSingleUser = (data?: SingleUser) => {
    const { t } = this.props;
    const identification = data?.user.matchingCitizen;
    if (!lodash.isNil(identification)) {
      return (
        <List>
          <ListItem>
            <div>
              <div>{t("user_data.name")}</div>
              <b>{(identification.lastName || "--") + " " + (identification.firstName || "--")}</b>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <div>{t("user_data.street_and_number")}</div>
              <b>{(identification.household.street || "--") + " " + (identification.household.streetNumber || "--")}</b>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <div>{t("user_data.postalode_and_city")}</div>
              <b>{(identification.household.postalCode || "--") + " " + (identification.household.city || "--")}</b>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <div>{t("user_data.date_of_birth")}</div>
              <b>{identification.dateOfBirth || "--"}</b>
            </div>
          </ListItem>
        </List>
      );
    }

    return <div />;
  };

  public render() {
    const { t, classes } = this.props;
    const { open, userId } = this.state;

    return (
      <Dialog open={open} maxWidth={"md"} fullWidth={true}>
        <Mutation<setDisposalAllowance, setDisposalAllowanceVariables> mutation={setDisposalAllowanceMutation}>
          {(setDisposalAllowanceMutation, importCitizensResult) => (
            <Query<SingleUser, SingleUserVariables> query={SINGLE_USER_QUERY} variables={{ userId: userId ?? "" }}>
              {({ loading, data: singleUserData, error }) => (
                <QueryState loading={loading} error={error} notNil={singleUserData}>
                  <DialogTitle>
                    {lodash.get(singleUserData, "user.hasDisposalAllowance") === true
                      ? t("user_management.disposal_allowance.resign_title")
                      : t("user_management.disposal_allowance.allow_title")}
                  </DialogTitle>
                  <DialogContent>{this.renderSingleUser(singleUserData)}</DialogContent>
                  <DialogActions className={classes.actions}>
                    <Button onClick={() => this.onCancel()} color="primary">
                      {t("cancel")}
                    </Button>

                    <Button
                      onClick={() =>
                        this.onSend(
                          setDisposalAllowanceMutation,
                          userId,
                          lodash.get(singleUserData, "user.hasDisposalAllowance")
                        )
                      }
                      variant={"contained"}
                      color="primary"
                      className={classes.saveButton}
                      disabled={importCitizensResult.loading}
                    >
                      {lodash.get(singleUserData, "user.hasDisposalAllowance") === true
                        ? t("user_management.disposal_allowance.resign_button")
                        : t("user_management.disposal_allowance.allow_button")}
                    </Button>
                  </DialogActions>
                </QueryState>
              )}
            </Query>
          )}
        </Mutation>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withSnackbar(withTranslation()(UpdateUserDisposalAllowance)));
