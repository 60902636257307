import { FunctionComponent, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, DialogActions, DialogContentText, Grid, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Header from "../components/core/header/header";
import { TownSelection } from "../components/partial/town-selection";
import { UserRole } from "../api/graphql/graphql-global-types";
import { Mutation } from "@apollo/react-components";
import { resetAllowances, resetAllowancesVariables } from "../api/graphql/mutations/types/resetAllowances";
import { resetAllowancesMutation } from "../api/graphql/mutations/reset-disposal-allowances";
import { ClipLoader } from "react-spinners";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { snackbarCloseAction } from "../utils/snackbar-close-action";
import { AddEditWasteDialog } from "../components/waste-management/add-edit-waste-dialog";
import { PageLoadingIndicator } from "../utils/page-loading-indicator";
import * as React from "react";
import { ResetAllowancesDialog } from "../components/waste-management/reset-allowances-dialog";
import { AuthContext } from "../utils/AuthContext";
import { TownContext } from "../components/core/town-context";
import { useLazyQuery } from "@apollo/react-hooks";
import wasteOfTownQuery, { WasteOfTownData, WasteType } from "../api/graphql/queries/waste-of-town";
import { WasteTable } from "../components/waste-management/waste-table";
import { CellInfo } from "react-table";

interface IWasteManagementNewProps {}

const useStyles = makeStyles((theme: Theme) => ({
  loadingIndicatorContainer: {
    flex: 1,
  },
}));

export const WasteManagement: FunctionComponent<IWasteManagementNewProps> = (props) => {
  const {} = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { hasRoles } = useContext(AuthContext);
  const { town } = useContext(TownContext);

  const [shouldShowResetDialog, setShouldShowResetDialog] = useState(false);
  const [shouldShowAddEditDialog, setShouldShowAddEditDialog] = useState(false);
  const [wasteToEdit, setWasteToEdit] = useState<WasteType | null>(null);

  const [loadWasteOfTown, { data, loading: isLoadingTowns }] = useLazyQuery<WasteOfTownData>(wasteOfTownQuery);

  useEffect(() => {
    loadWasteOfTown();
  }, [town]);

  return (
    <>
      <Grid container={true} justify={"space-between"} alignItems={"baseline"}>
        <Grid item={true}>
          <Header title={t("navigation.fraction_management")} />
        </Grid>

        <Grid item={true} xs={3}>
          <TownSelection />
        </Grid>

        <Grid item={true} xs={5} container={true} justify={"flex-end"} alignItems={"baseline"} spacing={2}>
          <Grid item={true}>
            {hasRoles && hasRoles([UserRole.TOWN_ADMIN, UserRole.ADMIN]) && (
              <>
                <ResetAllowancesDialog
                  onCloseDialog={() => {
                    setShouldShowResetDialog(false);
                  }}
                  open={shouldShowResetDialog}
                />
                <Button color="secondary" variant={"contained"} onClick={() => setShouldShowResetDialog(true)}>
                  {t("waste_management.reset_allowances.button")}
                </Button>
              </>
            )}
          </Grid>

          <Grid item={true}>
            {hasRoles && hasRoles([UserRole.TOWN_ADMIN, UserRole.ADMIN]) && (
              <Button variant={"contained"} color={"primary"} onClick={() => setShouldShowAddEditDialog(true)}>
                {t("waste_management.add_waste")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      {shouldShowAddEditDialog && data && data.basicWaste && (
        <AddEditWasteDialog
          onCloseDialog={() => {
            setShouldShowAddEditDialog(false);
            setWasteToEdit(null);
            loadWasteOfTown();
          }}
          existingWastes={data.me.town.wasteTypes || []}
          basicWastes={data.basicWaste}
          wasteToEdit={wasteToEdit}
        />
      )}

      {isLoadingTowns ? (
        <PageLoadingIndicator className={classes.loadingIndicatorContainer} />
      ) : (
        <WasteTable onEditWaste={(waste) => setWasteToEdit(waste)} wasteTypes={data?.me.town.wasteTypes || []} />
      )}
    </>
  );
};

export const renderHeader = (cellInfo: CellInfo, column: any, title: string) => {
  return (
    <div className={"icon-header"}>
      <span className={"icon-up"}>
        <img src={"/images/sort_arrow_up_white.svg"} alt="" />
      </span>
      <span className={"icon-down"}>
        <img src={"/images/sort_arrow_down_white.svg"} alt="" />
      </span>
      <span>{title}</span>
    </div>
  );
};
