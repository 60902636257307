import * as React from "react";
import { IBaseProps } from "../../utils/BaseProps";
import { withTranslation } from "react-i18next";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import CheckCircle from "@material-ui/icons/CheckCircleOutlined";
import { ClipLoader } from "react-spinners";
import {
  ImportCitizenInput,
  importCitizensMutation,
  ImportCitizensMutationFn,
} from "../../api/graphql/mutations/import-citizens-mutation";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { snackbarCloseAction } from "../../utils/snackbar-close-action";
import * as _ from "lodash";
import { excelInputToCitizenInput } from "./xlsx-mapping";
import { importCitizens, importCitizensVariables } from "../../api/graphql/mutations/types/importCitizens";
import { Mutation } from "@apollo/react-components";
import { ImportWaiting } from "./import-wating";

interface IImportCitizensProps extends IBaseProps, WithSnackbarProps {
  getRef: (ref: ImportCitizens) => void;
  onModification: () => void;
}

interface IImportCitizensState {
  importData?: ImportCitizenInput[];
  didLoadFile: boolean;
  open: boolean;
  apiError: boolean;
  importId: string | null;
}

/* list of supported file types */
const SheetJSFT = ["xlsx", "xls", "csv"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const styles = (theme: Theme) =>
  createStyles({
    dropArea: {
      height: 150,
      border: "1px dashed black",
      borderRadius: 5,
      color: theme.palette.secondary.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    success: {
      color: "#4caf50",
    },
    actions: {
      paddingRight: 20,
      paddingBottom: 24,
    },
    importErrorMessage: {
      color: theme.palette.error.main,
      fontWeight: "bold",
      paddingTop: 16,
    },
  });

class ImportCitizens extends React.Component<IImportCitizensProps, IImportCitizensState> {
  constructor(props: IImportCitizensProps) {
    super(props);

    this.state = {
      importData: undefined,
      didLoadFile: false,
      open: false,
      apiError: false,
      importId: null,
    };
  }

  componentDidMount(): void {
    this.props.getRef(this);
  }

  private handleFile = (file: File) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      // @ts-ignore
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: "binary",
        cellDates: true,
        dateNF: "DD.MM.YYYY",
      });

      const sheetNames = wb.SheetNames;
      const sheet = wb.Sheets[sheetNames[0]];
      const data = XLSX.utils.sheet_to_json(sheet).map(excelInputToCitizenInput) as ImportCitizenInput[];

      this.setState({
        importData: data,
        didLoadFile: true,
        apiError: false,
      });
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  private onDropedFiles = (acceptedFiles: File[]) => {
    acceptedFiles.forEach(this.handleFile);
  };

  private reset = () => {
    this.setState({
      importData: undefined,
      didLoadFile: false,
      open: false,
      apiError: false,
      importId: null,
    });
  };

  public onShowDialog = () => {
    this.setState({
      open: true,
      importData: undefined,
      didLoadFile: false,
      apiError: false,
    });
  };

  private onCancel = () => {
    this.reset();
  };

  private onSuccess = () => {
    this.reset();
    this.props.onModification();
    this.props.enqueueSnackbar(this.props.t("user_management.import_citizens.upload_success"), {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: this.props.closeSnackbar }),
    });
  };

  private onError = () => {
    this.setState({
      importData: undefined,
      didLoadFile: false,
      apiError: true,
      importId: null,
    });
  };

  private onSend = (mutation: ImportCitizensMutationFn) => {
    if (this.state.importData) {
      mutation({ variables: { citizens: this.state.importData } })
        .then((r) => {
          this.setState({ importId: r.data?.importCitizens ? r.data?.importCitizens : null });
        })
        .catch((e) => {
          this.onError();
        });
    }
  };

  public render() {
    const { t, classes } = this.props;
    const { importData, didLoadFile, open } = this.state;

    const importSuccess = importData && importData.length > 0;

    return (
      open && (
        <Mutation<importCitizens, importCitizensVariables> mutation={importCitizensMutation}>
          {(importCitizensMutation, importCitizensResult) => (
            <Dialog open={open} maxWidth={"md"} fullWidth={true}>
              <DialogTitle>{t("user_management.import_citizens.title")}</DialogTitle>
              <DialogContent>
                {this.state.importId ? (
                  <ImportWaiting importId={this.state.importId} onSuccess={this.onSuccess} onError={this.onError} />
                ) : (
                  <Dropzone onDrop={this.onDropedFiles} accept={SheetJSFT}>
                    {({ getRootProps, getInputProps }: { getRootProps: any; getInputProps: any }) => (
                      <Grid
                        container={true}
                        justify={"center"}
                        alignContent={"center"}
                        {...getRootProps()}
                        className={classes.dropArea}
                      >
                        <Grid item={true}>
                          <input {...getInputProps()} />
                          {didLoadFile ? (
                            <Grid
                              container={true}
                              justify={"center"}
                              alignItems={"center"}
                              className={importSuccess ? classes.success : classes.error}
                            >
                              {importSuccess ? (
                                <CheckCircle fontSize={"large"} style={{ marginRight: 10 }} />
                              ) : (
                                <CancelIcon fontSize={"large"} style={{ marginRight: 10 }} />
                              )}
                              <Typography className={importSuccess ? classes.success : classes.error}>
                                {importSuccess
                                  ? t("user_management.import_citizens.import_success") +
                                    `${importData && importData.length}`
                                  : t("user_management.import_citizens.import_message")}
                              </Typography>
                            </Grid>
                          ) : (
                            <p>{t("user_management.import_citizens.import_message")}</p>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Dropzone>
                )}
                {importCitizensResult && importCitizensResult.error && this.state.apiError ? (
                  <Typography className={classes.importErrorMessage}>
                    {t("user_management.import_citizens.upload_error")}
                  </Typography>
                ) : undefined}
              </DialogContent>
              <DialogActions className={classes.actions}>
                <Button
                  onClick={() => this.onCancel()}
                  color="primary"
                  disabled={importCitizensResult.loading || !_.isNil(this.state.importId)}
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={() => this.onSend(importCitizensMutation)}
                  variant={"contained"}
                  color="primary"
                  className={classes.saveButton}
                  disabled={importCitizensResult.loading || !_.isNil(this.state.importId)}
                >
                  {importCitizensResult.loading ? <ClipLoader color={"white"} size={17} /> : t("save")}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Mutation>
      )
    );
  }
}

export default withStyles(styles)(withSnackbar(withTranslation()(ImportCitizens)));
