import { gql } from "apollo-boost";
import { SingleUser_user_identificationData } from "./types/SingleUser";

export const SINGLE_USER_QUERY = gql`
  query SingleUser($userId: ID!) {
    user(userId: $userId) {
      userId
      identificationData {
        firstName
        lastName
        street
        streetNumber
        postalCode
        city
        dateOfBirth
        staircase
        apartmentNumber
      }
      matchingCitizen {
        firstName
        lastName
        dateOfBirth
        household {
          staircase
          apartmentNumber
          street
          streetNumber
          postalCode
          city
        }
      }
      email
      isVerifiedCitizen
      hasDisposalAllowance
    }
  }
`;

export type IdentificationData = SingleUser_user_identificationData;
