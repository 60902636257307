import { gql } from "apollo-boost";
import { RequestSapPayment } from "./types/requestSapPayment";

export const REQUEST_SAP_PAYMENT_MUTATION = gql`
  mutation RequestSapPayment($weighingProcessId: ID!) {
    requestSapPayment(weighingProcessId: $weighingProcessId) {
      error
      message
    }
  }
`;

export type RequestSapPaymentResult = RequestSapPayment;
