import * as React from "react"
import {Redirect, Route, RouteProps} from "react-router";
import {AuthConsumer} from "../utils/AuthContext";

interface IPrivateRouteProps  extends RouteProps{

}

export const PrivateRoute = ({render, ...rest}: IPrivateRouteProps) => {
  return (
    <AuthConsumer>
      {({ isAuthenticated, hasRoles }) => (
        <Route
          {...rest}
          render={props =>
            isAuthenticated ? (
              render ? render(props) : undefined
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      )}
    </AuthConsumer>
  );
}
