import { gql } from "apollo-boost";
import { Towns_me_assignedTowns } from "./types/Towns";

// todo replace townId with id
export const townsQuery = gql`
  query Towns {
    me {
      email
      town {
        townId
        name
      }
      assignedTowns {
        townId
        name

        wasteTypes {
          name
          defaultAllowance
          baseWaste {
            baseWasteId
            fractionGroup {
              unit
            }
          }
        }
      }
    }
  }
`;

export type Town = Towns_me_assignedTowns;
