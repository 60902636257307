import { Popover, Button, Typography, ClickAwayListener, Box, LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DisposalAllowance } from "../../api/graphql/queries/household";
import { formatAllowance } from "../../utils/numeral";

interface IWeightUserDialogProps {
  allowances: DisposalAllowance[];
  householdId: String | null | undefined;
}

interface IAllowanceStateProps {
  allowance: DisposalAllowance;
}

const AllowanceState = ({ allowance }: IAllowanceStateProps) => {
  const { t } = useTranslation();
  const calculateProgress = (allowance: DisposalAllowance) => {
    return ((allowance.totalAmount - allowance.remainingAmount) / allowance.totalAmount) * 100;
  };
  return (
    <Box p={2}>
      <div>
        <Typography>
          <strong>{allowance.waste.name}</strong>
        </Typography>
      </div>
      <div>
        <Box my={1}>
          <LinearProgress value={calculateProgress(allowance)} variant="determinate" />
        </Box>
        <div>
          <Typography>
            {t("allowance.available")}:{" "}
            <strong>{formatAllowance(allowance.remainingAmount, allowance.waste.baseWaste.fractionGroup.unit)}</strong>
          </Typography>
        </div>
      </div>
    </Box>
  );
};

export const WeightUserDialog = ({ allowances, householdId }: IWeightUserDialogProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseOver = (event: any) => setAnchorEl(anchorEl ? null : event?.currentTarget);

  const handleMouseLeave = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={handleMouseLeave}>
      <div>
        <Button onClick={handleMouseOver}>{t("waste_management.allowance")}</Button>
        <Popover
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={open}
          anchorEl={anchorEl}
          onMouseLeave={handleMouseLeave}
        >
          {allowances.map((allowance) => (
            <AllowanceState allowance={allowance} />
          ))}
        </Popover>
      </div>
    </ClickAwayListener>
  );
};
