// Libraries
import React, { ReactNode, Fragment } from "react";
import _ from "lodash";

// Material UI
import withStyles from "@material-ui/core/styles/withStyles";

import { createStyles, Grid, Theme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IBaseProps } from "../utils/BaseProps";
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    rootGrid: {
      flexGrow: 1,
      padding: 16
    }
  });

interface IQueryStateProps extends IBaseProps {
  loading: boolean;
  error?: any;
  notNil?: any;
  children: ReactNode;
}

interface IQueryStateState {}

class QueryState extends React.Component<IQueryStateProps, IQueryStateState> {
  constructor(props: IQueryStateProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, loading, error, notNil, children } = this.props;
    // console.log('loading, error, notNil,: ', loading, error, notNil,)
    if (loading && _.isNil(notNil)) {
      return (
        <Grid container className={classes.rootGrid} direction={"column"} justify={"center"} alignItems={"center"}>
          <CircularProgress />
        </Grid>
      );
    } else if (!_.isNil(error)) {
      return (
        <Grid container direction={"row"} justify={"space-between"} alignItems={"center"}>
          <h2>Es ist ein Fehler aufgetreten, bitte laden sie die Seite neu.</h2>
          <div>{error.message || "No specific error message"}</div>
        </Grid>
      );
    } else if (_.isNil(notNil)) {
      return (
        <Grid container direction={"row"} justify={"space-between"} alignItems={"center"}>
          <h2>Es wurden fehlerhafte Daten empfangen, bitte laden sie die Seite neu.</h2>
        </Grid>
      );
    } else {
      return <Fragment>{children}</Fragment>;
    }
  }
}

// QueryState.propTypes = {
//   loading: PropTypes.bool,
//   error: PropTypes.object,
//   notNil: PropTypes.oneOfType([
//     PropTypes.array,
//     PropTypes.object
//   ]),
//   children: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node
//   ]).isRequired
// };
//
// QueryState.defaultProps = {
//   loading: false,
//   error: null,
//   notNil: {},
//   children: <div>Children are missing</div>
// };

export default withStyles(styles)(withTranslation()(QueryState));
