import { fromWeb, requiredStr } from "@protomodule/instruments";

export const config = fromWeb({
  REACT_APP_GRAPHQL_ENDPOINT: requiredStr(),
  REACT_APP_GRAPHQL_ENDPOINT_WS: requiredStr(),
  REACT_APP_DAHEIM_IOS_URL: requiredStr(),
  REACT_APP_DAHEIM_ANDROID_URL: requiredStr(),
  REACT_APP_APP_STORE_LINK: requiredStr(),
  REACT_APP_PLAY_STORE_LINK: requiredStr(),
  REACT_APP_CIITZEN_LIMIT: requiredStr(),
});
