import { FunctionComponent, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, DialogActions, DialogContentText, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { snackbarCloseAction } from "../../utils/snackbar-close-action";
import * as React from "react";
import { useMutation } from "@apollo/react-hooks";
import { resetAllowances, resetAllowancesVariables } from "../../api/graphql/mutations/types/resetAllowances";
import { resetAllowancesMutation } from "../../api/graphql/mutations/reset-disposal-allowances";
import { TownContext } from "../core/town-context";
import { useSnackbar } from "notistack";
import { ClipLoader } from "react-spinners";

interface IResetAllowancesDialogProps extends DialogProps {
  onCloseDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({}));

export const ResetAllowancesDialog: FunctionComponent<IResetAllowancesDialogProps> = (props) => {
  const { open, onCloseDialog } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { town } = useContext(TownContext);

  const onError = () => {
    enqueueSnackbar(t("waste_management.reset_allowances.button.error"), {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
    });
  };

  const [resetAllowances, { loading: isResetting }] = useMutation<resetAllowances, resetAllowancesVariables>(
    resetAllowancesMutation,
    {
      variables: {
        townId: town?.townId ?? "undefined",
      },
      onCompleted: (result) => {
        if (result.resetHouseholdDisposalAllowances.error) {
          onError();
        } else {
          enqueueSnackbar(t("waste_management.reset_allowances.success"), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
          });
          onCloseDialog();
        }
      },
      onError,
    }
  );

  return (
    <Dialog
      open={open}
      onClose={() => onCloseDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("waste_management.reset_allowances.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("waste_management.reset_allowances.description")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCloseDialog()} color="primary" disabled={isResetting}>
          {t("cancel")}
        </Button>
        <Button
          color="secondary"
          autoFocus
          variant={"contained"}
          disabled={isResetting}
          onClick={() => {
            resetAllowances();
          }}
        >
          {isResetting ? <ClipLoader color={"white"} size={17} /> : t("waste_management.reset_allowances.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
