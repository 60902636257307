import * as React from "react"
import { IBaseProps } from "../../../utils/BaseProps"
import { withTranslation } from "react-i18next";
import { createStyles, withStyles, Theme, Paper, Table } from "@material-ui/core";

interface IPaperTableProps extends IBaseProps {
    className?: string
}

const styles = (theme: Theme) => createStyles({
    table: {
        marginTop: 16,
        marginBottom: 30
    }
})

class PaperTable extends React.Component<IPaperTableProps> {

    public render() {
        const { classes, children, className } = this.props

        return (
            <Paper className={classes.table + " " + className}>
                <Table>
                    {children}
                </Table>
            </Paper>
        )
    }
}

export default withStyles(styles)(withTranslation()(PaperTable))
