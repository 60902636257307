import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";
import { App } from "./App";
import { createMuiTheme } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import ApolloClient, { DefaultOptions } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { from } from "apollo-link";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as moment from "moment";
import store from "store";
import { ApolloProvider } from "@apollo/react-hooks";
import "moment/locale/de";
import { AuthProvider } from "./utils/AuthContext";
import { SnackbarProvider } from "notistack";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { initNumeral } from "./utils/numeral";
import { TownContextProvider } from "./components/core/town-context";
import { CitizenCardService } from "./services/citizen-card-service";
import { config } from "./utils/config";
moment.locale("de");

export const PRIMARY_COLOR = "#3b474f";
export const ERROR_COLOR = "#e86161";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#626b72",
      main: PRIMARY_COLOR,
      dark: "#293137",
      contrastText: "#fff",
    },
    secondary: {
      light: "#bfc2c3",
      main: "#c80000",
      dark: "#7b7d7d",
      contrastText: "#fff",
    },
    /*    backgroundColor: {
      main: "#eceff1"
    },*/
    error: {
      main: ERROR_COLOR,
    },
  },
  // @ts-ignore
  customColors: {
    background: "#16181f",
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Segoe UI"',

      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "1px solid #3b474f",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "40px",
        "& $notchedOutline": {
          borderColor: "#2b303e",
        },
      },
      notchedOutline: {
        borderRadius: "0",
        borderWidth: "2px",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#84d1e0 !important",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 12px) scale(1)",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "0",
        boxShadow: "none",
      },
      contained: {
        borderRadius: 2,
        boxShadow: "0 0 12px -5px rgba(0, 0, 0, 0.3)",
        minWidth: 105,
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
  },
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const CITIZEN_LIMIT = parseInt(config.REACT_APP_CIITZEN_LIMIT || "100", 10);

initNumeral();

console.log("link: ", config.REACT_APP_GRAPHQL_ENDPOINT_WS);

const httpLink = new HttpLink({
  uri: config.REACT_APP_GRAPHQL_ENDPOINT,
});

let smartLink: any = httpLink;
if (config.REACT_APP_GRAPHQL_ENDPOINT_WS) {
  // Create a WebSocket link:
  const token = store.get("access_token");
  const wsLink = new WebSocketLink({
    uri: config.REACT_APP_GRAPHQL_ENDPOINT_WS,
    options: {
      reconnect: true,
      connectionParams: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    },
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  smartLink = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );
}
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.get("access_token");
  // return the headers to the context so httpLink can read them
  const townId = CitizenCardService.getTown();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "X-TOWN-ID": townId,
    },
  };
});

const graphqlClient = new ApolloClient({
  link: from([authLink, smartLink]),
  cache: new InMemoryCache(),
  defaultOptions,
});

// @ts-ignore
ReactDOM.render(
  <ApolloProvider client={graphqlClient}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={"de"} libInstance={moment}>
              <SnackbarProvider maxSnack={5} action={true}>
                <TownContextProvider>
                  <App />
                </TownContextProvider>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </I18nextProvider>
  </ApolloProvider>,

  document.getElementById("root")
);
