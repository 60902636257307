import gql from "graphql-tag";
import { DeleteWeighing } from "./types/DeleteWeighing";

export const DELETE_WEIGHING_MUTATION = gql`
  mutation DeleteWeighing($weighingId: ID!) {
    deleteWeighing(id: $weighingId) {
      error
      message
    }
  }
`;

export type DeleteWeighingResult = DeleteWeighing;
