import * as React from "react";
import { IBaseProps } from "../utils/BaseProps";
import { withTranslation } from "react-i18next";
import { createStyles, Theme, withStyles } from "@material-ui/core";
import Sidebar from "./Sidebar";
import { IMatchProps } from "../utils/router-utils";
import Toolbar from "./Toolbar";
import { Redirect, Route, Switch } from "react-router";
import UserManagement from "./user-management";
import "./react-table.css";

import { AuthConsumer } from "../utils/AuthContext";
import { UserRole } from "../api/graphql/graphql-global-types";
import { WeighingManagement } from "./weighing-management";
import { HouseholdOverview } from "./household-overview";
import { NumberOfWeighingContextProvider } from "../utils/NumberOfWeighingContext";
import { WasteManagement } from "./waste-management";

interface IPortalProps extends IBaseProps {
  match: IMatchProps;
}

interface IPortalState {}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: theme.palette.background.default,
      minHeight: "100vh",
      flexFlow: "column",
      display: "flex",
    },
    content: {
      height: "100%",
      display: "flex",
      flex: 1,
      flexFlow: "column",
      padding: 16,
      marginBottom: 16,
    },
  });

interface IRouteItem {
  path: string;
  render: any;
  roles: Array<UserRole>;
}

interface IRedirectItem {
  role: UserRole;
  path: string;
}

class Portal extends React.Component<IPortalProps, IPortalState> {
  routes: IRouteItem[] = [];
  defaultRedirect: IRedirectItem[] = [];

  constructor(props: IPortalProps) {
    super(props);

    this.state = {};

    this.routes = [
      { path: `user-management`, render: <UserManagement {...props} />, roles: [UserRole.ADMIN, UserRole.TOWN_ADMIN] },
      {
        path: `household-overview`,
        render: <HouseholdOverview {...props} />,
        roles: [UserRole.ADMIN, UserRole.TOWN_ADMIN, UserRole.WEIGHING_MASTER],
      },
      {
        path: `waste-management`,
        render: <WasteManagement {...props} />,
        roles: [UserRole.ADMIN, UserRole.TOWN_ADMIN, UserRole.WEIGHING_MASTER],
      },
      {
        path: `weighing-management`,
        render: <WeighingManagement {...props} />,
        roles: [UserRole.ADMIN, UserRole.WEIGHING_MASTER],
      },
    ];

    this.defaultRedirect = [
      { role: UserRole.ADMIN, path: "user-management" },
      { role: UserRole.TOWN_ADMIN, path: "user-management" },
      { role: UserRole.WEIGHING_MASTER, path: "weighing-management" },
    ];
  }

  public render() {
    const { classes, match } = this.props;

    return (
      <AuthConsumer>
        {({ hasRoles }) => (
          <NumberOfWeighingContextProvider>
            <div style={{ display: "flex" }}>
              <Sidebar />
              <div className={classes.root}>
                <Toolbar />
                <div className={classes.content}>
                  <Switch>
                    {this.routes
                      .filter((item) => hasRoles !== undefined && hasRoles(item.roles))
                      .map((item) => (
                        <Route path={`${match.path}/${item.path}`} render={(props) => item.render} key={item.path} />
                      ))}
                    {this.defaultRedirect
                      .filter((item) => hasRoles !== undefined && hasRoles([item.role]))
                      .map((item) => (
                        <Redirect to={`${match.path}/${item.path}`} key={item.path} />
                      ))}
                  </Switch>
                </div>
              </div>
            </div>
          </NumberOfWeighingContextProvider>
        )}
      </AuthConsumer>
    );
  }
}

export default withStyles(styles)(withTranslation()(Portal));
