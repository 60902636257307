import * as React from "react";
import { FunctionComponent, useContext, useState } from "react";
import { Button, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { Redirect } from "react-router";
import emailValidator from "email-validator";
import { passwordResetMutation } from "../api/graphql/mutations/password-reset";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { AuthContext } from "../utils/AuthContext";
import {
  PasswordReset as PasswordResetType,
  PasswordResetVariables,
} from "../api/graphql/mutations/types/PasswordReset";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "white",
    display: "flex",
    flexFlow: "row",
    flex: 1,
    paddingTop: 50,
  },

  content: {
    display: "flex",
    flex: 1,
    flexFlow: "column",
    padding: 16,
    alignItems: "center",
  },

  component: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },

  textInputComponent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    maxWidth: "80%",
    width: 480,
  },

  logo: {
    width: 250,
    height: "100%",
    paddingBottom: 0,
    altText: "Daheim ASZ-Portal",
  },

  resetTitle: {
    color: theme.palette.primary.main,
    fontSize: 26,
    fontWeight: "bold",
    margin: 10,
    paddingBottom: 1,
    paddingTop: 25,
    textAlign: "center",
  },

  resetSubTitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "normal",
    margin: 10,
    paddingBottom: 25,
    maxWidth: 480,
  },

  resetOk: {
    color: "rgb(119, 232, 86)",
    fontSize: 100,
  },

  textField: {},

  resetbutton: {
    marginTop: 20,
    marginBottom: 15,
    width: 260,
  },

  passwordReset: {
    marginTop: 5,
    fontSize: 12,
    color: "white",
    cursor: "pointer",
  },
  loginErrorText: {
    color: theme.palette.error.main,
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 20,
  },
}));

interface IResetPasswordProps {}

export const ResetPassword: FunctionComponent<IResetPasswordProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useContext(AuthContext);

  const [email, setEmail] = useState<string>("");
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const isValidEmail = emailValidator.validate(email);

  const [passwordResetFn, passwordResetResult] = useMutation<PasswordResetType, PasswordResetVariables>(
    passwordResetMutation
  );

  const onResetButtonClick = () => {
    setShouldShowError(true);
    passwordResetFn({
      variables: { email },
    }).catch(console.error);
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (shouldShowError && !passwordResetResult.loading && passwordResetResult.data?.passwordReset.error === true) {
    setShouldShowError(false);
    enqueueSnackbar(t("password_reset.server_error"), {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  }

  if (isValidEmail && !passwordResetResult.loading && passwordResetResult.data?.passwordReset.error === false) {
    return (
      <div className={classes.root}>
        <Grid container={true} className={classes.content}>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <Typography className={classes.resetTitle}>{t("password_reset.sent.title")}</Typography>
            </div>
          </Grid>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <Typography className={classes.resetSubTitle}>{t("password_reset.sent.instructions")}</Typography>
            </div>
          </Grid>
          <Grid container={true} className={classes.component}>
            <div className={classes.component}>
              <CheckCircleOutlineIcon fontSize={"large"} color={"primary"} />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        onResetButtonClick();
      }}
      className={classes.root}
    >
      <Grid container={true} className={classes.content}>
        <Grid container={true} className={classes.component}>
          <div className={classes.component}>
            <Typography className={classes.resetTitle}>{t("password_reset.title")}</Typography>
          </div>
        </Grid>
        <Grid container={true} className={classes.component}>
          <div className={classes.component}>
            <Typography className={classes.resetSubTitle}>{t("password_reset.instructions")}</Typography>
          </div>
        </Grid>
        <Grid container={true} className={classes.textInputComponent}>
          <TextField
            className={classes.textField}
            placeholder={t("login.emailPlaceholder")}
            label={t("login.emailPlaceholder")}
            id="reset"
            type="email"
            fullWidth
            autoFocus
            error={email.length > 0 && !isValidEmail}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid container={true} className={classes.component}>
          {passwordResetResult.data?.passwordReset.error && (
            <Typography className={classes.loginErrorText}>{t("password_reset.try_again")}</Typography>
          )}
          <Button
            disabled={!isValidEmail}
            variant="contained"
            onClick={(event) => {
              event.preventDefault();
              onResetButtonClick();
            }}
            type={"submit"}
            className={classes.resetbutton}
            color={"primary"}
          >
            {passwordResetResult?.loading ? <ClipLoader color={"white"} size={17} /> : t("password_reset.button")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
