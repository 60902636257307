import * as React from "react";
import { FunctionComponent } from "react";
import { Fab, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from "@material-ui/core";
import { Done, LockOpen, NotInterested } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import PaperTable from "../core/table/paper-table";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableHeadText: {
    color: "white",
    fontWeight: "bold",
  },
}));

export interface UserData {
  id: string;
  lastname: string;
  firstname: string;
  street: string;
  city: string;
  dateOfBirth?: string;
  email: string | null;
  hasDisposalAllowance: boolean | undefined;
}

interface UserTableProps {
  data: UserData[];
  isVerified: boolean;
  onButtonClicked: (userId: string) => void;
}

export const UserTable: FunctionComponent<UserTableProps> = (props) => {
  const { data, isVerified, onButtonClicked } = props;
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <PaperTable>
      <TableHead className={styles.tableHead}>
        <TableRow>
          <TableCell>
            <Typography className={styles.tableHeadText}>{t("user_data.lastname")}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={styles.tableHeadText}>{t("user_data.firstname")}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={styles.tableHeadText}>{t("user_data.street_and_number")}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={styles.tableHeadText}>{t("user_data.postalode_and_city")}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={styles.tableHeadText}>{t("user_data.date_of_birth")}</Typography>
          </TableCell>
          {isVerified && (
            <TableCell>
              <Typography className={styles.tableHeadText}>{t("user_data.email")}</Typography>
            </TableCell>
          )}
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length === 0 ? (
          <TableRow>
            <TableCell>
              <Typography>{t("no_data")}</Typography>
            </TableCell>
          </TableRow>
        ) : (
          data.map((user) => <UserTableRow user={user} isVerified={isVerified} onButtonClicked={onButtonClicked} />)
        )}
      </TableBody>
    </PaperTable>
  );
};

interface IUserTableRow {
  user: UserData;
  isVerified: boolean;
  onButtonClicked: (userId: string) => void;
}

const UserTableRow: FunctionComponent<IUserTableRow> = (props) => {
  const { user, isVerified, onButtonClicked } = props;

  return (
    <TableRow key={user.id}>
      <TableCell>{user.lastname}</TableCell>
      <TableCell>{user.firstname}</TableCell>
      <TableCell>{user.street}</TableCell>
      <TableCell>{user.city}</TableCell>
      <TableCell>{user.dateOfBirth || "-"}</TableCell>
      {isVerified && <TableCell>{user.email}</TableCell>}
      <TableCell align="right">
        <Fab color="primary" size="small" onClick={() => onButtonClicked(user.id)}>
          {isVerified ? user.hasDisposalAllowance ? <Done /> : <NotInterested /> : <LockOpen />}
        </Fab>
      </TableCell>
    </TableRow>
  );
};
