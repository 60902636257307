import { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { CitizenCardService } from "../../services/citizen-card-service";
import React from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Towns } from "../../api/graphql/queries/types/Towns";
import { Town, townsQuery } from "../../api/graphql/queries/towns";
import { AuthContext } from "../../utils/AuthContext";

interface ITownContext {
  town: Town | null | undefined;
  setTownId: (townId: string | null) => void;
  assignedTowns: Town[];
}

export const TownContext = createContext<ITownContext>({
  town: null,
  setTownId: () => {},
  assignedTowns: [],
});

export const TownContextProvider: FunctionComponent = (props) => {
  const [townId, setTownId] = useState<string | null>(CitizenCardService.getTown());
  const { userInfo } = useContext(AuthContext);

  const [loadTowns, { data: townResult, loading: isLoadingTowns }] = useLazyQuery<Towns>(townsQuery);

  useEffect(() => {
    loadTowns();
  }, [userInfo]);

  useEffect(() => {
    if (townResult) {
      updateTownId(townResult?.me.town.townId);
    }
  }, [townResult]);

  const updateTownId = (townId: string | null) => {
    CitizenCardService.setTown(townId ?? null);
    setTownId(townId ?? null);
    console.log("MY FUCKING TOWN", townId);
  };

  const town = townResult?.me.assignedTowns.find((town) => town.townId === townId);

  console.log("MY FUCKING TOWN", town);

  return (
    <TownContext.Provider value={{ town, setTownId: updateTownId, assignedTowns: townResult?.me?.assignedTowns ?? [] }}>
      {props.children}
    </TownContext.Provider>
  );
};
