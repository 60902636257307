import { gql } from "apollo-boost";

export const editWasteOfTownMutation = gql`
  mutation EditWasteOfTown($baseWasteId: ID!, $name: String!, $defaultAllowance: Float!) {
    editWasteOfTown(baseWasteId: $baseWasteId, newName: $name, defaultAllowance: $defaultAllowance) {
      error
      message
    }
  }
`;
