import { ImportCitizenInput } from "../../api/graphql/mutations/import-citizens-mutation";
import moment from "moment";
import lodash from "lodash";

interface IMappingSettings {
  xlsxKey: string;
  transformer?: (data: any) => any;
}

const stringTransformer = (d: any) => (d ? `${d}` : undefined);

const xlsxMapping: { [S in keyof ImportCitizenInput]: IMappingSettings } = {
  firstName: { xlsxKey: "vorname" },
  lastName: { xlsxKey: "familienname" },
  dateOfBirth: {
    xlsxKey: "geburtsdatum",
    transformer: (date) => {
      if (!date) {
        return null;
      }

      const dateAsMoment = lodash.isString(date) ? moment(date, "DD.MM.YYYY") : moment(date);

      if (!dateAsMoment.isValid()) {
        throw new Error("invalid_date");
      }

      return dateAsMoment.utcOffset("+02:00").format("DD.MM.YYYY");
    },
  },
  street: { xlsxKey: "strasse" },
  streetNumber: { xlsxKey: "hausnummer", transformer: stringTransformer },
  staircase: { xlsxKey: "stiege", transformer: stringTransformer },
  apartmentNumber: { xlsxKey: "tuer", transformer: stringTransformer },
  postalCode: { xlsxKey: "plz", transformer: stringTransformer },
  city: { xlsxKey: "gemeinde" },
  gkz: { xlsxKey: "gkz", transformer: stringTransformer },
  typeOfResidence: { xlsxKey: "meldeart" },
};

export const excelInputToCitizenInput = (data: any) => {
  const input: any = {};
  Object.entries(xlsxMapping).forEach(([key, setting]) => {
    if (setting) {
      const val = data[setting.xlsxKey];
      input[key] = setting.transformer ? setting.transformer(val) : val;
    }
  });
  return input;
};
