export class SwitchState {
  firstName: boolean = true;
  lastName: boolean = true;
  street: boolean = true;
  streetNumber: boolean = true;
  city: boolean = true;
  postalCode: boolean = true;
  dateOfBirth: boolean = true;
  staircase: boolean = true;
  apartmentNumber: boolean = true;
}

export enum SwitchStateKeys {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  STREET = "street",
  STREET_NUMBER = "streetNumber",
  CITY = "city",
  POSTAL_CODE = "postalCode",
  DATE_OF_BIRTH = "dateOfBirth",
  STAIRCASE = "staircase",
  APARTMENT_NUMBER = "apartmentNumber"
}
