import gql from "graphql-tag";
import { AvailableQrCodes, AvailableQrCodes_qrCodes } from "./types/AvailableQrCodes";

export const AVAILABLE_QR_CODES = gql`
  query AvailableQrCodes($type: QrCodeType!, $availableOnly: Boolean!) {
    qrCodes(type: $type, availableOnly: $availableOnly) {
      id
      label
    }
  }
`;

export type AvailableQrCodesResults = AvailableQrCodes;
export type QrCode = AvailableQrCodes_qrCodes;
