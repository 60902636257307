import {Grid, makeStyles, Theme} from "@material-ui/core";
import {BounceLoader} from "react-spinners";
import {PRIMARY_COLOR} from "../index";
import * as React from "react";
import {FunctionComponent} from "react";

interface IPageLoadingIndicatorProps {
  className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%'
  }
}))

export const PageLoadingIndicator: FunctionComponent<IPageLoadingIndicatorProps> = ({className}) => {

  const classes = useStyles()

  return (
    <Grid container={true} justify={"center"} alignItems={"center"} className={`${className} ${classes.container}`}>
      <Grid item={true}>
        <BounceLoader size={70} color={PRIMARY_COLOR}/>
      </Grid>
    </Grid>
  )
}
