import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import { WasteType } from "../../api/graphql/queries/town-detail";
import { snackbarCloseAction } from "../../utils/snackbar-close-action";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import lodash from "lodash";
import { TownDetail_town_wasteTypes } from "../../api/graphql/queries/types/TownDetail";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { TownContext } from "../core/town-context";
import { useMutation } from "@apollo/react-hooks";
import { ADD_OR_EDIT_WEIGHING_MUTATION, AddOrEditWeighingResult } from "../../api/graphql/mutations/add-weighing";
import { OpenWeighingProcess, OpenWeighingProcessWeight } from "../../api/graphql/queries/open-weighings";
import { AddOrEditWeighingVariables } from "../../api/graphql/mutations/types/AddOrEditWeighing";

export interface IEditWeighingDialogProps {
  onSuccess: () => void;
  isOpen: boolean;
  onCloseDialog: () => void;
  weighingProcess: OpenWeighingProcess;
  weighing?: OpenWeighingProcessWeight;
}

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#979797",
    marginBottom: 37,
  },
  contentRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 5,
    paddingBottom: 17,
  },
  highlightedText: {
    backgroundColor: "#D5D5D5",
  },
  menuItemRoot: {
    backgroundColor: "transparent",
    "&:focus": {
      background: "transparent",
    },
  },
}));

export const AddEditWeighingDialog: React.FunctionComponent<IEditWeighingDialogProps> = ({
  onCloseDialog,
  isOpen,
  onSuccess,
  weighing,
  weighingProcess,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [selectedWasteType, setSelectedWasteType] = useState<TownDetail_town_wasteTypes | undefined>(undefined);

  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [weight, setWeight] = useState<string>("");

  const { town } = useContext(TownContext);
  const wasteTypes: TownDetail_town_wasteTypes[] = town?.wasteTypes ?? [];

  const isWeightValid = lodash.isFinite(Number(weight)) && Number(weight) > 0;

  const [addOrEditWeighing, { loading: isAddingWeighing }] = useMutation<
    AddOrEditWeighingResult,
    AddOrEditWeighingVariables
  >(ADD_OR_EDIT_WEIGHING_MUTATION);

  useEffect(() => {
    if (weighing) {
      setWeight(weighing.weight ? weighing.weight.toString() : "");
      setSelectedWasteType(
        wasteTypes.find((wasteType) => wasteType.baseWaste.baseWasteId === weighing.waste.baseWasteId)
      );
    }
  }, [weighing]);

  const onError = () => {
    enqueueSnackbar(t("weighing_management.edit_dialog.errors.general"), {
      variant: "error",
      action: (key) => snackbarCloseAction({ key, closeSnackbar }),
    });
  };

  const onSave = async () => {
    setShouldShowError(true);

    if (!selectedWasteType || !isWeightValid || !town) {
      return;
    }

    try {
      const result = await addOrEditWeighing({
        variables: {
          baseWasteId: selectedWasteType.baseWaste.baseWasteId,
          weight: Number(weight),
          weighingProcessId: weighingProcess.id,
          townId: town.townId,
          householdId: weighingProcess.citizenHousehold.household.householdId,
          weighingId: weighing?.id,
        },
      });

      if (!result || !result.data || result.data.addOrEditWeighing.error) {
        onError();
      } else {
        enqueueSnackbar(t("weighing_management.edit_dialog.success"), {
          variant: "success",
          action: (key) => snackbarCloseAction({ key, closeSnackbar }),
        });
        onSuccess();
      }
    } catch (e) {
      onError();
    }
  };

  const onWasteTypeSelected = (wasteId: string) => {
    const newWasteType = wasteTypes.find((w) => w.baseWaste.baseWasteId === wasteId);
    setSelectedWasteType(newWasteType);
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth={true} maxWidth={"sm"}>
        <form>
          <DialogTitle>{t("weighing_management.edit_dialog.title")}</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item={true} xs={12} className={classes.content}>
                <FormControl fullWidth={true} error={shouldShowError && !selectedWasteType}>
                  <InputLabel>{t("weighing_management.edit_dialog.waste_type")}</InputLabel>
                  <Select
                    disabled={lodash.isEmpty(wasteTypes)}
                    value={selectedWasteType || ""}
                    renderValue={(value?: WasteType | string | number | boolean | object | unknown) =>
                      value && (value as WasteType).name ? `${(value as WasteType).name}` : undefined
                    }
                    displayEmpty={false}
                    onChange={(e) => onWasteTypeSelected(e.target.value as string)}
                  >
                    {wasteTypes
                      .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
                      .map((waste) => (
                        <MenuItem
                          classes={{ root: classes.menuItemRoot }}
                          key={waste.name}
                          value={waste.baseWaste.baseWasteId}
                        >
                          {waste.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {shouldShowError && !selectedWasteType && (
                    <FormHelperText>{t("weighing_management.start_dialog.errors.waste")}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item={true} xs={12} className={classes.content}>
                <FormControl fullWidth={true} error={shouldShowError && !isWeightValid}>
                  <InputLabel>{t("weighing_management.edit_dialog.weight_first")}</InputLabel>
                  <Input
                    autoFocus={true}
                    endAdornment={
                      <InputAdornment position="end">
                        {selectedWasteType?.baseWaste?.fractionGroup.unit || ""}
                      </InputAdornment>
                    }
                    inputProps={{ type: "number" }}
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                  {shouldShowError && !isWeightValid ? (
                    <FormHelperText>{t("weighing_management.start_dialog.errors.weight")}</FormHelperText>
                  ) : undefined}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingBottom: 10, paddingRight: 10 }}>
            <Button color="primary" onClick={() => onCloseDialog()}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              variant={"contained"}
              type={"submit"}
              onClick={(event) => {
                event.preventDefault();
                onSave();
              }}
            >
              {isAddingWeighing ? <ClipLoader color={"white"} size={17} /> : t("save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
