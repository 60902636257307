import { default as React, FunctionComponent, useState } from "react";
import { Fab, Grid, InputAdornment, makeStyles, Paper, TextField, Theme, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./open-weighings.css";
import { useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { AddEditWeighingDialog } from "../edit-weighing-dialog";
import { OpenWeighingProcess } from "../../../api/graphql/queries/open-weighings";
import { EditAdditionalWeighingInfoDialog } from "../edit-additional-weighing-info-dialog";
import { WeightUserDialog } from "../weight-user-dialog";
import { Add, Delete, Done } from "@material-ui/icons";
import { OpenWeighingsTable } from "./open-weighings-table";
import { ConfirmationDialog } from "../../core/confirmation-dialog";
import * as _ from "lodash";
import { useMutation } from "@apollo/react-hooks";
import {
  FINISH_WEIGHING_PROCESS_MUTATION,
  FinishWeighingProcessResult,
} from "../../../api/graphql/mutations/finish-weighing-process";
import { FinishWeighingProcessVariables } from "../../../api/graphql/mutations/types/FinishWeighingProcess";
import { snackbarCloseAction } from "../../../utils/snackbar-close-action";
import { NoWeighings } from "./no-weighings";
import {
  REQUEST_SAP_PAYMENT_MUTATION,
  RequestSapPaymentResult,
} from "../../../api/graphql/mutations/request-sap-payment";
import { RequestSapPaymentVariables } from "../../../api/graphql/mutations/types/requestSapPayment";
import { ClipLoader } from "react-spinners";

interface IOpenWeighingCardProps {
  weighingProcess: OpenWeighingProcess;
  refetch: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: 24,
    minHeight: 150,
    padding: 10,
  },
  citizenTitle: {
    fontSize: "16px",
    "font-weight": "bold",
    "font-style": "normal",
    "font-stretch": "normal",
    "line-height": "1.13",
    "letter-spacing": "normal",
    color: theme.palette.primary.main,
  },

  waitForWeighing: {
    color: "#228a0f",
  },
  completeButtonContainer: {
    marginTop: 9,
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  rightAlign: {
    display: "flex",
    // flexDirection: ,
    justifyContent: "right",
  },
  additionalWeighingInfo: {
    marginLeft: theme.spacing(2),
  },
}));

export const OpenWeighingsCard: FunctionComponent<IOpenWeighingCardProps> = ({ weighingProcess, refetch }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [showEnterWeighingDialog, setShowEnterWeighingDialog] = useState(false);
  const [showConfirmWeighingDialog, setShowConfirmWeighingDialog] = useState(false);
  const [showEditWeighingDialog, setShowEditWeighingDialog] = useState(false);
  const [showEditAdditionalWeighingInfoDialog, setShowEditAdditionalWeighingInfoDialog] = useState(false);

  const [finishWeighingProcess, { loading: isFinishing }] = useMutation<
    FinishWeighingProcessResult,
    FinishWeighingProcessVariables
  >(FINISH_WEIGHING_PROCESS_MUTATION);

  const onError = () => {
    enqueueSnackbar(t("general_error_message"), {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
    });
  };

  const [requestSapPayment, { loading: isRequestingSapPayment }] = useMutation<
    RequestSapPaymentResult,
    RequestSapPaymentVariables
  >(REQUEST_SAP_PAYMENT_MUTATION, {
    variables: {
      weighingProcessId: weighingProcess.id,
    },
    onCompleted: (data) => {
      if (!data || data.requestSapPayment.error) {
        onError();
      } else {
        enqueueSnackbar(t("weighing_management.send_to_sap_success"), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
        });
      }
    },
    onError,
  });

  const citizenHousehold = weighingProcess.citizenHousehold;

  const tableData = weighingProcess.weighings;

  const hasToPay = tableData.some((d) => d.weightToPay > 0);
  const townName: string = citizenHousehold.household.town.name;

  const onFinishWeighing = () => {
    finishWeighingProcess({
      variables: {
        weighingProcessId: weighingProcess.id,
      },
    })
      .then(({ data }) => {
        if (!data || data.finishWeighingProcess.error) {
          onError();
        } else {
          enqueueSnackbar(t("weighing_management.confirm_dialog.success"), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            action: (key) => snackbarCloseAction({ key, closeSnackbar: closeSnackbar }),
          });
        }
      })
      .catch(() => {
        onError();
      });
  };

  return (
    <Paper key={weighingProcess.id} className={classes.card}>
      <Grid container>
        <Grid item md={12} className={classes.citizenTitle} lg={3}>
          {townName} - {citizenHousehold.citizen.lastName} {citizenHousehold.citizen.firstName} -
          {citizenHousehold.household.street} {citizenHousehold.household.streetNumber}
        </Grid>
        <Grid item md={6} lg={4}>
          <TextField
            id="additional-weighing-info"
            placeholder={t("weighing_management.no_additional_weighing_info_set")}
            fullWidth
            value={weighingProcess.additional_weighing_info}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowEditAdditionalWeighingInfoDialog(true)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={2} lg={1}>
          <WeightUserDialog
            allowances={citizenHousehold.household.disposalAllowance}
            householdId={citizenHousehold.household.householdId}
          />
        </Grid>
        <Grid item md={4} lg={4}>
          <Grid container justify={"flex-end"}>
            {hasToPay && (
              <Tooltip title={t("weighing_management.send_to_sap")}>
                <Fab
                  color={"primary"}
                  size={"small"}
                  onClick={() => {
                    if (!isRequestingSapPayment) {
                      requestSapPayment();
                    }
                  }}
                  style={{ marginLeft: 30 }}
                >
                  {isRequestingSapPayment ? (
                    <ClipLoader size={10} color={"white"} />
                  ) : (
                    <img src={"/images/icon-to-pay.svg"} alt="" />
                  )}
                </Fab>
              </Tooltip>
            )}
            <Tooltip title={t("weighing_management.add_weighing")}>
              <Fab
                color={"primary"}
                size={"small"}
                onClick={() => setShowEditWeighingDialog(true)}
                style={{ marginLeft: 30 }}
              >
                <Add fontSize="small" />
              </Fab>
            </Tooltip>
            <Tooltip title={t("weighing_management.finish_weighing")}>
              <Fab
                color={"primary"}
                size={"small"}
                onClick={() => setShowConfirmWeighingDialog(true)}
                style={{ marginLeft: 30 }}
              >
                <Done fontSize="small" />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {weighingProcess.sap_weighing_nr &&
            `${t("weighing_management.sap_weighing_nr")}: ${weighingProcess.sap_weighing_nr}`}
        </Grid>
      </Grid>

      <div className={`OpenWeighings ${hasToPay ? "has-to-pay" : undefined}`}>
        {weighingProcess.weighings.length > 0 ? (
          <OpenWeighingsTable weighingProcess={weighingProcess} />
        ) : (
          <NoWeighings />
        )}
      </div>

      {showEditWeighingDialog && (
        <AddEditWeighingDialog
          weighingProcess={weighingProcess}
          onSuccess={() => {
            setShowEditWeighingDialog(false);
          }}
          isOpen={showEditWeighingDialog}
          onCloseDialog={() => setShowEditWeighingDialog(false)}
        />
      )}

      {showEditAdditionalWeighingInfoDialog && (
        <EditAdditionalWeighingInfoDialog
          weighingProcessId={weighingProcess.id}
          additionalWeighingInfo={weighingProcess.additional_weighing_info ?? ""}
          onCloseDialog={() => setShowEditAdditionalWeighingInfoDialog(false)}
        />
      )}

      {showConfirmWeighingDialog && (
        <ConfirmationDialog
          onClose={() => setShowConfirmWeighingDialog(false)}
          onSuccess={onFinishWeighing}
          content={
            <>
              <Typography>
                {t("weighing_management.confirm_dialog.text", {
                  name: `${weighingProcess.citizenHousehold.citizen.lastName} ${weighingProcess.citizenHousehold.citizen.firstName}`,
                })}
              </Typography>
              {hasToPay && (
                <Typography>
                  <strong>
                    {t("weighing_management.confirm_dialog.fractions_to_pay", {
                      fractions: _.uniq(
                        weighingProcess.weighings.filter((w) => w.weightToPay > 0).map((w) => w.waste.name)
                      ).join(", "),
                    })}
                  </strong>
                </Typography>
              )}
            </>
          }
          dialogTitle={t("weighing_management.confirm_dialog.heading")}
          open={showConfirmWeighingDialog}
          primaryActionText={t("weighing_management.confirm_dialog.paid")}
          isLoading={isFinishing}
        />
      )}
    </Paper>
  );
};
