import { gql } from "apollo-boost";

export const addWasteToTownMuation = gql`
  mutation AddWasteToTown($baseWasteId: ID!, $name: String!, $defaultAllowance: Float!, $fractionGroupId: ID) {
    addWasteToTown(baseWasteId: $baseWasteId, name: $name, defaultAllowance: $defaultAllowance, fractionGroupId: $fractionGroupId) {
      error
      message
    }
  }
`;
