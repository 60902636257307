import { gql } from "apollo-boost";
import { MutationFunction } from "@apollo/react-common";
import { setDisposalAllowance, setDisposalAllowanceVariables } from "./types/setDisposalAllowance";

export const setDisposalAllowanceMutation = gql`
  mutation setDisposalAllowance($userId: ID!, $disposalAllowance: Boolean!) {
    updateUser(userId: $userId, hasDisposalAllowance: $disposalAllowance) {
      error
      message
    }
  }
`;

export type SetDisposalAllowanceMutationFn = MutationFunction<setDisposalAllowance, setDisposalAllowanceVariables>;
