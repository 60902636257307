import { gql } from "apollo-boost";
import { CitizensInput } from "../graphql-global-types";
import { MutationFunction } from "@apollo/react-common";
import { importCitizens, importCitizensVariables } from "./types/importCitizens";

export const importCitizensMutation = gql`
  mutation importCitizens($citizens: [CitizensInput!]!) {
    importCitizens(citizens: $citizens)
  }
`;

export type ImportCitizenInput = CitizensInput;
export type ImportCitizensMutationFn = MutationFunction<importCitizens, importCitizensVariables>;
