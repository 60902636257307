import * as React from "react";
import { FunctionComponent, useContext, useState } from "react";
import { ReactComponent as Logo } from "../assets/logo-daheim.svg";
import { Button, Grid, Link, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core";
import { Redirect } from "react-router";
import emailValidator from "email-validator";
import { loginMutation } from "../api/graphql/mutations/login";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import lodash from "lodash";
import { AuthContext } from "../utils/AuthContext";
import { Login as LoginType, LoginVariables } from "../api/graphql/mutations/types/Login";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { TownContext } from "../components/core/town-context";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexFlow: "row",
    flex: 1,
    paddingTop: 50
  },

  content: {
    display: "flex",
    flex: 1,
    flexFlow: "column",
    padding: 16,
    alignItems: "center"
  },

  component: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center"
  },

  textInputComponent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center"
  },

  logo: {
    width: 250,
    height: "100%",
    paddingBottom: 0,
    altText: "Daheim ASZ-Portal"
  },

  loginTitle: {
    color: "#FFFFFF",
    fontSize: 26,
    fontWeight: "bold",
    margin: 10,
    paddingBottom: 25
  },

  textField: {
    backgroundColor: "white",
    borderRadius: 2
  },

  paper: {
    display: "flex",
    maxWidth: "80%",
    width: 480,
    color: theme.palette.text.secondary,
    height: 50,
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 20,
    paddingRitht: 20,
    justifyContent: "center",
    alignItems: "center"
  },

  loginbutton: {
    "&:hover": {
      backgroundColor: "lightgray"
    },

    borderRadius: "2px",
    backgroundColor: "white",
    color: theme.palette.text.primary,
    marginTop: 20,
    marginBottom: 15,
    width: 260
  },

  passwordReset: {
    marginTop: 5,
    fontSize: 12,
    color: "white",
    cursor: "pointer"
  },
  loginErrorText: {
    color: theme.palette.error.main,
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 20
  }
}));

interface ILoginProps {}

export const LoginPage: FunctionComponent<ILoginProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setTownId } = useContext(TownContext);
  const { isAuthenticated, authin } = useContext(AuthContext);

  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);

  const [loginFn, loginResult] = useMutation<LoginType, LoginVariables>(loginMutation);

  const onLoginButtonClick = () => {
    setShouldShowError(true);
    if (login && emailValidator.validate(login) && password) {
      loginFn({
        variables: {
          email: login,
          password
        }
      })
        .then(result => {
          if (result && result.data) {
            if (result.data.login.user.assignedTowns.length > 0) {
              // @ts-ignore todo
              setTownId(result.data.login.user.assignedTowns[0].townId);
            }

            if (!lodash.isNil(authin)) {
              authin(result.data.login.token, result.data.login.user);
            }

            window.location.reload();
          }
        })
        .catch(console.error);
    } else {
      enqueueSnackbar(t("login.check"), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      });
    }
  };

  const onPasswordResetClick = () => {
    enqueueSnackbar("Coming soon", {
      variant: "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right"
      }
    });
  };

  if (isAuthenticated) {
    return <Redirect to="/portal" />;
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();

        onLoginButtonClick();
      }}
      className={classes.root}
    >
      <Grid container={true} className={classes.content}>
        <Grid container={true} className={classes.component}>
          <div className={classes.component}>
            <Logo className={classes.logo} />
            <Typography className={classes.loginTitle}>{t("login.title")}</Typography>
          </div>
        </Grid>
        <Grid container={true} className={classes.textInputComponent}>
          <Paper className={classes.paper}>
            <TextField
              className={classes.textField}
              placeholder={t("login.emailPlaceholder")}
              id="login"
              type="email"
              fullWidth
              autoFocus
              error={true}
              InputProps={{
                disableUnderline: true
              }}
              onChange={e => setLogin(e.target.value)}
            />
          </Paper>
        </Grid>
        <Grid container={true} className={classes.textInputComponent}>
          <Paper className={classes.paper}>
            <TextField
              className={classes.textField}
              placeholder={t("login.passwordPlaceholder")}
              id="password"
              type="password"
              fullWidth
              error={shouldShowError && !password}
              InputProps={{
                disableUnderline: true
              }}
              onChange={e => setPassword(e.target.value)}
            />
          </Paper>
        </Grid>
        <Grid container={true} className={classes.component}>
          {loginResult.error ? (
            <Typography className={classes.loginErrorText}>{t("login.error")}</Typography>
          ) : (
            undefined
          )}
          <Button
            variant="contained"
            onClick={event => {
              event.preventDefault();
              onLoginButtonClick();
            }}
            type={"submit"}
            className={classes.loginbutton}
          >
            {loginResult?.loading ? <ClipLoader color={"black"} size={17} /> : t("login.login")}
          </Button>
        </Grid>
        <Grid container={true} className={classes.component}>
          <Link hidden={true} onClick={onPasswordResetClick} className={classes.passwordReset}>
            Passwort vergessen?
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};
